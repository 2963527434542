import React from "react";
import {Close} from "@mui/icons-material";
import LoadingButton from "../LoadingButton";
import {OneDataRow, TwoDataRow} from "../DataRow";
import {adminActions} from "../../store/Admin/slice";
import {extendDateRequest} from "../../store/Admin/api";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormHelperText,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";

export default function ExtendAccountDialog() {
    const extendAccountDialog = useAppSelector((state: any) => state.admin.extendAccountDialog);
    const extendedExpiryDate: any = useAppSelector((state: any) => state.admin.extendedExpiryDate);
    const extendAccountStatus: any = useAppSelector((state: any) => state.admin.extendAccountStatus);
    const currentSelectedUser: any = useAppSelector((state: any) => state.admin.currentSelectedUser);
    const dispatch = useAppDispatch();

    function handleClose(_: any, reason: any = null) {
        if (reason && reason === "backdropClick")
            return;
        dispatch(adminActions.closeExtendAccountDialog())
    }

    function handleExtendDate() {
        dispatch(adminActions.validateExtendedExpiryDate());
        if (extendedExpiryDate.isValid)
            dispatch(extendDateRequest())

    }

    function handleExpiryDateChange(e: any) {
        const expiryDate = `${e["$y"]}-${e["$M"] + 1}-${e["$D"]}T00:00`
        dispatch(adminActions.setExtendedExpiryDate(expiryDate));
        dispatch(adminActions.validateExtendedExpiryDate());
    }

    const getFormattedHelperText = (text: any) => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                component="span"
                spacing={4}
                color="#c70f49"
                sx={{ml: 0}}
            >
                <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
                {text}
            </Stack>
        )
    }

    return (
        <Dialog
            open={extendAccountDialog}
            onClose={handleClose}
            fullWidth
            componentsProps={{backdrop: {style: {backgroundColor: "#003965", opacity: 0.6499999761581421}}}}
        >
            <DialogTitle variant={"h5"}>
                Extend Account
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{mx: 1}}>
                <TwoDataRow
                    leftChild={{key: 'First Name:', value: currentSelectedUser?.first_name || 'NA'}}
                    rightChild={{key: 'Last Name:', value: currentSelectedUser?.last_name || 'NA'}}
                />
                <OneDataRow
                    child={{key: 'Email Address:', value: currentSelectedUser?.email || 'NA'}}
                    sx={{mt: 2}}
                />
                <OneDataRow
                    child={{
                        key: 'Algorithms:',
                        value: currentSelectedUser?.algorithm_access_set.map((algo: any, index: number) => {
                            return (index ? ', ' : '') + algo.algorithm.name
                        })
                    }}
                    sx={{my: 2}}
                />
                <Divider sx={{my:3}}/>
                <Typography
                    sx={{mb: 1}}
                    fontWeight={"bold"}
                >
                    Extend Account
                </Typography>
                <Typography
                    color='grey'
                    variant='body1'
                >
                    Extended Date
                </Typography>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                >
                    <DatePicker
                        sx={{width: '100%'}}
                        onChange={(e: any) => handleExpiryDateChange(e)}
                    />
                    {extendedExpiryDate.errorMessage && <FormHelperText>
                        {getFormattedHelperText(extendedExpiryDate.errorMessage)}
                    </FormHelperText>}
                </LocalizationProvider>
                <Stack
                    direction='row'
                    justifyContent={'space-around'}
                    sx={{mt: 5}}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        sx={{width: '200px', borderRadius: '4px'}}
                        onClick={handleExtendDate}
                        status={extendAccountStatus}
                    >
                        Confirm Extend
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}