import {Buffer} from "buffer";
import {RootState} from "../index";
import {authAxios} from '../axiosAPI';
import {CSVToArrParser} from "../validation";
import {createAsyncThunk} from '@reduxjs/toolkit'
import {commonAlgoActions} from "../CommonAlgorithm/slice";

export const startEvaluation = createAsyncThunk(
    'algo/start_evaluation',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const whrmAlgorithm = state.whrmAlgorithm;
        const commonAlgorithm = state.commonAlgorithm;
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationAPIStatus("pending"))
        const data = {
            id: commonAlgorithm.evaluationID,
            age: whrmAlgorithm.age.data,
            height: whrmAlgorithm.height.data,
            weight: whrmAlgorithm.weight.data,
            gender: whrmAlgorithm.gender.data
        };
        const response = await authAxios.post(
            "/algorithm/start_whrm_evaluation/",
            data
        );
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationAPIStatus("succeeded"))
        return response.data;
    }
);

export const startWHRMSyncEvaluation = createAsyncThunk(
    'users/whrm_sync',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        const whrmAlgorithm = state.whrmAlgorithm;
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationSyncAPIStatus("pending"))
        const data = {
            id: commonAlgorithm.evaluationID,
            time_diff_in_sec: whrmAlgorithm.timeDiffInSec,
        };
        const response = await authAxios.post(
            "/algorithm/start_whrm_sync_evaluation/",
            data
        );
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationSyncAPIStatus("succeeded"))
        return response.data;
    }
);

export const getHROutput = createAsyncThunk(
    'evaluation/csv1',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        const response = await authAxios.get(
            '/files/evaluation_files/' + commonAlgorithm.evaluationID + '/OUTPUT/SHORT_OUTPUT.csv',
        );
        return CSVToArrParser(response.data, 0);
    }
)

export const getReferenceHROutput = createAsyncThunk(
    'evaluation/csv2',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        const response = await authAxios.get(
            '/files/evaluation_files/' + commonAlgorithm.evaluationID + '/OUTPUT/SHORT_REFERENCE.csv',
        );
        return CSVToArrParser(response.data, 0, 0);
    }
)

export const getLineGraphImages = createAsyncThunk(
    'evaluation/line_graph',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        let result = []
        try {
            const whrm_out = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/whrm_out.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(whrm_out.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const whrm5 = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/whrm_out_vs_ref_HR_5BPM_err.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(whrm5.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const whrm10 = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/whrm_out_vs_ref_HR_10BPM_err.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(whrm10.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const rawPPG = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/raw_PPG_plot.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(rawPPG.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/accel_mag_activity_plot.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }
        return result
    }
)

export const getSpectrogramGraphImages = createAsyncThunk(
    'evaluation/spectrogram',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        let result = []
        try {
            const rawPPG = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/raw_ppg_spectrogram.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(rawPPG.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/accel_spectrogram.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const rawPPGHR = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/raw_ppg_spectrogram_HR.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(rawPPGHR.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accelMotion = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/accel_spectrogram_motion_freq.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accelMotion.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }
        return result
    }
)