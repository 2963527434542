import React, {useEffect} from "react";
import {publicAxios} from "../../store/axiosAPI";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";
import {Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography} from "@mui/material";

function refreshPage() {
    window.location.reload();
}

export default function InternetConnectionDialog() {

    const [isOnline, setIsOnline] = React.useState(true);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                await publicAxios.get('/health')
            } catch (e) {
                setIsOnline(false)
                clearInterval(interval)
            }
        }, 10000);

        return () => clearInterval(interval);
    }, []);


    return (
        <Dialog
            open={!isOnline}
            onClose={() => {
            }}
            fullWidth
        >
            <DialogTitle variant={"h5"}>
                <b>Connection Lost</b>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Stack direction={"row"} spacing={2}>
                    <HmcIcon name="warning-m"/>
                    <Typography fontSize={18}>
                        Please restart the session as your internet has been disconnected.
                    </Typography>
                </Stack>
                <Button
                    variant={'contained'}
                    sx={{mx: 2, my: 1, mt: 3, float: 'right', px: 4, textTransform: 'none'}}
                    onClick={refreshPage}
                >
                    Restart
                </Button>
            </DialogContent>
        </Dialog>
    );
}