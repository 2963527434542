import React, {useEffect} from 'react';
import Header3 from '../../components/Header3';
import Footer2 from '../../components/Footer2';
import error404 from '../../svgs/404.png';
import {
    Box,
    Button, Paper,
    Typography
} from '@mui/material';

export default function InternalServerError() {

    useEffect(() => {
        document.title = 'Not Found';
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: "#F2F5FD"
            }}
        >
            <Header3/>
            <Box
                style={{backgroundColor: '#F2F5FD', textAlign: 'center'}}
                sx={{p: 3}}
            >
                <Paper sx={{width: '100%', pb: 20}} square>
                    <img src={error404} style={{width: '20vw', marginTop: '12vh'}} alt={"404"}/>
                    <br/>
                    <br/>
                    <Typography variant='h5'>Internal Server Error</Typography>
                    <br/>
                    <Typography variant='body2'>Our team is working to solve this. Meanwhile, we recommend:</Typography>
                    <br/>
                    <Typography variant='body2'>
                        1. Try again later on.
                        <br/>
                        2. Contact us via <Typography color='primary' sx={{display: 'inline'}}
                                                      component={"span"}><u>email.</u></Typography>
                    </Typography>
                    <br/>
                    <Button
                        variant='contained'
                        sx={{textTransform: 'none', borderRadius: '4px', width: '200px'}}
                    >
                        Reload Page
                    </Button>
                </Paper>
            </Box>
            <Paper
                square
                component="footer"
                sx={{
                    py: 2,
                    px: 2,
                    mt: 'auto',
                }}
            >
                <Footer2/>
            </Paper>
        </Box>
    )
}