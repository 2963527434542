import React from 'react';
import {useAppSelector} from "../../../store/hooks";
import Skeleton from "@mui/material/Skeleton";
import {Stack} from "@mui/material";

export default function SpectrogramCharts() {
    const spectrogramGraph = useAppSelector((state: any) => state.whrmAlgorithm.spectrogramGraph);

    return (<>
            {
                spectrogramGraph[0] === null &&
                <Skeleton
                    variant="rectangular"
                    sx={{width: '97%', height: '335px', ml: 2, my: 2, backgroundColor: 'rgb(241, 243, 250)'}}
                />
            }
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{mr: 1, mt: 2}}
            >
                {spectrogramGraph[0] && <img
                    src={`data:image/png;base64, ${spectrogramGraph[0]}`}
                    style={{width: '98%'}}
                    alt={"Raw PPG"}/>}
                {spectrogramGraph[1] && <img
                    src={`data:image/png;base64, ${spectrogramGraph[1]}`}
                    style={{width: '98%'}}
                    alt={"Accelerometer Spectrogram"}/>}
                {spectrogramGraph[2] && <img
                    src={`data:image/png;base64, ${spectrogramGraph[2]}`}
                    style={{width: '98%'}}
                    alt={"Raw PPG with HR"}/>}
                {spectrogramGraph[3] && <img
                    src={`data:image/png;base64, ${spectrogramGraph[3]}`}
                    style={{width: '98%'}}
                    alt={"Accelerometer with Motion Frequency"}/>}
            </Stack>
        </>
    )
}