import {authAxios} from '../axiosAPI';
import {createAsyncThunk} from '@reduxjs/toolkit'

export const algorithmRequest = createAsyncThunk(
    'users/algorithm',
    async (_, thunkAPI) => {
        const response = await authAxios.get('/algorithm/')
        return response.data
    }
)
