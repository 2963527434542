import {createSlice} from "@reduxjs/toolkit";
import {forgotPasswordRequest} from "./api";
import {
    setState,
    setInput,
    clearInput,
    validateEmail,
    InputInterface,
} from '../validation'

interface forgotPasswordState {
    email: InputInterface;
    response: InputInterface;
    status: "idle" | "pending" | "succeeded" | "failed";
}

const defaultStates = {
        email: {data: '', errorMessage: '', isValid: false},
        response: {data: '', errorMessage: '', isValid: false},
        status: 'idle' as 'idle' | 'pending' | 'succeeded' | 'failed'
    }

const forgotPasswordSlice = createSlice({
    name: "forgotPasswordSlice",
    initialState: defaultStates as forgotPasswordState,
    reducers: {
        resetStates() {
            return defaultStates;
        },
        clearEmail(state) {
            clearInput(state, "email")
        },
        validateEmail(state) {
            validateEmail(state, "email")
        },
        setEmail(state, action) {
            setInput(state, "email", action.payload)
        },
        // setResponse(state, action) {
        //     setState(state, "response", action.payload)
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(forgotPasswordRequest.pending, (state) => {
            state.status = 'pending';
            clearInput(state, 'response')
        })
        builder.addCase(forgotPasswordRequest.fulfilled, (state) => {
            state.status = 'succeeded'
            setState(state, 'response', {
                data: '',
                errorMessage: '',
                isValid: true
            })
        })
        builder.addCase(forgotPasswordRequest.rejected, (state) => {
            state.status = 'failed';
            setState(state, 'response', {
                data: '',
                errorMessage: '',
                isValid: false
            })
            setState(state, 'email', {
                data: '',
                errorMessage: 'Please enter valid email address.',
                isValid: false
            })
        })
    }
});

export const forgotPasswordActions = forgotPasswordSlice.actions;
export default forgotPasswordSlice;