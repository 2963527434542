import React, {useEffect} from 'react';
import Header2 from '../../components/Header2';
import Footer2 from '../../components/Footer2';
import SearchIcon from '@mui/icons-material/Search';
import AdminTable from "../../components/AdminTable";
import {adminActions} from "../../store/Admin/slice";
import OutlinedInput from '@mui/material/OutlinedInput';
import {algorithmRequest} from "../../store/Algorithm/api";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import ExtendAccountDialog from "../../components/ExtendAccountDialog";
import CreateAccountDialog from "../../components/CreateAccountDialog";
import ChangePasswordDialog from "../../components/ChangePasswordDialog";
import AccountPermissionDialog from "../../components/AccountPermissionDialog";
import {userListRequest, requestAccountListRequest, archiveAccountList} from '../../store/Admin/api';
import {
    Box,
    Button,
    Divider,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {userDetailRequest} from "../../store/Auth/api";


export default function AEPAdmin() {
    const currentTab = useAppSelector((state: any) => state.admin.currentTab);
    const filterName = useAppSelector((state: any) => state.admin.filterName);
    const currentSelectedUser = useAppSelector((state: any) => state.admin.currentSelectedUser);
    const currentSelectedAccountRequest = useAppSelector((state: any) => state.admin.currentSelectedAccountRequest);
    const refreshData = useAppSelector((state: any) => state.admin.refreshData);
    const dispatch = useAppDispatch();

    function handleTabChange(event: any, newValue: string) {
        dispatch(adminActions.setTab(newValue))
    }

    function handleCreateAccount() {
        dispatch(adminActions.openCreateAccountDialog())
    }

    function handleFilterList(e: any) {
        dispatch(adminActions.setFilterName(e.target.value))
    }

    React.useEffect(() => {
        dispatch(userListRequest());
        dispatch(requestAccountListRequest());
        dispatch(archiveAccountList());
    }, [filterName])

    React.useEffect(() => {
        dispatch(userDetailRequest())
        dispatch(userListRequest());
        dispatch(requestAccountListRequest());
        dispatch(archiveAccountList());
        dispatch(algorithmRequest());
    }, [dispatch])

    useEffect(() => {
        document.title = 'AEP Admin';
    }, []);

    useEffect(() => {
        if (refreshData === true) {
            dispatch(adminActions.setRefreshData(false))
            dispatch(userDetailRequest())
            dispatch(userListRequest());
            dispatch(requestAccountListRequest());
            dispatch(archiveAccountList());
            dispatch(algorithmRequest());
        }
    }, [refreshData])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: "#F2F5FD"
            }}
        >
            <Header2/>
            <Paper square sx={{pt: 3}}>
                <Typography
                    sx={{my: 4, ml: 4, fontSize: '20px', display: 'inline'}}
                    variant="h6"
                >
                    Account Management
                </Typography>
                <Stack
                    direction='row'
                    sx={{float: 'right', mb: 1}}
                >
                    <OutlinedInput
                        size='small'
                        sx={{width: '325px', fontSize: '14px'}}
                        placeholder='Search by email.'
                        onChange={handleFilterList}
                        value={filterName}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        }
                    />
                    <Button
                        variant='contained'
                        sx={{textTransform: 'none', mx: 1, px: 4, fontSize: '16px', borderRadius: '3px'}}
                        onClick={handleCreateAccount}
                    >
                        Create Account
                    </Button>
                </Stack>

                <Box sx={{mx: 2, mt: 2, overflow: 'auto'}}>
                    <Tabs
                        value={currentTab}
                        TabIndicatorProps={{style: {backgroundColor: '#0067B9', color: '#0067B9'}}}
                        sx={{float: 'left'}}
                        onChange={handleTabChange}
                    >
                        <Tab
                            label="New Requests"
                            sx={{textTransform: 'none', fontSize: '14px'}}
                            value={'1'}
                        />
                        <Tab
                            label="Accounts"
                            sx={{textTransform: 'none', fontSize: '14px'}}
                            value={'2'}
                        />
                        <Tab
                            label="Archived"
                            sx={{textTransform: 'none', fontSize: '14px'}}
                            value={'3'}
                        />
                    </Tabs>
                </Box>
            </Paper>
            <Box sx={{mx: 2, mb: 4}}>
                <AdminTable/>
            </Box>
            <Paper
                square
                component="footer"
                sx={{
                    py: 2,
                    px: 2,
                    mt: 'auto',
                }}
            >
                <Footer2/>
            </Paper>
            <CreateAccountDialog/>
            {currentSelectedAccountRequest !== null && <AccountPermissionDialog/>}
            {currentSelectedUser !== null && <ExtendAccountDialog/>}
            <ChangePasswordDialog/>
        </Box>
    )
}
