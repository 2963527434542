import {Stack} from "@mui/material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";
import React from "react";


export default function FormattedHelperText(props: any) {
    const {text, ...rest} = props;

    return (
        text.length > 0 ? <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
            component="span"
            color="#c70f49"
            sx={{ml: -1.5}}
            {...rest}
        >
            <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
            {text}
        </Stack> : <></>
    )
}