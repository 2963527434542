import axios from 'axios';

export const publicAxios = axios.create({
    baseURL: 'https://aep.analog-dhc.com:8000',
    // baseURL: 'http://localhost:8000',
    headers: {
        'Content-Type': 'application/json'
    }
});

export const authAxios = axios.create({
    baseURL: 'https://aep.analog-dhc.com:8000',
    // baseURL: 'http://localhost:8000',
    headers: {
        'Content-Type': 'application/json'
    }
});

function parseErrorMessage(error: any) {
    try {
        if (error.response.data[0] === "<") return error.message
        const key = Object.keys(error.response.data)[0]
        const value: any = Object.values(error.response.data)[0]
        if (Array.isArray(value))
            return key + " : " + value[0];
        else
            return key + " : " + value;
    } catch (e) {
        return error.message
    }
}

authAxios.interceptors.request.use(function (config) {
    const token = window.localStorage.getItem('access')
    const headers: any = {
        ...config.headers,
        'Authorization': 'Bearer ' + token
    }
    return {...config, headers};
}, function (error) {
    return Promise.reject(error);
});

authAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const errorMessage = parseErrorMessage(error)
    if (errorMessage === "detail : Given token not valid for any token type") {
        window.location.replace('/logout');
    }
    return Promise.reject(error);
});