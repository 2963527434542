import * as React from "react";
import {
    IconButton, Stack,
    TextField
} from "@mui/material";
import {
    Visibility,
    VisibilityOff
} from "@mui/icons-material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";

export default function PasswordTextField(props: any) {
    const {helperText, ...rest} = props;

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const getFormattedHelperText = (text: any) => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                component="span"
                spacing={4}
                color="#c70f49"
                sx={{ml: -1.5}}
            >
                <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
                {text}
            </Stack>
        )
    }

    return (
        <TextField
            type={showPassword ? "text" : "password"}
            helperText={helperText && helperText.length !== 0 ? getFormattedHelperText(helperText) : ""}
            InputProps={{
                endAdornment: (
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        sx={{
                            color: '#757A8B',
                            cursor: 'pointer'
                        }}
                    >
                        {showPassword ? <Visibility
                                // sx={{color: '#0067B9'}}
                            /> :
                            <VisibilityOff
                                // sx={{color: '#0067B9'}}
                            />}
                    </IconButton>
                )
            }}
            {...rest}
        />
    );
}