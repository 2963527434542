import React from 'react';
import {Cached} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import InfoIcon from '@mui/icons-material/Info';
import {useAppSelector} from "../../../store/hooks";
import CSVFileUpload from "../../../components/CSVFileUpload";
import LoadingButton from "../../../components/LoadingButton";
import {FileConfig} from "../../../store/CommonAlgorithm/slice";
import {
    Box,
    Divider,
    Link,
    Paper,
    Stack,
    Typography,
    Tooltip as MuiTooltip,
    TooltipProps,
    tooltipClasses
} from '@mui/material';

export default function InputColumn(props: any) {
    const {
        handleAlgorithmReset,
        AlgorithmTemplateDialog,
        AlgorithmInputs,
        handleAlgorithmEvaluate
    } = props;
    const inputFiles = useAppSelector((state: any) => state.commonAlgorithm.inputFiles);
    const referenceFiles = useAppSelector((state: any) => state.commonAlgorithm.referenceFiles);
    const startEvaluationAPIStatus = useAppSelector((state: any) => state.commonAlgorithm.startEvaluationAPIStatus);
    const [templateDialog, setTemplateDialog] = React.useState<any>(false);

    const CustomTooltip = styled(({className, ...props}: TooltipProps) => (
        <MuiTooltip arrow placement="right" classes={{popper: className}} {...props} children={props.children}/>
    ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#003965',
            maxWidth: '200px'
        },
    }));

    function openTemplateDialog() {
        setTemplateDialog(true);
    }

    function closeTemplateDialog() {
        setTemplateDialog(false);
    }

    return (
        <Paper sx={{height: '100%', position: 'relative',}}>
            <Stack
                direction='row'
                justifyContent={'center'}
                alignItems='center'
                sx={{float: 'left', m: 2}}
            >
                <Typography fontSize={20} fontWeight={"bold"}>Data</Typography>
                <CustomTooltip
                    title="The file you upload it will be also analysed and sanitised during the upload action."
                >
                    <InfoIcon color="action" fontSize="small" sx={{ml: 0.5}}/>
                </CustomTooltip>
            </Stack>
            <Stack
                direction='row'
                justifyContent={'center'}
                sx={{float: 'right', m: 2, cursor: 'pointer'}}
                onClick={handleAlgorithmReset}
            >
                <Cached color='primary' fontSize={"small"}/>
                <Typography color='primary' fontSize={14}>Reset</Typography>
            </Stack>
            <Divider sx={{width: '100%'}}/>
            <Typography align='center' sx={{fontSize: 12, m: 2}}>
                Upload the input file and its reference file (if it is the case).
                For successful results, we encourage you to
                <Link
                    onClick={openTemplateDialog}
                    sx={{cursor: 'pointer', ml: 0.5}}
                >
                    follow our template.
                </Link>
            </Typography>
            <Stack
                sx={{
                    py: 1,
                    m: 1,
                    mx: 3,
                    pb: 4,
                    border: 'dashed',
                    borderWidth: 1,
                    borderRadius: 1,
                    position: 'relative',
                    borderColor: '#C5C7CE',
                    background: 'rgba(242, 245, 253, 0.50)'
                }}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Typography fontSize={12}>
                    <b>Input File</b><i>(mandatory)</i>
                </Typography>
                {inputFiles.map((file: FileConfig, index: number) =>
                    <CSVFileUpload
                        key={index}
                        index={index}
                        fileType={"INPUT"}
                        fileName={"Input.csv"}
                        testid={"input-file-upload"}
                        uploadTestid={"input-file-upload"}
                        uploadedTestid={"input-uploaded"}
                    />
                )}
                <Divider sx={{
                    width: '100%',
                    borderStyle: 'dashed',
                    borderColor: '#C5C7CE',
                }} />
                <Typography fontSize={12}>
                    <b>Reference File</b><i>(optional)</i>
                </Typography>
                {referenceFiles.map((file: FileConfig, index: number) =>
                    <CSVFileUpload
                        key={index}
                        index={index}
                        fileType={"REFERENCE"}
                        fileName={"Reference.csv"}
                        uploadTestid={"reference-file-upload"}
                        uploadedTestid={"reference-uploaded"}
                    />
                )}
            </Stack>
            <Box>
                <AlgorithmInputs/>
            </Box> {/* Prevent absolute from overlapping. */}
            <Box sx={{position: 'absolute', bottom: 0, width: '100%'}}>
                <Divider sx={{width: '100%'}}/>
                <Box sx={{px: 2, py: 1,}}>
                    <LoadingButton
                        onClick={handleAlgorithmEvaluate}
                        disabled={
                            (inputFiles.length > 0 && inputFiles[0].state !== 'Uploaded')
                            || (startEvaluationAPIStatus === "pending")
                        }
                        fullWidth
                        variant="contained"
                        status={startEvaluationAPIStatus}
                        sx={{fontSize: '14px', height: '40px'}}
                    >
                        Evaluate
                    </LoadingButton>
                </Box>
            </Box>
            <AlgorithmTemplateDialog
                fileTemplateDialog={templateDialog}
                closeFileTemplateDialog={closeTemplateDialog}
            />
        </Paper>
    )
}