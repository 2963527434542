import {useAppSelector} from "../store/hooks";
import {Navigate, useLocation} from "react-router-dom";

export default function RequireUserAuth({children}: { children: JSX.Element }) {
    const isLoggedIn = useAppSelector((state: any) => state.auth.isLoggedIn);
    const user = useAppSelector((state: any) => state.auth.user);
    let location = useLocation();

    if (!isLoggedIn || user.groups[0].name !== "user") {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }
    return children;
}