import {RootState} from "../index";
import {authAxios} from "../axiosAPI";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const userListRequest = createAsyncThunk(
    "admin/user_list",
    async (args, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = "/user?limit=" + state.admin.userListSize
        if (state.admin.filterName.length > 0) {
            url = url + "&search_key=" + state.admin.filterName
        }
        const accountData = await authAxios.get(url);
        return accountData.data
    }
);

export const previousUserListRequest = createAsyncThunk(
    "admin/user_list_left",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = new URL(state.admin.userLeftArrowLink);
        const updatedURL = url.href.replace('http://aep.analog-dhc.com:8000', '');
        const accountData = await authAxios.get(updatedURL);
        return accountData.data
    }
)

export const nextUserListRequest = createAsyncThunk(
    "admin/user_list_right",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = new URL(state.admin.userRightArrowLink);
        const updatedURL = url.href.replace('http://aep.analog-dhc.com:8000', '');
        const accountData = await authAxios.get(updatedURL);
        return accountData.data
    }
)

export const requestAccountListRequest = createAsyncThunk(
    "admin/request_account_list",
    async (args, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = "/user/request_accounts/?limit=" + state.admin.accountRequestsListSize
        if (state.admin.filterName.length > 0) {
            url = url + "&search_key=" + state.admin.filterName
        }
        const accountData = await authAxios.get(url);
        return accountData.data
    }
);

export const previousRequestAccountListRequest = createAsyncThunk(
    "admin/request_account_list_left",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = new URL(state.admin.accountRequestsLeftArrowLink);
        const updatedURL = url.href.replace('http://aep.analog-dhc.com:8000', '');
        const accountData = await authAxios.get(updatedURL);
        return accountData.data
    }
)

export const nextRequestAccountListRequest = createAsyncThunk(
    "admin/request_account_list_right",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = new URL(state.admin.accountRequestsRightArrowLink);
        const updatedURL = url.href.replace('http://aep.analog-dhc.com:8000', '');
        const accountData = await authAxios.get(updatedURL);
        return accountData.data
    }
)

export const archiveAccountList = createAsyncThunk(
    "admin/archive",
    async (args, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = "/user/archive/?limit=" + state.admin.archivedListSize
        if (state.admin.filterName.length > 0) {
            url = url + "&search_key=" + state.admin.filterName
        }
        const accountData = await authAxios.get(url);
        return accountData.data
    }
)

export const previousArchiveAccountList = createAsyncThunk(
    "admin/archive_list_left",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = new URL(state.admin.archiveLeftArrowLink);
        const updatedURL = url.href.replace('http://aep.analog-dhc.com:8000', '');
        const accountData = await authAxios.get(updatedURL);
        return accountData.data
    }
)

export const nextArchiveAccountList = createAsyncThunk(
    "admin/archive_list_right",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let url = new URL(state.admin.archiveRightArrowLink);
        const updatedURL = url.href.replace('http://aep.analog-dhc.com:8000', '');
        const accountData = await authAxios.get(updatedURL);
        return accountData.data
    }
)

export const extendDateRequest = createAsyncThunk(
    "users/extend_date_request",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const admin = state.admin;
        const data = {
            email: admin.currentSelectedUser.email,
            expires_on: admin.extendedExpiryDate.data,
        }
        const response = await authAxios.post(
            "/user/extend_account/",
            data
        );
        return response.data;
    }
);


export const archiveAccountRequest = createAsyncThunk(
    "users/archive_account_request",
    async (args: any, _) => {
        const data = {
            email: args.user.email,
        }
        const response = await authAxios.post(
            "/user/archive_account/",
            data
        );
        return response.data;
    }
);

export const approveRequestAccess = createAsyncThunk(
    "users/approve_request_access",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const currentSelectedAccountRequest = state.admin.currentSelectedAccountRequest;
        const requestAccessAlgorithms = state.admin.requestAccessAlgorithms;
        const requestAccessLength = currentSelectedAccountRequest.request_access_set.length;
        const getStatus = (status: any) => {
            if (status === "PENDING") return requestAccessLength === 1 ? "APPROVED" : "REJECTED"
            if (status === true) return "APPROVED"
            else return "REJECTED"
        }
        const data = {
            request_account_id: currentSelectedAccountRequest.id,
            request_access_set: currentSelectedAccountRequest.request_access_set.map((request: any) => {
                return {
                    id: request.id,
                    status: getStatus(request.status)
                }
            }),
            reason: currentSelectedAccountRequest.reason || "",
            algorithm_set: requestAccessAlgorithms.map((algorithm: any) => {
                return {id: algorithm.id}
            }),
        }
        const response = await authAxios.post(
            "/user/request_account_approval/",
            data
        );
        return response.data;
    }
);

export const rejectRequestAccess = createAsyncThunk(
    "users/reject_request_access",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const currentSelectedAccountRequest = state.admin.currentSelectedAccountRequest;
        const requestAccessAlgorithms = state.admin.requestAccessAlgorithms;
        const requestAccessLength = currentSelectedAccountRequest.request_access_set.length;
        const getStatus = (status: any) => {
            if (status === "PENDING") return requestAccessLength === 1 ? "REJECTED" : "APPROVED"
            if (status === true) return "REJECTED"
            else return "APPROVED"
        }
        const data = {
            request_account_id: currentSelectedAccountRequest.id,
            request_access_set: currentSelectedAccountRequest.request_access_set.map((request: any) => {
                return {
                    id: request.id,
                    status: getStatus(request.status)
                }
            }),
            reason: currentSelectedAccountRequest.reason,
            algorithm_set: requestAccessAlgorithms.map((algorithm: any) => {
                return {id: algorithm.id}
            }),
        }
        const response = await authAxios.post(
            "/user/request_account_approval/",
            data
        );
        return response.data;
    }
);

