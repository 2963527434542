import SparkMD5 from "spark-md5";
import {authAxios} from '../axiosAPI';
import {createAsyncThunk} from '@reduxjs/toolkit'
import {RootState} from "../index";
import {Buffer} from "buffer";
import {commonAlgoActions} from "./slice";

function createHeaders(range: string, totalLength: number): any {
    return {
        headers: {
            'Content-Range': `${range}/${totalLength}`,
            'Content-Type': 'multipart/form-data',
        },
    };
}

const CHUNK_SIZE = 1000000; // 1,000,000 bytes

function readChunk(file: Blob, offset: number): Promise<Uint8Array> {
    return new Promise<Uint8Array>((resolve, reject) => {
        const reader = new FileReader();
        const blob = file.slice(offset, offset + CHUNK_SIZE);
        reader.readAsArrayBuffer(blob);
        reader.onload = () => {
            const chunk = new Uint8Array(reader.result as ArrayBuffer);
            resolve(chunk);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
    });
}

export const uploadChunkFile = createAsyncThunk(
    'users/upload_chunk_file',
    async (args: any, thunkAPI) => {
        let setFileProgress;
        let setFileState;
        if (args.type === 'INPUT') {
            setFileProgress = commonAlgoActions.setInputFileProgress
            setFileState = commonAlgoActions.setInputFileState
        } else {
            setFileProgress = commonAlgoActions.setReferenceFileProgress
            setFileState = commonAlgoActions.setReferenceFileState
        }

        try {
            const state = thunkAPI.getState() as RootState;
            const commonAlgorithm = state.commonAlgorithm;
            const totalSize = args.file[0].size
            const hash = new SparkMD5.ArrayBuffer()
            const byteArray = await readChunk(args.file[0], 0)
            hash.append(byteArray)
            const data = {
                "filename": args.type,
                "evaluation": commonAlgorithm.evaluationID,
                "type": args.type,
                // react testing lib is unable to mock the request with BLOB data.
                "file": process.env["IS_TEST_ENV"] ? Buffer.alloc(5) : new Blob([byteArray])
            }
            const range = `bytes ${0}-${byteArray.byteLength - 1}`;
            const response = await authAxios.put(
                '/algorithm/upload_chunk_file/',
                data,
                createHeaders(range, totalSize)
            );
            let offset = byteArray.byteLength;
            const newUrl = response.data.url;
            const newUrlPath = new URL(newUrl).pathname;
            thunkAPI.dispatch(setFileProgress({index: args.index, data: Math.floor((offset / totalSize) * 100)}))

            while (offset < totalSize) {
                if (thunkAPI.signal.aborted) {
                    return thunkAPI.rejectWithValue("Signal Stopped.");
                }
                const byteArray = await readChunk(args.file[0], offset)
                data.file = new Blob([byteArray])
                hash.append(byteArray);
                const range = `bytes ${offset}-${offset + byteArray.byteLength - 1}`;
                await authAxios.put(newUrlPath, data, createHeaders(range, totalSize));
                offset += byteArray.byteLength;
                thunkAPI.dispatch(setFileProgress({index: args.index, data: Math.floor((offset / totalSize) * 100)}))
            }

            const md5Data = {"md5": hash.end()}
            await authAxios.post(newUrlPath, md5Data);
            thunkAPI.dispatch(setFileState({index: args.index, data: "Uploaded"}))
            return response.data;
        } catch (e) {
            thunkAPI.dispatch(setFileState({index: args.index, data: "Error"}))
        }
    }
)

export const createNewEvaluation = createAsyncThunk(
    'users/create_new_evaluation',
    async (args: any) => {
        const response = await authAxios.post(
            "/algorithm/evaluation/",
            {algorithm_name: args.name}
        );
        return response.data;
    }
);

export const deleteEvaluationFile = createAsyncThunk(
    'users/delete_evaluation',
    async (args: any, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        const response = await authAxios.post(
            "/algorithm/delete_evaluation_file/",
            {
                id: commonAlgorithm.evaluationID,
                type: args.type
            }
        );
        return response.data;
    }
);