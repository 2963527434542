import React from "react";
import {Box, Typography} from "@mui/material";

export default function Instructions() {
    return (
        <React.Fragment>
            <Typography fontSize={14} sx={{p: 2}}>
                Heart rate/pulse (HR) is often measured using contact based optical sensors that
                use PPG i.e. the variation of transmissivity and/or reflectivity of light through the
                finger tip as a function of arterial pulsation.
            </Typography>
            <Box sx={{px: 2}}>
                <img
                    src='https://plot.ly/~archidehex/46/heart-rate-of-a-person-vs-time.png'
                    style={{width: '100%'}}
                    alt={"HR"}
                />
            </Box>
        </React.Fragment>
    )
}