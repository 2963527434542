import {PaletteMode} from "@mui/material";
import {Shadows} from "@mui/material/styles/shadows";

export const lightTheme = {
    shape: {
        borderRadius: 8,
    },
    palette: {
        mode: "light" as PaletteMode,
        primary: {
            50: "#F0F7FF",
            100: "#C2E0FF",
            200: "#99CCF3",
            300: "#66B2FF",
            400: "#3399FF",
            500: "#007FFF",
            600: "#0072E5",
            700: "#0059B2",
            800: "#004C99",
            900: "#003A75",
            main: "#0067B9",
            light: "#66B2FF",
            dark: "#0059B2",
            contrastText: "#fff"
        },
        divider: "rgba(0, 0, 0, 0.12)",
        primaryDark: {
            50: "#E2EDF8",
            100: "#CEE0F3",
            200: "#91B9E3",
            300: "#5090D3",
            400: "#265D97",
            500: "#1E4976",
            600: "#173A5E",
            700: "#132F4C",
            800: "#001E3C",
            900: "#0A1929",
            main: "#5090D3"
        },
        common: {
            black: "#1D1D1D",
            white: "#fff"
        },
        text: {
            primary: "#1A2027",
            secondary: "#3E5060",
            dark: '#000000',
            disabled: "rgba(0, 0, 0, 0.38)"
        },
        grey: {
            50: "#F3F6F9",
            100: "#E7EBF0",
            200: "#E0E3E7",
            300: "#CDD2D7",
            400: "#B2BAC2",
            500: "#A0AAB4",
            600: "#6F7E8C",
            700: "#3E5060",
            800: "#2D3843",
            900: "#1A2027",
            A100: "#f5f5f5",
            A200: "#eeeeee",
            A400: "#bdbdbd",
            A700: "#616161"
        },
        error: {
            50: "#FFF0F1",
            100: "#FFDBDE",
            200: "#FFBDC2",
            300: "#FF99A2",
            400: "#FF7A86",
            500: "#FF505F",
            600: "#EB0014",
            700: "#C70011",
            800: "#94000D",
            900: "#570007",
            main: "#c70f49",
            light: "#FF99A2",
            dark: "#C70011",
            contrastText: "#fff"
        },
        success: {
            50: "#E9FBF0",
            100: "#C6F6D9",
            200: "#9AEFBC",
            300: "#6AE79C",
            400: "#3EE07F",
            500: "#21CC66",
            600: "#1DB45A",
            700: "#1AA251",
            800: "#178D46",
            900: "#0F5C2E",
            main: "#009d80",
            light: "#6AE79C",
            dark: "#1AA251",
            contrastText: "#fff"
        },
        warning: {
            50: "#FFF9EB",
            100: "#FFF3C1",
            200: "#FFECA1",
            300: "#FFDC48",
            400: "#F4C000",
            500: "#DEA500",
            600: "#D18E00",
            700: "#AB6800",
            800: "#8C5800",
            900: "#5A3600",
            main: "#DEA500",
            light: "#FFDC48",
            dark: "#AB6800",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        secondary: {
            main: "#0f0f0f",
            light: "#1A1A1A",
            dark: "#000000",
            contrastText: "#fff"
        },
        info: {
            main: "#0288d1",
            light: "#03a9f4",
            dark: "#01579b",
            contrastText: "#fff"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        background: {
            paper: "#fff",
            default: "#fff",
            navBar: 'rgba(255, 255, 255, 0.7)',
        },
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            hoverOpacity: 0.04,
            selected: "rgba(0, 0, 0, 0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(0, 0, 0, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.12
        }
    },
    shadows: [
        'none',
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
    ] as Shadows,
}