import {createSlice} from "@reduxjs/toolkit";
import {
    setInput,
    clearInput, validateInput,
} from '../validation'
import {changePasswordRequest, resetPasswordRequest} from "./api";

const resetPasswordSlice = createSlice({
    name: "resetPasswordSlice",
    initialState: {
        password: {data: '', errorMessage: '', isValid: false},
        currentPassword: {data: '', errorMessage: '', isValid: false},
        confirmPassword: {data: '', errorMessage: '', isValid: false},
        passwordStatus10Characters: null as any,
        passwordStatus1Number: null as any,
        passwordStatus1Capital: null as any,
        passwordStatus1Lower: null as any,
        passwordStatus1Special: null as any,
        response: {data: '', errorMessage: '', status: false},
        status: "idle" as "idle" | "pending" | "succeeded" | "failed",
    },
    reducers: {
        validatePassword(state) {
            const password = state.password.data
            const char10 = password.length >= 10
            const number1 = /\d/.test(password)
            const capital1 = /[A-Z]/.test(password)
            const lower1 = /[a-z]/.test(password)
            const special1 = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)
            state.passwordStatus10Characters = char10
            state.passwordStatus1Number = number1
            state.passwordStatus1Capital = capital1
            state.passwordStatus1Lower = lower1
            state.passwordStatus1Special = special1
        },
        validateCurrentPassword(state) {
            validateInput(state, "currentPassword", "Please enter your password")
        },
        validateConfirmPassword(state) {
            const password = state.password.data
            const confirmPassword = state.confirmPassword.data
            if (confirmPassword.length === 0)
                state.confirmPassword = {
                    data: confirmPassword,
                    errorMessage: 'Please enter your password',
                    isValid: false
                }
            else if (password !== confirmPassword)
                state.confirmPassword = {
                    data: confirmPassword,
                    errorMessage: 'Passwords do not match',
                    isValid: false
                }
            else
                state.confirmPassword = {
                    data: confirmPassword,
                    errorMessage: '',
                    isValid: true
                }
        },
        setPassword(state, action) {
            setInput(state, 'password', action.payload);
        },
        setCurrentPassword(state, action) {
            setInput(state, 'currentPassword', action.payload);
        },
        setConfirmPassword(state, action) {
            setInput(state, 'confirmPassword', action.payload)
        },
        // setResponse(state, action) {
        //     setState(state, "response", action.payload)
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(resetPasswordRequest.pending, (state) => {
            state.status = 'pending';
            clearInput(state, 'response')
        })
        builder.addCase(resetPasswordRequest.fulfilled, (state) => {
            state.status = 'succeeded'
        })
        builder.addCase(resetPasswordRequest.rejected, (state) => {
            state.status = 'failed'
        })
        builder.addCase(changePasswordRequest.pending, (state) => {
            state.status = 'pending';
        })
        builder.addCase(changePasswordRequest.fulfilled, (state) => {
            state.status = 'succeeded'
        })
        builder.addCase(changePasswordRequest.rejected, (state) => {
            state.status = 'failed'
            state.currentPassword = {
                data: '',
                errorMessage: 'Passwords do not match',
                isValid: false
            }
        })
    }
});

export const resetPasswordActions = resetPasswordSlice.actions;
export default resetPasswordSlice;