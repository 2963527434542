import React from 'react';
import {Box, Grid} from '@mui/material';
import Footer2 from "../../../components/Footer2";
import Header2 from "../../../components/Header2";
import AlgorithmSubHeader from "../AlgorithmSubHeader";
import InputColumn from "../AlgorithmPageColumns/InputColumn";
import InstructionColumn from "../AlgorithmPageColumns/InstructionColumn";
import InternetConnectionDialog from "../../../components/InternetConnectionDialog";

export default function AlgorithmBasePage(props: any) {
    const {
        algorithmTitle,
        handleAlgorithmReset,
        AlgorithmInputs,
        AlgorithmInstructions,
        AlgorithmTemplateDialog,
        AlgorithmProcessingPage,
        handleAlgorithmEvaluate
    } = props
    const [showInstruction, setShowInstructions] = React.useState(true);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: "#F2F5FD"
            }}
        >
            <Header2/>
            <AlgorithmSubHeader algorithmTitle={algorithmTitle}/>
            <Grid container>
                <Grid item xs={3} sx={{pt: 3, pl: 2, pb: 3}}>
                    <InputColumn
                        handleAlgorithmReset={handleAlgorithmReset}
                        AlgorithmTemplateDialog={AlgorithmTemplateDialog}
                        AlgorithmInputs={AlgorithmInputs}
                        handleAlgorithmEvaluate={handleAlgorithmEvaluate}
                    />
                </Grid>
                <Grid item xs={showInstruction ? 6 : 9} sx={{pt: 3, pl: 2, pb: 3}}>
                    <AlgorithmProcessingPage
                        setShowInstructions={setShowInstructions}
                        showInstruction={showInstruction}
                    />
                </Grid>
                {showInstruction && <Grid item xs={3} sx={{pl: 2,}}>
                    <InstructionColumn
                        AlgorithmsInstructions={AlgorithmInstructions}
                        setShowInstructions={setShowInstructions}
                    />
                </Grid>}
            </Grid>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: '#fff'
                }}
            >
                <Footer2/>
            </Box>
            <InternetConnectionDialog/>
        </Box>
    )
}