import React from 'react';
import {FormatIndentIncrease,} from "@mui/icons-material";
import {
    Box,
    Divider,
    Paper,
    Typography,
} from '@mui/material';


export default function InstructionColumn(props: any) {
    const {
        setShowInstructions,
        AlgorithmsInstructions,
    } = props

    return (
        <Box sx={{backgroundColor: "#fff", height: '100%', m: 0, p: 2}}>
            <Paper sx={{height: '100%'}}>
                <Typography
                    variant='h6'
                    sx={{m: 2, float: 'left', fontSize: '20px'}}
                    fontWeight={"bold"}
                >
                    Instructions
                </Typography>
                <Box
                    sx={{float: 'right', m: 2, cursor: 'pointer'}}
                    onClick={() => setShowInstructions(false)}
                >
                    <FormatIndentIncrease/>
                </Box>
                <Divider sx={{width: '100%'}}/>
                <AlgorithmsInstructions/>
            </Paper>
        </Box>
    )
}