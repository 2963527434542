import React from 'react';
import validator from 'validator';
import toBoolean = validator.toBoolean;
import LoadingButton from "../LoadingButton";
import SimpleTextField from "../SimpleTextField";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {forgotPasswordRequest} from "../../store/ForgotPassword/api";
import {forgotPasswordActions} from "../../store/ForgotPassword/slice";
import {
    Divider,
    FormControl,
    Typography
} from "@mui/material";

export default function ForgotPasswordFragment() {
    const email = useAppSelector((state: any) => state.forgotPassword.email);
    const successStatus = useAppSelector((state: any) => state.forgotPassword.status);
    const dispatch = useAppDispatch();

    function handleEmailChange(event: any) {
        dispatch(forgotPasswordActions.setEmail(event.target.value))
        dispatch(forgotPasswordActions.validateEmail())
    }

    function clearEmail() {
        dispatch(forgotPasswordActions.clearEmail())
    }

    function sendInstructions() {
        dispatch(forgotPasswordActions.validateEmail())
        if (email.isValid) {
            dispatch(forgotPasswordRequest())
        }
    }

    return (
        <React.Fragment>
            <Typography
                variant="body2"
                color="grey"
                align="left"
                sx={{pl: 6, pr: 3, mb: 2, fontSize: '16px'}}
            >
                Enter the email address below, and we’ll email you instructions on resetting your password.
            </Typography>
            <FormControl
                variant="standard"
                sx={{pl: 3, pr: 3, fontSize: '16px'}}
            >
                Email Address:
                <SimpleTextField
                    sx={{mb: 3, width: 400}}
                    error={toBoolean(email.errorMessage)}
                    helperText={email.errorMessage}
                    onClear={clearEmail}
                    status={successStatus}
                    onChange={handleEmailChange}
                    value={email.data}
                />
            </FormControl>
            <LoadingButton
                onClick={sendInstructions}
                fullWidth
                status={successStatus}
                variant="contained"
                sx={{ml: 3, mr: 3, width: 400, height: 50}}
            >
                Send Instructions
            </LoadingButton>
            <Divider sx={{width: '90%', mt: 3, mb: 2, ml: 3, mr: 3}}/>
        </React.Fragment>
    )
}