import authSlice from "./Auth/slice";
import adminSlice from "./Admin/slice"
import algorithmSlice from "./Algorithm/slice";
import {configureStore} from "@reduxjs/toolkit";
import whrmAlgoSlice from "./WHRMAlgorithm/slice"
import spo2AlgoSlice from "./SpO2Algorithm/slice";
import commonAlgoSlice from "./CommonAlgorithm/slice"
import resetPasswordSlice from "./ResetPassword/slice"
import requestAccountSlice from "./RequestAccount/slice"
import forgotPasswordSlice from "./ForgotPassword/slice"
import evaluationMiddleware from "./CommonAlgorithm/evaluationMiddleware";

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        admin: adminSlice.reducer,
        algorithm: algorithmSlice.reducer,
        whrmAlgorithm: whrmAlgoSlice.reducer,
        spo2Algorithm: spo2AlgoSlice.reducer,
        resetPassword: resetPasswordSlice.reducer,
        requestAccount: requestAccountSlice.reducer,
        forgotPassword: forgotPasswordSlice.reducer,
        commonAlgorithm: commonAlgoSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([evaluationMiddleware])
    },
});
export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
