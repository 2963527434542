import React, {useEffect} from 'react';
import AlgorithmBasePage from "../../components/Algorithm/AlgorithmBasePage";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {commonAlgoActions} from "../../store/CommonAlgorithm/slice";
import Inputs from "../../components/Algorithm/SpO2Algorithm/Inputs";
import Instructions from "../../components/Algorithm/SpO2Algorithm/Instructions";
import TemplateDialog from "../../components/Algorithm/SpO2Algorithm/TemplateDialog";
import ProcessingPage from "../../components/Algorithm/SpO2Algorithm/ProcessingPage";
import {
    getSpO2Output,
    getLineGraphImages,
    getReferenceSpO2Output,
    getSpectrogramGraphImages,
    startSpO2Evaluation
} from "../../store/SpO2Algorithm/api";
import {createNewEvaluation} from "../../store/CommonAlgorithm/api";
import {spo2AlgoActions} from "../../store/SpO2Algorithm/slice";

export default function SpO2Algorithm() {
    const dispatch = useAppDispatch();
    const coefficientA = useAppSelector((state: any) => state.spo2Algorithm.coefficientA);
    const coefficientB = useAppSelector((state: any) => state.spo2Algorithm.coefficientB);
    const coefficientC = useAppSelector((state: any) => state.spo2Algorithm.coefficientC);
    const enableCoefficient = useAppSelector((state: any) => state.spo2Algorithm.enableCoefficient);
    const evaluationID = useAppSelector((state: any) => state.commonAlgorithm.evaluationID);
    const referenceFiles = useAppSelector((state: any) => state.commonAlgorithm.referenceFiles);
    const algorithmState = useAppSelector((state: any) => state.commonAlgorithm.algorithmState);
    const evaluationResult = useAppSelector((state: any) => state.commonAlgorithm.evaluationResult);
    const algorithmEvaluationState = useAppSelector((state: any) => state.commonAlgorithm.algorithmEvaluationState);

    function handleReset() {
        dispatch(commonAlgoActions.resetState())
        dispatch(spo2AlgoActions.resetState())
        dispatch(commonAlgoActions.setInputFileSize(1))
        dispatch(commonAlgoActions.setReferenceFileSize(1))
    }

    useEffect(() => {
        dispatch(commonAlgoActions.setInputFileSize(1))
        dispatch(commonAlgoActions.setReferenceFileSize(1))
    }, [dispatch])

    function handleEvaluate() {
        dispatch(spo2AlgoActions.validateCoefficientA());
        dispatch(spo2AlgoActions.validateCoefficientB());
        dispatch(spo2AlgoActions.validateCoefficientC());
        if (coefficientA.isValid && coefficientB.isValid && coefficientC.isValid && enableCoefficient.isValid) {
            dispatch(startSpO2Evaluation());
        }
    }

    useEffect(() => {
        if (evaluationID === null) {
            dispatch(createNewEvaluation({name: "spo2"}))
        } else {
            dispatch(commonAlgoActions.startWebsocketConnection())
        }
    }, [dispatch, evaluationID])

    useEffect(() => {
        if (algorithmEvaluationState === "Completed" && algorithmState === "AlgoExecution") {
            // AlgoExecution
            dispatch(getSpO2Output())
            if (referenceFiles.length > 0 && referenceFiles[0].state === "Uploaded") {
                dispatch(getReferenceSpO2Output())
            }
        } else if (algorithmEvaluationState === "Completed" && algorithmState === "PostProcessing") {
            // PostProcessing
            dispatch(getLineGraphImages())
            dispatch(getSpectrogramGraphImages())
        }
    }, [dispatch, algorithmEvaluationState, algorithmState, referenceFiles])

    useEffect(() => {
        let RMSE = parseFloat(evaluationResult["rmse"]).toFixed(2)
        let MAE = parseFloat(evaluationResult["mae"]).toFixed(2)
        if (isNaN(Number(RMSE))) RMSE = "-"
        if (isNaN(Number(MAE))) MAE = "-"
        dispatch(spo2AlgoActions.setEvaluationResult({
            RMSE: RMSE,
            MAE: MAE
        }))
    }, [dispatch, evaluationResult])

    return (
        <AlgorithmBasePage
            algorithmTitle={"SpO2 Algorithm"}
            AlgorithmInputs={Inputs}
            AlgorithmInstructions={Instructions}
            AlgorithmTemplateDialog={TemplateDialog}
            AlgorithmProcessingPage={ProcessingPage}
            handleAlgorithmEvaluate={handleEvaluate}
            handleAlgorithmReset={handleReset}
        />
    )
}
