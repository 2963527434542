import React from 'react';
import validator from 'validator';
import flags from "../../assets/flags";
import toBoolean = validator.toBoolean;
import SimpleTextField from "../SimpleTextField";
import ClearIcon from "@mui/icons-material/Clear";
import {getList} from 'country-list-with-dial-code-and-flag'
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {requestAccountActions} from "../../store/RequestAccount/slice";
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    Link,
    MenuItem,
    Paper,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";

const countryInfo = getList();
const purposeArr = ['Purpose1', 'Purpose2', 'Purpose3'];

export default function RequestAccountFragment() {
    const companyName = useAppSelector((state: any) => state.requestAccount.companyName);
    const firstName = useAppSelector((state: any) => state.requestAccount.firstName);
    const lastName = useAppSelector((state: any) => state.requestAccount.lastName);
    const emailAddress = useAppSelector((state: any) => state.requestAccount.emailAddress);
    const countryCode = useAppSelector((state: any) => state.requestAccount.countryCode);
    const phoneNumber = useAppSelector((state: any) => state.requestAccount.phoneNumber);
    const purpose = useAppSelector((state: any) => state.requestAccount.purpose);
    const requestUsers = useAppSelector((state: any) => state.requestAccount.requestUsers);
    const requestStatus = useAppSelector((state: any) => state.requestAccount.status);
    const dispatch = useAppDispatch();

    function clearCompanyName() {
        dispatch(requestAccountActions.clearCompanyName())
    }

    function clearFirstName() {
        dispatch(requestAccountActions.clearFirstName())
    }

    function clearLastName() {
        dispatch(requestAccountActions.clearLastName())
    }

    function clearEmailAddress() {
        dispatch(requestAccountActions.clearEmailAddress())
    }

    function clearPhoneNumber() {
        dispatch(requestAccountActions.clearPhoneNumber())
    }

    function clearUserFirstName(id: any) {
        dispatch(requestAccountActions.clearUserFirstName(id))
    }

    function clearUserLastName(id: any) {
        dispatch(requestAccountActions.clearUserLastName(id))
    }

    function clearUserEmailAddress(id: any) {
        dispatch(requestAccountActions.clearUserEmail(id))
    }

    function handleCompanyNameChange(event: any) {
        dispatch(requestAccountActions.setCompanyName(event.target.value))
        dispatch(requestAccountActions.validateCompanyName())
    }

    function handleFirstNameChange(event: any) {
        dispatch(requestAccountActions.setFirstName(event.target.value))
        dispatch(requestAccountActions.validateFirstName())
    }

    function handleLastNameChange(event: any) {
        dispatch(requestAccountActions.setLastName(event.target.value))
        dispatch(requestAccountActions.validateLastName())
    }

    function handleEmailAddress(event: any) {
        dispatch(requestAccountActions.setEmailAddress(event.target.value))
        dispatch(requestAccountActions.validateEmailAddress())
    }

    function handlePhoneNumberChange(event: any) {
        dispatch(requestAccountActions.setPhoneNumber(event.target.value))
        dispatch(requestAccountActions.validatePhoneNumber())
    }

    function handleCountryCodeChange(event: any) {
        dispatch(requestAccountActions.setCountryCode(event.target.value))
    }

    function handlePurposeChange(event: any) {
        dispatch(requestAccountActions.setPurpose(event.target.value))
        dispatch(requestAccountActions.validatePurpose())
    }

    function handleUserFirstNamesChange(event: any, id: any) {
        dispatch(requestAccountActions.setUserFirstName({data: event.target.value, id: id}))
        dispatch(requestAccountActions.validateUserFirstName(id))
    }

    function handleUserLastNamesChange(event: any, id: any) {
        dispatch(requestAccountActions.setUserLastName({data: event.target.value, id: id}))
        dispatch(requestAccountActions.validateUserLastName(id))
    }

    function handleUserEmailAddressesChange(event: any, id: any) {
        dispatch(requestAccountActions.setUserEmail({data: event.target.value, id: id}))
        dispatch(requestAccountActions.validateUserEmail(id))
    }

    function handleAddUser() {
        dispatch(requestAccountActions.addUser())
    }

    function handleRemoveUser(id: any) {
        dispatch(requestAccountActions.removeUser(id))
    }

    const getFormattedErrorMessage = (message: any) => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                component="span"
                alignItems="center"
                spacing={4}
                color={'#c70f49'}
            >
                <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
                {message}
            </Stack>
        )
    }

    return (
        <React.Fragment>
            <Typography
                variant="body1"
                color="grey"
                align="left"
                sx={{pl: 3, pr: 3, pb: 0.5, fontSize: '18px'}}
            >
                Enter the company and the email address(es) for which you request account(s).
            </Typography>
            <Typography
                variant="body2"
                color="grey"
                align="left"
                sx={{pl: 3, pr: 3, pb: 0.5, fontSize: '14px'}}
            >
                All the fields from above are mandatory to be filled.
            </Typography>
            <FormControl
                variant="standard"
                sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                fullWidth
            >
                Company Name:
                <SimpleTextField
                    error={toBoolean(companyName.errorMessage)}
                    helperText={companyName.errorMessage}
                    onClear={clearCompanyName}
                    onChange={handleCompanyNameChange}
                    value={companyName.data}
                    status={requestStatus}
                />
            </FormControl>
            <Typography
                variant="body1"
                color="grey"
                align="left"
                sx={{pl: 3, pr: 3, mt: 1, fontSize: '18px'}}
            >
                Requester Details
            </Typography>
            <Stack direction={"row"}>
                <FormControl
                    variant="standard"
                    sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                    fullWidth
                >
                    First Name:
                    <SimpleTextField
                        error={toBoolean(firstName.errorMessage)}
                        helperText={firstName.errorMessage}
                        onChange={handleFirstNameChange}
                        onClear={clearFirstName}
                        value={firstName.data}
                        status={requestStatus}
                    />
                </FormControl>
                <FormControl
                    variant="standard"
                    sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                    fullWidth
                >
                    Last Name:
                    <SimpleTextField
                        error={toBoolean(lastName.errorMessage)}
                        helperText={lastName.errorMessage}
                        onChange={handleLastNameChange}
                        onClear={clearLastName}
                        value={lastName.data}
                        status={requestStatus}
                    />
                </FormControl>
            </Stack>
            <FormControl
                variant="standard"
                sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                fullWidth
            >
                Email Address:
                <SimpleTextField
                    error={toBoolean(emailAddress.errorMessage)}
                    helperText={emailAddress.errorMessage}
                    onChange={handleEmailAddress}
                    onClear={clearEmailAddress}
                    autoComplete="Email"
                    value={emailAddress.data}
                    status={requestStatus}
                />
            </FormControl>
            <FormControl
                variant="standard"
                sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                fullWidth
            >
                Phone Number:
                <Stack direction={"row"}>
                    <Select
                        sx={{width: 90, height: 55, mr: '10px', display: 'inline-block', fontSize: '16px'}}
                        variant='outlined'
                        value={countryCode.data as any}
                        onChange={handleCountryCodeChange}
                        renderValue={(selected) => (
                            <img
                                src={flags[selected.split("=")[0]]}
                                style={{width: "36px", height: "24px"}}
                                alt={selected.split("=")[1]}
                            />
                        )}
                    >
                        {countryInfo.map((info: any, index: number) =>
                            info.code !== 'AN' && // flag missing
                            <MenuItem
                                key={index}
                                value={`${info.code}=${info.dial_code}`}
                            >
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                                    <img
                                        src={flags[info.code]}
                                        style={{width: "36px", height: "24px"}}
                                        alt={info.name}
                                    />
                                    <Typography>{info.name}</Typography>
                                    <Typography>{info.dial_code}</Typography>
                                </Box>
                            </MenuItem>
                        )}
                    </Select>
                    <SimpleTextField
                        error={toBoolean(phoneNumber.errorMessage)}
                        onChange={handlePhoneNumberChange}
                        onClear={clearPhoneNumber}
                        value={phoneNumber.data}
                        status={requestStatus}
                    />
                </Stack>
                {phoneNumber.errorMessage ? <FormHelperText>
                    {getFormattedErrorMessage(phoneNumber.errorMessage)}
                </FormHelperText> : ''}
            </FormControl>
            <FormControl
                variant="standard"
                sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                fullWidth
            >
                Purpose:
                <Select
                    variant={'outlined'}
                    value={purpose.data as any}
                    error={toBoolean(purpose.errorMessage)}
                    onChange={handlePurposeChange}
                >
                    {purposeArr.map((i: any, index: number) =>
                        <MenuItem value={i} key={index}>
                            {i}
                        </MenuItem>
                    )}
                </Select>
                {purpose.errorMessage ? <FormHelperText style={{color: 'error'}}>
                    {getFormattedErrorMessage(purpose.errorMessage)}
                </FormHelperText> : ''}
            </FormControl>
            <Typography
                variant="body1"
                color="grey"
                align="left"
                sx={{pl: 3, pr: 3, fontSize: '18px'}}
            >
                Request Access for
            </Typography>
            {requestUsers.map((user: any, index: number) =>
                <Paper
                    variant="outlined"
                    key={index}
                    sx={{
                        backgroundColor: 'inherit',
                        py: 2,
                        mb: 1,
                        position: 'relative'
                    }}
                    data-testid="request-access"
                >
                    {requestUsers.length > 1 &&
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            onClick={() => handleRemoveUser(user.id)}
                        >
                            <ClearIcon/>
                        </IconButton>
                    }
                    <Stack direction={"row"}>
                        <FormControl
                            variant="standard"
                            sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                            fullWidth
                        >
                            First Name:
                            <SimpleTextField
                                error={toBoolean(user.firstName.errorMessage)}
                                helperText={user.firstName.errorMessage}
                                onClear={() => clearUserFirstName(user.id)}
                                onChange={(event: any) => handleUserFirstNamesChange(event, user.id)}
                                value={user.firstName.data}
                                status={requestStatus}
                            />
                        </FormControl>
                        <FormControl
                            variant="standard"
                            sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                            fullWidth
                        >
                            Last Name:
                            <SimpleTextField
                                error={toBoolean(user.lastName.errorMessage)}
                                helperText={user.lastName.errorMessage}
                                onClear={() => clearUserLastName(user.id)}
                                onChange={(event: any) => handleUserLastNamesChange(event, user.id)}
                                value={user.lastName.data}
                                status={requestStatus}
                            />
                        </FormControl>
                    </Stack>
                    <FormControl
                        variant="standard"
                        sx={{pl: 3, pr: 3, my: 1, fontSize: '16px'}}
                        fullWidth
                    >
                        Company Email Address:
                        <SimpleTextField
                            error={toBoolean(user.email.errorMessage)}
                            helperText={user.email.errorMessage}
                            onClear={() => clearUserEmailAddress(user.id)}
                            onChange={(event: any) => handleUserEmailAddressesChange(event, user.id)}
                            value={user.email.data}
                            status={requestStatus}
                        />
                    </FormControl>
                </Paper>
            )}
            <Link
                sx={{
                    textDecoration: 'none',
                    ml: 3,
                    cursor: 'pointer'
                }}
                onClick={handleAddUser}
                fontWeight={"bold"}
            >
                + Add Another User ({5 - requestUsers.length})
            </Link>
        </React.Fragment>
    )
}
