import React from "react";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Health from "../pages/health";
import AEPAdmin from "../pages/AEPAdmin";
import Dashboard from "../pages/Dashboard";
import RequireUserAuth from "./RequireUserAuth";
import RequireAdminAuth from "./RequireAdminAuth";
import ResetPassword from "../pages/ResetPassword";
import WHRMAlgorithm from "../pages/WHRMAlgorithm";
import AlreadyLoginCheck from "./AlreadyLoginCheck";
import {Navigate, useRoutes} from 'react-router-dom';
import ForgotPassword from "../pages/ForgotPassword";
import DefaultLayout from "../layouts/DefaultLayout";
import RequestAccount from "../pages/RequestAccount";
import InternalServerError from "../pages/InternalServerError";
import SpO2Algorithm from "../pages/SpO2Algorithm";

export default function Router() {

    return useRoutes([
        {
            path: '/dashboard',
            element:
                <RequireUserAuth>
                    <DefaultLayout/>
                </RequireUserAuth>,
            children: [
                {path: '', element: <Dashboard/>},
                {path: 'whrm', element: <WHRMAlgorithm/>},
                {path: 'spo2', element: <SpO2Algorithm/>},
            ]
        },
        {
            path: '/admin',
            element:
                <RequireAdminAuth>
                    <DefaultLayout/>
                </RequireAdminAuth>,
            children: [
                {path: '', element: <AEPAdmin/>},
            ]
        },
        {
            path: '/',
            element: <DefaultLayout/>,
            children: [
                {path: '/', element: <Navigate to="/dashboard"/>},
                {path: 'login', element: <AlreadyLoginCheck><Login/></AlreadyLoginCheck>},
                {path: 'forgot_password', element: <ForgotPassword/>},
                {path: 'reset_password', element: <ResetPassword/>},
                {path: 'request_account', element: <RequestAccount/>},
                {path: 'not_found', element: <InternalServerError/>},
                {path: 'logout', element: <Logout/>},
                {path: 'health', element: <Health/>},
            ]
        },
        {path: '*', element: <Navigate to="/not_found" replace/>}
    ]);
}
