import {RootState} from "../index";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {authAxios, publicAxios} from "../axiosAPI";

export const requestAccount = createAsyncThunk(
    "users/request_account",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const requestAccount = state.requestAccount;
        const countryCode = requestAccount.countryCode.data.split("=")[1]
        const data = {
            captcha_key: requestAccount.captchaKey,
            captcha_value: requestAccount.captcha.data,
            company_name: requestAccount.companyName.data,
            first_name: requestAccount.firstName.data,
            last_name: requestAccount.lastName.data,
            email: requestAccount.emailAddress.data,
            phone: countryCode + "-" + requestAccount.phoneNumber.data,
            purpose: requestAccount.purpose.data,
            request_access_users: requestAccount.requestUsers.map((user) => {
                return {
                    first_name: user.firstName.data,
                    last_name: user.lastName.data,
                    email: user.email.data,
                };
            }),
        };
        try {
            const response = await publicAxios.post(
                "/user/request_account/",
                data
            );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const requestAccountByAdmin = createAsyncThunk(
    "users/request_account_admin",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const requestAccount = state.requestAccount;
        const countryCode = requestAccount.countryCode.data.split("=")[1]
        const data = {
            company_name: requestAccount.companyName.data,
            first_name: requestAccount.firstName.data,
            last_name: requestAccount.lastName.data,
            email: requestAccount.emailAddress.data,
            phone: countryCode + "-" + requestAccount.phoneNumber.data,
            purpose: requestAccount.purpose.data,
            request_access_users: requestAccount.requestUsers.map((user) => {
                return {
                    first_name: user.firstName.data,
                    last_name: user.lastName.data,
                    email: user.email.data,
                };
            }),
            algorithm_set: requestAccount.algorithm.data.map((algorithm: any) => {
                return {
                    id: algorithm.id
                };
            }),
        };
        try {
            const response = await authAxios.post(
                "/user/request_account_admin/",
                data
            );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const requestCaptcha = createAsyncThunk(
    "users/captcha",
    async () => {
        const response = await publicAxios.post("/captcha/");
        return response.data;
    });
