import AD from './png/ad.png';
import AE from './png/ae.png';
import AF from './png/af.png';
import AG from './png/ag.png';
import AI from './png/ai.png';
import AL from './png/al.png';
import AM from './png/am.png';
import AO from './png/ao.png';
import AQ from './png/aq.png';
import AR from './png/ar.png';
import AS from './png/as.png';
import AT from './png/at.png';
import AU from './png/au.png';
import AW from './png/aw.png';
import AX from './png/ax.png';
import AZ from './png/az.png';
import BA from './png/ba.png';
import BB from './png/bb.png';
import BD from './png/bd.png';
import BE from './png/be.png';
import BF from './png/bf.png';
import BG from './png/bg.png';
import BH from './png/bh.png';
import BI from './png/bi.png';
import BJ from './png/bj.png';
import BL from './png/bl.png';
import BM from './png/bm.png';
import BN from './png/bn.png';
import BO from './png/bo.png';
import BQ from './png/bq.png';
import BR from './png/br.png';
import BS from './png/bs.png';
import BT from './png/bt.png';
import BV from './png/bv.png';
import BW from './png/bw.png';
import BY from './png/by.png';
import BZ from './png/bz.png';
import CA from './png/ca.png';
import CC from './png/cc.png';
import CD from './png/cd.png';
import CF from './png/cf.png';
import CG from './png/cg.png';
import CH from './png/ch.png';
import CI from './png/ci.png';
import CK from './png/ck.png';
import CL from './png/cl.png';
import CM from './png/cm.png';
import CN from './png/cn.png';
import CO from './png/co.png';
import CR from './png/cr.png';
import CU from './png/cu.png';
import CV from './png/cv.png';
import CW from './png/cw.png';
import CX from './png/cx.png';
import CY from './png/cy.png';
import CZ from './png/cz.png';
import DE from './png/de.png';
import DJ from './png/dj.png';
import DK from './png/dk.png';
import DM from './png/dm.png';
import DO from './png/do.png';
import DZ from './png/dz.png';
import EC from './png/ec.png';
import EE from './png/ee.png';
import EG from './png/eg.png';
import EH from './png/eh.png';
import ER from './png/er.png';
import ES from './png/es.png';
import ET from './png/et.png';
import EU from './png/eu.png';
import FI from './png/fi.png';
import FJ from './png/fj.png';
import FK from './png/fk.png';
import FM from './png/fm.png';
import FO from './png/fo.png';
import FR from './png/fr.png';
import GA from './png/ga.png';
import GB_ENG from './png/gb-eng.png';
import GB_NIR from './png/gb-nir.png';
import GB_SCT from './png/gb-sct.png';
import GB_WLS from './png/gb-wls.png';
import GB from './png/gb.png';
import GD from './png/gd.png';
import GE from './png/ge.png';
import GF from './png/gf.png';
import GG from './png/gg.png';
import GH from './png/gh.png';
import GI from './png/gi.png';
import GL from './png/gl.png';
import GM from './png/gm.png';
import GN from './png/gn.png';
import GP from './png/gp.png';
import GQ from './png/gq.png';
import GR from './png/gr.png';
import GS from './png/gs.png';
import GT from './png/gt.png';
import GU from './png/gu.png';
import GW from './png/gw.png';
import GY from './png/gy.png';
import HK from './png/hk.png';
import HM from './png/hm.png';
import HN from './png/hn.png';
import HR from './png/hr.png';
import HT from './png/ht.png';
import HU from './png/hu.png';
import ID from './png/id.png';
import IE from './png/ie.png';
import IL from './png/il.png';
import IM from './png/im.png';
import IN from './png/in.png';
import IO from './png/io.png';
import IQ from './png/iq.png';
import IR from './png/ir.png';
import IS from './png/is.png';
import IT from './png/it.png';
import JE from './png/je.png';
import JM from './png/jm.png';
import JO from './png/jo.png';
import JP from './png/jp.png';
import KE from './png/ke.png';
import KG from './png/kg.png';
import KH from './png/kh.png';
import KI from './png/ki.png';
import KM from './png/km.png';
import KN from './png/kn.png';
import KP from './png/kp.png';
import KR from './png/kr.png';
import KW from './png/kw.png';
import KY from './png/ky.png';
import KZ from './png/kz.png';
import LA from './png/la.png';
import LB from './png/lb.png';
import LC from './png/lc.png';
import LI from './png/li.png';
import LK from './png/lk.png';
import LR from './png/lr.png';
import LS from './png/ls.png';
import LT from './png/lt.png';
import LU from './png/lu.png';
import LV from './png/lv.png';
import LY from './png/ly.png';
import MA from './png/ma.png';
import MC from './png/mc.png';
import MD from './png/md.png';
import ME from './png/me.png';
import MF from './png/mf.png';
import MG from './png/mg.png';
import MH from './png/mh.png';
import MK from './png/mk.png';
import ML from './png/ml.png';
import MM from './png/mm.png';
import MN from './png/mn.png';
import MO from './png/mo.png';
import MP from './png/mp.png';
import MQ from './png/mq.png';
import MR from './png/mr.png';
import MS from './png/ms.png';
import MT from './png/mt.png';
import MU from './png/mu.png';
import MV from './png/mv.png';
import MW from './png/mw.png';
import MX from './png/mx.png';
import MY from './png/my.png';
import MZ from './png/mz.png';
import NA from './png/na.png';
import NC from './png/nc.png';
import NE from './png/ne.png';
import NF from './png/nf.png';
import NG from './png/ng.png';
import NI from './png/ni.png';
import NL from './png/nl.png';
import NO from './png/no.png';
import NP from './png/np.png';
import NR from './png/nr.png';
import NU from './png/nu.png';
import NZ from './png/nz.png';
import OM from './png/om.png';
import PA from './png/pa.png';
import PE from './png/pe.png';
import PF from './png/pf.png';
import PG from './png/pg.png';
import PH from './png/ph.png';
import PK from './png/pk.png';
import PL from './png/pl.png';
import PM from './png/pm.png';
import PN from './png/pn.png';
import PR from './png/pr.png';
import PS from './png/ps.png';
import PT from './png/pt.png';
import PW from './png/pw.png';
import PY from './png/py.png';
import QA from './png/qa.png';
import RE from './png/re.png';
import RO from './png/ro.png';
import RS from './png/rs.png';
import RU from './png/ru.png';
import RW from './png/rw.png';
import SA from './png/sa.png';
import SB from './png/sb.png';
import SC from './png/sc.png';
import SD from './png/sd.png';
import SE from './png/se.png';
import SG from './png/sg.png';
import SH from './png/sh.png';
import SI from './png/si.png';
import SJ from './png/sj.png';
import SK from './png/sk.png';
import SL from './png/sl.png';
import SM from './png/sm.png';
import SN from './png/sn.png';
import SO from './png/so.png';
import SR from './png/sr.png';
import SS from './png/ss.png';
import ST from './png/st.png';
import SV from './png/sv.png';
import SX from './png/sx.png';
import SY from './png/sy.png';
import SZ from './png/sz.png';
import TC from './png/tc.png';
import TD from './png/td.png';
import TF from './png/tf.png';
import TG from './png/tg.png';
import TH from './png/th.png';
import TJ from './png/tj.png';
import TK from './png/tk.png';
import TL from './png/tl.png';
import TM from './png/tm.png';
import TN from './png/tn.png';
import TO from './png/to.png';
import TR from './png/tr.png';
import TT from './png/tt.png';
import TV from './png/tv.png';
import TW from './png/tw.png';
import TZ from './png/tz.png';
import UA from './png/ua.png';
import UG from './png/ug.png';
import UM from './png/um.png';
import US from './png/us.png';
import UY from './png/uy.png';
import UZ from './png/uz.png';
import VA from './png/va.png';
import VC from './png/vc.png';
import VE from './png/ve.png';
import VG from './png/vg.png';
import VI from './png/vi.png';
import VN from './png/vn.png';
import VU from './png/vu.png';
import WF from './png/wf.png';
import WS from './png/ws.png';
import XK from './png/xk.png';
import YE from './png/ye.png';
import YT from './png/yt.png';
import ZA from './png/za.png';
import ZM from './png/zm.png';
import ZW from './png/zw.png';

const flags: any = {
    'AD': AD,
    'AE': AE,
    'AF': AF,
    'AG': AG,
    'AI': AI,
    'AL': AL,
    'AM': AM,
    'AO': AO,
    'AQ': AQ,
    'AR': AR,
    'AS': AS,
    'AT': AT,
    'AU': AU,
    'AW': AW,
    'AX': AX,
    'AZ': AZ,
    'BA': BA,
    'BB': BB,
    'BD': BD,
    'BE': BE,
    'BF': BF,
    'BG': BG,
    'BH': BH,
    'BI': BI,
    'BJ': BJ,
    'BL': BL,
    'BM': BM,
    'BN': BN,
    'BO': BO,
    'BQ': BQ,
    'BR': BR,
    'BS': BS,
    'BT': BT,
    'BV': BV,
    'BW': BW,
    'BY': BY,
    'BZ': BZ,
    'CA': CA,
    'CC': CC,
    'CD': CD,
    'CF': CF,
    'CG': CG,
    'CH': CH,
    'CI': CI,
    'CK': CK,
    'CL': CL,
    'CM': CM,
    'CN': CN,
    'CO': CO,
    'CR': CR,
    'CU': CU,
    'CV': CV,
    'CW': CW,
    'CX': CX,
    'CY': CY,
    'CZ': CZ,
    'DE': DE,
    'DJ': DJ,
    'DK': DK,
    'DM': DM,
    'DO': DO,
    'DZ': DZ,
    'EC': EC,
    'EE': EE,
    'EG': EG,
    'EH': EH,
    'ER': ER,
    'ES': ES,
    'ET': ET,
    'EU': EU,
    'FI': FI,
    'FJ': FJ,
    'FK': FK,
    'FM': FM,
    'FO': FO,
    'FR': FR,
    'GA': GA,
    'GB-ENG': GB_ENG,
    'GB-NIR': GB_NIR,
    'GB-SCT': GB_SCT,
    'GB-WLS': GB_WLS,
    'GB': GB,
    'GD': GD,
    'GE': GE,
    'GF': GF,
    'GG': GG,
    'GH': GH,
    'GI': GI,
    'GL': GL,
    'GM': GM,
    'GN': GN,
    'GP': GP,
    'GQ': GQ,
    'GR': GR,
    'GS': GS,
    'GT': GT,
    'GU': GU,
    'GW': GW,
    'GY': GY,
    'HK': HK,
    'HM': HM,
    'HN': HN,
    'HR': HR,
    'HT': HT,
    'HU': HU,
    'ID': ID,
    'IE': IE,
    'IL': IL,
    'IM': IM,
    'IN': IN,
    'IO': IO,
    'IQ': IQ,
    'IR': IR,
    'IS': IS,
    'IT': IT,
    'JE': JE,
    'JM': JM,
    'JO': JO,
    'JP': JP,
    'KE': KE,
    'KG': KG,
    'KH': KH,
    'KI': KI,
    'KM': KM,
    'KN': KN,
    'KP': KP,
    'KR': KR,
    'KW': KW,
    'KY': KY,
    'KZ': KZ,
    'LA': LA,
    'LB': LB,
    'LC': LC,
    'LI': LI,
    'LK': LK,
    'LR': LR,
    'LS': LS,
    'LT': LT,
    'LU': LU,
    'LV': LV,
    'LY': LY,
    'MA': MA,
    'MC': MC,
    'MD': MD,
    'ME': ME,
    'MF': MF,
    'MG': MG,
    'MH': MH,
    'MK': MK,
    'ML': ML,
    'MM': MM,
    'MN': MN,
    'MO': MO,
    'MP': MP,
    'MQ': MQ,
    'MR': MR,
    'MS': MS,
    'MT': MT,
    'MU': MU,
    'MV': MV,
    'MW': MW,
    'MX': MX,
    'MY': MY,
    'MZ': MZ,
    'NA': NA,
    'NC': NC,
    'NE': NE,
    'NF': NF,
    'NG': NG,
    'NI': NI,
    'NL': NL,
    'NO': NO,
    'NP': NP,
    'NR': NR,
    'NU': NU,
    'NZ': NZ,
    'OM': OM,
    'PA': PA,
    'PE': PE,
    'PF': PF,
    'PG': PG,
    'PH': PH,
    'PK': PK,
    'PL': PL,
    'PM': PM,
    'PN': PN,
    'PR': PR,
    'PS': PS,
    'PT': PT,
    'PW': PW,
    'PY': PY,
    'QA': QA,
    'RE': RE,
    'RO': RO,
    'RS': RS,
    'RU': RU,
    'RW': RW,
    'SA': SA,
    'SB': SB,
    'SC': SC,
    'SD': SD,
    'SE': SE,
    'SG': SG,
    'SH': SH,
    'SI': SI,
    'SJ': SJ,
    'SK': SK,
    'SL': SL,
    'SM': SM,
    'SN': SN,
    'SO': SO,
    'SR': SR,
    'SS': SS,
    'ST': ST,
    'SV': SV,
    'SX': SX,
    'SY': SY,
    'SZ': SZ,
    'TC': TC,
    'TD': TD,
    'TF': TF,
    'TG': TG,
    'TH': TH,
    'TJ': TJ,
    'TK': TK,
    'TL': TL,
    'TM': TM,
    'TN': TN,
    'TO': TO,
    'TR': TR,
    'TT': TT,
    'TV': TV,
    'TW': TW,
    'TZ': TZ,
    'UA': UA,
    'UG': UG,
    'UM': UM,
    'US': US,
    'UY': UY,
    'UZ': UZ,
    'VA': VA,
    'VC': VC,
    'VE': VE,
    'VG': VG,
    'VI': VI,
    'VN': VN,
    'VU': VU,
    'WF': WF,
    'WS': WS,
    'XK': XK,
    'YE': YE,
    'YT': YT,
    'ZA': ZA,
    'ZM': ZM,
    'ZW': ZW,
};

export default flags;