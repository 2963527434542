import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";

export default function Footer(props: any) {

    return (
        <>
            <Divider sx={{mt: 8, mb: 4, borderColor: 'rgba(255, 255, 255, 0.2)'}}/>
            <Typography variant="body2" align="center" color={"white"} {...props}>
                <b>Analog Devices Confidential Information - Not for External Distribution.</b>
                <br/>
                © 1955 - 2023 <b>Analog Devices, </b>
                <Link color="#9ED3FF" href="https://analog.com/">
                    Inc. All Rights Reserved
                </Link>{' '}
            </Typography>
        </>
    );
}