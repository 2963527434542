import {Buffer} from "buffer";
import {RootState} from "../index";
import {authAxios} from '../axiosAPI';
import {CSVToArrParser} from "../validation";
import {createAsyncThunk} from '@reduxjs/toolkit'
import {commonAlgoActions} from "../CommonAlgorithm/slice";

export const startSpO2Evaluation = createAsyncThunk(
    'spo2/start_evaluation',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const spo2Algorithm = state.spo2Algorithm;
        const commonAlgorithm = state.commonAlgorithm;
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationAPIStatus("pending"))
        const data = {
            id: commonAlgorithm.evaluationID,
            default_coefficient: spo2Algorithm.enableCoefficient.data,
            coefficient_a: spo2Algorithm.coefficientA.data,
            coefficient_b: spo2Algorithm.coefficientB.data,
            coefficient_c: spo2Algorithm.coefficientC.data
        };
        const response = await authAxios.post(
            "/algorithm/start_spo2_evaluation/",
            data
        );
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationAPIStatus("succeeded"))
        return response.data;
    }
);

export const startSpO2SyncEvaluation = createAsyncThunk(
    'spo2/algo_sync',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        const spo2Algorithm = state.spo2Algorithm;
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationSyncAPIStatus("pending"))
        const data = {
            id: commonAlgorithm.evaluationID,
            time_diff_in_sec: spo2Algorithm.timeDiffInSec,
        };
        const response = await authAxios.post(
            "/algorithm/start_spo2_sync_evaluation/",
            data
        );
        thunkAPI.dispatch(commonAlgoActions.setStartEvaluationSyncAPIStatus("succeeded"))
        return response.data;
    }
);

export const getSpO2Output = createAsyncThunk(
    'spo2/csv1',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        const response = await authAxios.get(
            '/files/evaluation_files/' + commonAlgorithm.evaluationID + '/OUTPUT/SHORT_OUTPUT.csv',
        );
        return CSVToArrParser(response.data, 0);
    }
)

export const getReferenceSpO2Output = createAsyncThunk(
    'spo2/csv2',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        const response = await authAxios.get(
            '/files/evaluation_files/' + commonAlgorithm.evaluationID + '/OUTPUT/SHORT_REFERENCE.csv',
        );
        return CSVToArrParser(response.data, 0, 0);
    }
)

export const getLineGraphImages = createAsyncThunk(
    'spo2/line_graph',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        let result = []

        try {
            const rawPPG = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/spo2_out_vs_ref.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(rawPPG.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/spo2_out.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const whrm_out = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/input_ir_ppg.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(whrm_out.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const whrm5 = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/input_red_ppg.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(whrm5.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const whrm10 = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/input_accel_mag.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(whrm10.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/spo2_confidence.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/ir_pi.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/red_pi.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/low_pi_flag.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/low_signal_quality_flag.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/unreliable_r_flag.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/motion_flag.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        return result
    }
)

export const getSpectrogramGraphImages = createAsyncThunk(
    'spo2/spectrogram',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const commonAlgorithm = state.commonAlgorithm;
        let result = []
        try {
            const rawPPG = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/ir_ppg_spectrogram.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(rawPPG.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const accel = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/red_ppg_spectrogram.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(accel.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        try {
            const rawPPGHR = await authAxios.get(
                "/files/evaluation_files/" + commonAlgorithm.evaluationID + "/OUTPUT/accel_spectrogram.png",
                {responseType: 'arraybuffer'}
            );
            result.push(Buffer.from(rawPPGHR.data, 'binary').toString('base64'))
        } catch (e) {
            result.push(null)
        }

        return result
    }
)