import {createSlice} from "@reduxjs/toolkit";
import _ from "lodash";
import EvaluationEvent from "./evaluationEvent";
import {createNewEvaluation} from "./api";

export type FileUploadState = "Initial" | "Uploading" | "Error" | "Uploaded";
export type EvaluationTimelineState = "Initial" | "Running" | "Completed" | "Error";
export type AlgorithmEvaluationState = "Initial" | "Running" | "Completed" | "Error";
export type AlgorithmState = "Initial" | "AlgoExecution" | "PostProcessing";
export type APIStatus = "idle" | "pending" | "succeeded" | "failed";


export interface FileConfig {
    file: any;
    state: FileUploadState;
    progress: number;
}

export interface TimelineConfig {
    name: string,
    status: EvaluationEvent,
    time: string
}

const initialState = {
    inputFiles: [] as FileConfig[] | [],
    referenceFiles: [] as FileConfig[] | [],
    evaluationID: null as any,
    chunk: null as any,
    chunkSize: 1024 * 1024 as number,
    uploadURL: '' as string,
    isWebsocketConnected: false,
    isWebsocketDisconnected: false,
    evaluationTimelineData: [] as [TimelineConfig] | [],
    evaluationTimelineState: "Initial" as EvaluationTimelineState,
    algorithmEvaluationState: "Initial" as AlgorithmEvaluationState,
    algorithmState: "Initial" as AlgorithmState,
    startEvaluationAPIStatus: "idle" as APIStatus,
    startEvaluationSyncAPIStatus: "idle" as APIStatus,
    enableShowEvaluationResultButton: false,
    evaluationResult: {},
    tab: 0,
}

const commonAlgoSlice = createSlice({
    name: "commonAlgoSlice",
    initialState: initialState,
    reducers: {
        resetState() {
            return initialState
        },
        setTab(state, action) {
            state.tab = action.payload
        },
        setStartEvaluationAPIStatus(state, action) {
            if (action.payload === "pending") {
                state.enableShowEvaluationResultButton = false
            } else if (action.payload === "succeeded") {
                state.algorithmState = "AlgoExecution"
                state.algorithmEvaluationState = 'Running'
                state.evaluationTimelineState = "Initial"
            }
            state.startEvaluationAPIStatus = action.payload
        },
        setStartEvaluationSyncAPIStatus(state, action) {
            if (action.payload === "pending") {
                state.enableShowEvaluationResultButton = false
            } else if (action.payload === "succeeded") {
                state.algorithmState = "PostProcessing"
                state.algorithmEvaluationState = 'Running'
                state.evaluationTimelineState = "Initial"
            }
            state.startEvaluationSyncAPIStatus = action.payload
        },
        startWebsocketConnection() {
        },
        setWebsocketConnected(state, action) {
            state.isWebsocketConnected = action.payload
        },
        setWebsocketDisconnected(state, action) {
            state.isWebsocketDisconnected = action.payload
        },
        setEvaluationTimelineStates(state, action) {
            console.log(action.payload)
            state.evaluationTimelineData = action.payload.map((item: any) => {
                return {
                    name: item,
                    status: EvaluationEvent.INIT,
                    time: "-"
                }
            })
        },
        updateEvaluationTimelineStates(state, action) {
            console.log(action.payload)
            if (action.payload.status === EvaluationEvent.TIME_INFO) {
                const arr = action.payload.data.split(" ");
                const lastElement = arr.pop();
                const remainingStr = arr.join(" ");

                const index = _.findIndex(state.evaluationTimelineData, {name: remainingStr});
                if (index !== -1) {
                    state.evaluationTimelineData[index].time = parseFloat(lastElement).toFixed(2) + " sec" || "-";
                }
            } else if (action.payload.status === EvaluationEvent.FAILED) {
                const arr = action.payload.data.split(" $$$ ");
                const index = _.findIndex(
                    state.evaluationTimelineData,
                    {name: arr[0]}
                );
                if (index !== -1) {
                    state.evaluationTimelineData[index].status = action.payload.status;
                    state.evaluationTimelineData[index].time = arr[1];
                }
                state.evaluationTimelineState = "Error"
            } else if (action.payload.status === EvaluationEvent.TIME_END) {
                if (state.evaluationTimelineState !== "Error")
                    state.enableShowEvaluationResultButton = true
            } else if (action.payload.status === EvaluationEvent.RESULT) {
                state.evaluationResult = action.payload.data;
            } else if (action.payload.status === EvaluationEvent.STEP_LIST) {
            } else {
                const index = _.findIndex(state.evaluationTimelineData, {name: action.payload.data});
                if (index !== -1) {
                    state.evaluationTimelineData[index].status = action.payload.status;
                }
            }
        },
        setEvaluationCompletedState(state) {
            state.evaluationTimelineState = "Completed"
            state.algorithmEvaluationState = "Completed"
        },
        setChunk(state, action) {
            state.chunk = action.payload;
        },
        setChunkSize(state, action) {
            state.chunkSize = action.payload;
        },
        setInputFileSize(state, action) {
            const size = action.payload;
            state.inputFiles = []
            for (let i = 0; i < size; i++) {
                state.inputFiles = [...state.inputFiles, {
                    file: null,
                    state: "Initial",
                    progress: 0
                }]
            }
        },
        setReferenceFileSize(state, action) {
            const size = action.payload;
            state.referenceFiles = []
            for (let i = 0; i < size; i++) {
                state.referenceFiles = [...state.referenceFiles, {
                    file: null,
                    state: "Initial",
                    progress: 0
                }]
            }
        },
        setSelectedInputFile(state, action) {
            const index = action.payload.index
            state.inputFiles[index].file = action.payload.data;
            state.inputFiles[index].state = "Uploading"
            state.inputFiles[index].progress = 0
        },
        setSelectedReferenceFile(state, action) {
            const index = action.payload.index
            state.referenceFiles[index].file = action.payload.data;
            state.referenceFiles[index].state = "Uploading"
            state.referenceFiles[index].progress = 0
        },
        setInputFileState(state, action) {
            const index = action.payload.index
            state.inputFiles[index].state = action.payload.data;
        },
        setReferenceFileState(state, action) {
            const index = action.payload.index
            state.referenceFiles[index].state = action.payload.data;
        },
        setInputFileProgress(state, action) {
            const index = action.payload.index
            state.inputFiles[index].progress = action.payload.data;
        },
        setReferenceFileProgress(state, action) {
            const index = action.payload.index
            state.referenceFiles[index].progress = action.payload.data;
        },
        updateTestReferenceFileState(state, action) {
            const index = action.payload.index
            state.referenceFiles[index].state = action.payload.state
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createNewEvaluation.fulfilled, (state, action) => {
            state.evaluationID = action.payload.id
        })

    }
});

export const commonAlgoActions = commonAlgoSlice.actions;
export default commonAlgoSlice;

