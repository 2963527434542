import React, {useEffect} from 'react';
import {whrmAlgoActions} from "../../store/WHRMAlgorithm/slice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {commonAlgoActions} from "../../store/CommonAlgorithm/slice";
import {createNewEvaluation} from "../../store/CommonAlgorithm/api";
import Inputs from '../../components/Algorithm/WHRMAlgorithm/Inputs';
import AlgorithmBasePage from "../../components/Algorithm/AlgorithmBasePage";
import Instructions from "../../components/Algorithm/WHRMAlgorithm/Instructions";
import TemplateDialog from "../../components/Algorithm/WHRMAlgorithm/TemplateDialog";
import ProcessingPage from "../../components/Algorithm/WHRMAlgorithm/ProcessingPage";
import {
    getHROutput,
    getLineGraphImages,
    getReferenceHROutput,
    getSpectrogramGraphImages,
    startEvaluation
} from "../../store/WHRMAlgorithm/api";

export default function WHRMAlgorithm() {
    const dispatch = useAppDispatch();
    const age = useAppSelector((state: any) => state.whrmAlgorithm.age);
    const height = useAppSelector((state: any) => state.whrmAlgorithm.height);
    const weight = useAppSelector((state: any) => state.whrmAlgorithm.weight);
    const gender = useAppSelector((state: any) => state.whrmAlgorithm.gender);
    const evaluationID = useAppSelector((state: any) => state.commonAlgorithm.evaluationID);
    const referenceFiles = useAppSelector((state: any) => state.commonAlgorithm.referenceFiles);
    const algorithmState = useAppSelector((state: any) => state.commonAlgorithm.algorithmState);
    const evaluationResult = useAppSelector((state: any) => state.commonAlgorithm.evaluationResult);
    const algorithmEvaluationState = useAppSelector((state: any) => state.commonAlgorithm.algorithmEvaluationState);

    useEffect(() => {
        dispatch(commonAlgoActions.setInputFileSize(1))
        dispatch(commonAlgoActions.setReferenceFileSize(1))
    }, [dispatch])

    function handleReset() {
        dispatch(commonAlgoActions.resetState())
        dispatch(whrmAlgoActions.resetState())
        dispatch(commonAlgoActions.setInputFileSize(1))
        dispatch(commonAlgoActions.setReferenceFileSize(1))
    }

    function handleEvaluate() {
        dispatch(whrmAlgoActions.validateAge());
        dispatch(whrmAlgoActions.validateHeight());
        dispatch(whrmAlgoActions.validateWeight());
        dispatch(whrmAlgoActions.validateGender());
        if (age.isValid && weight.isValid && height.isValid && gender.isValid) {
            dispatch(startEvaluation());
        }
    }

    useEffect(() => {
        if (evaluationID === null) {
            dispatch(createNewEvaluation({name: "whrm"}))
        } else {
            dispatch(commonAlgoActions.startWebsocketConnection())
        }
    }, [dispatch, evaluationID])

    useEffect(() => {
        if (algorithmEvaluationState === "Completed" && algorithmState === "AlgoExecution") {
            // AlgoExecution
            dispatch(getHROutput())
            if (referenceFiles.length > 0 && referenceFiles[0].state === "Uploaded") {
                dispatch(getReferenceHROutput())
            }
        } else if (algorithmEvaluationState === "Completed" && algorithmState === "PostProcessing") {
            // PostProcessing
            dispatch(getLineGraphImages())
            dispatch(getSpectrogramGraphImages())
        }
    }, [dispatch, algorithmEvaluationState, algorithmState, referenceFiles])

    useEffect(() => {
        let bpm5Acc = parseFloat(evaluationResult["accuracy5"]).toFixed(2)
        let bpm10Acc = parseFloat(evaluationResult["accuracy10"]).toFixed(2)
        let MAE = parseFloat(evaluationResult["mean_absolute_error"]).toFixed(2)
        if (isNaN(Number(bpm5Acc))) bpm5Acc = "-"
        if (isNaN(Number(bpm10Acc))) bpm10Acc = "-"
        if (isNaN(Number(MAE))) MAE = "-"
        dispatch(whrmAlgoActions.setEvaluationResult({
            bpm5Acc: bpm5Acc,
            bpm10Acc: bpm10Acc,
            MAE: MAE
        }))
    }, [dispatch, evaluationResult])

    return (
        <AlgorithmBasePage
            algorithmTitle={"Heart Rate Algorithm"}
            AlgorithmInputs={Inputs}
            AlgorithmInstructions={Instructions}
            AlgorithmTemplateDialog={TemplateDialog}
            AlgorithmProcessingPage={ProcessingPage}
            handleAlgorithmEvaluate={handleEvaluate}
            handleAlgorithmReset={handleReset}
        />
    )
}