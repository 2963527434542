import React from 'react';
import {
    Box,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import ADILogo from "../../svgs/ADI-Logo-White.svg"

export default function Header() {
    return (
        <Paper
            square
            variant={"outlined"}
            sx={{
                borderWidth: '0px 0px 1px 0px',
                display: 'flex',
                py: 2,
                borderColor: 'rgba(255, 255, 255, 0.1)',
                backgroundColor: '#0067B9'
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{flexGrow: 1, pl: 3}}
            >
                <img src={ADILogo} alt={'analog devices'} height={40} width={100}/>
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: 0.7,
                        width: 2,
                        height: '100%',
                        ml: 1
                    }}
                />
                <Typography fontWeight={'bold'} sx={{fontSize: '20px'}} color={"white"}>
                    Algorithms Evaluation Platform
                </Typography>
            </Stack>
        </Paper>
    )
}