import React, {useRef} from "react";
import {Delete, UploadFile} from "@mui/icons-material";
import FormattedHelperText from "../FormattedHelperText";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {commonAlgoActions} from "../../store/CommonAlgorithm/slice";
import {ReactComponent as upload_icon} from "../../svgs/upload-icon.svg"
import {deleteEvaluationFile, uploadChunkFile} from "../../store/CommonAlgorithm/api";
import {Box, Button, IconButton, LinearProgress, Stack, SvgIcon, Typography} from "@mui/material";

export default function CSVFileUpload(props: any) {
    const {
        index,
        fileName,
        fileType,
        uploadTestid,
        uploadedTestid
    } = props
    const inputFile = useRef<any>(null);
    const evaluationID = useAppSelector((state: any) => state.commonAlgorithm.evaluationID);
    const [fileUploadPromise, setFileUploadPromise] = React.useState<any>(null);
    const inputFiles = useAppSelector((state: any) => state.commonAlgorithm.inputFiles);
    const referenceFiles = useAppSelector((state: any) => state.commonAlgorithm.referenceFiles);
    const fileState = fileType === "INPUT" ? inputFiles[index].state : referenceFiles[index].state;
    const fileProgress = fileType === "INPUT" ? inputFiles[index].progress : referenceFiles[index].progress;
    const dispatch = useAppDispatch();


    function fileUploadHandler(e: any) {
        const fileName = e.target.files[0].name;
        const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1);
        if (fileExtension.toLowerCase() === 'csv') {
            if (fileType === "INPUT") {
                dispatch(commonAlgoActions.setSelectedInputFile({index: index, data: e.target.value}));
                dispatch(commonAlgoActions.setInputFileProgress({index: index, data: 0}));
            } else {
                dispatch(commonAlgoActions.setSelectedReferenceFile({index: index, data: e.target.value}));
                dispatch(commonAlgoActions.setReferenceFileProgress({index: index, data: 0}));
            }
            const promise = dispatch(uploadChunkFile({file: e.target.files, type: fileType, index: index}));
            setFileUploadPromise(promise)
        } else {
            if (fileType === "INPUT") {
                dispatch(commonAlgoActions.setInputFileState({index: index, data: "Error"}))
            } else {
                dispatch(commonAlgoActions.setReferenceFileState({index: index, data: "Error"}))
            }
        }
    }

    function fileUploadCancelHandler() {
        if (fileUploadPromise) {
            fileUploadPromise.abort();
        }
        if (fileType === "INPUT") {
            dispatch(commonAlgoActions.setInputFileState({index: index, data: "Initial"}))
        } else {
            dispatch(commonAlgoActions.setReferenceFileState({index: index, data: "Initial"}))
        }
    }

    function setFileInitialState() {
        if (fileType === "INPUT") {
            dispatch(commonAlgoActions.setInputFileState({index: index, data: "Initial"}))
        } else {
            dispatch(commonAlgoActions.setReferenceFileState({index: index, data: "Initial"}))
        }
        dispatch(deleteEvaluationFile({type: fileType}))
    }


    function FileUploadWithErrorMessage() {
        return <React.Fragment>
            <Typography fontSize={12} color={"#c70f49"}>
                <FormattedHelperText text={'Failed to upload'}/>
            </Typography>
            <Typography fontSize={10} color={"#c70f49"} style={{marginTop: '0px'}}>
                The format file is not supported.
                <br/>
                Supported file format is: .csv
            </Typography>
            <Button
                variant="outlined"
                sx={{textTransform: 'none', fontSize: 13, width: '80%', py: 1, backgroundColor: '#fff'}}
                onClick={() => inputFile?.current?.click()}
                disabled={evaluationID === null}
            >
                <SvgIcon component={upload_icon}/>
                <input
                    data-testid={uploadTestid}
                    type='file'
                    hidden
                    ref={inputFile}
                    onChange={fileUploadHandler}
                />
                Re-upload File
            </Button>
        </React.Fragment>;
    }

    function FileUploaded() {
        return (
            <Stack
                data-testid={uploadedTestid}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{
                    width: '80%',
                    backgroundColor: '#EDF5F1',
                    px: 1,
                    pl: 2,
                    py: 1,
                    borderRadius: 1,
                    border: '1px solid #8ACCAF',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <Box sx={{backgroundColor: '#8ACCAF', p: 1, pb: 0.5, borderRadius: 100}}>
                        <UploadFile sx={{color: '#fff'}}/>
                    </Box>
                    <Typography fontSize={13}>{fileName}</Typography>
                </Stack>
                <IconButton onClick={setFileInitialState}>
                    <Delete fontSize={"small"}/>
                </IconButton>
            </Stack>
        )
    }


    function FileUpload() {
        return <React.Fragment>
            <Button
                variant="outlined"
                sx={{textTransform: 'none', fontSize: 13, width: '80%', py: 1, backgroundColor: '#fff'}}
                onClick={() => inputFile?.current?.click()}
                disabled={evaluationID === null}
            >
                <SvgIcon component={upload_icon}/>
                <input
                    data-testid={uploadTestid}
                    type='file'
                    hidden
                    ref={inputFile}
                    onChange={fileUploadHandler}
                />
                Upload File
            </Button>
            <Typography fontSize={8} sx={{mt: 2}}>
                <i>Supported Format: .csv</i>
                <br/>
                <i>Maximum Size: 50MB</i>
            </Typography>
        </React.Fragment>;
    }


    function FileLoading() {
        return <React.Fragment>
            <LinearProgress
                variant="determinate"
                value={fileProgress}
                sx={{width: '80%'}}
            />
            <Button
                variant="text"
                color={"error"}
                sx={{textTransform: 'none'}}
                onClick={fileUploadCancelHandler}
            >
                Cancel
            </Button>
        </React.Fragment>;
    }

    return (
        <>
            {fileState === "Initial" && <FileUpload/>}
            {fileState === "Uploading" && <FileLoading/>}
            {fileState === 'Error' && <FileUploadWithErrorMessage/>}
            {fileState === "Uploaded" && <FileUploaded/>}
        </>
    )
}