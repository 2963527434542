import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    Box,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import ADILogo from "../../assets/Images/Analog_Devices_Logo.svg";

export default function Header2() {

    return (
        <Paper
            square
            variant={"outlined"}
            sx={{
                borderWidth: '0px 0px 1px 0px',
                display: 'flex',
                py: 1,
                zIndex: 11,
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{flexGrow: 1, pl: 3, py: 1, textDecoration: 'none',}}
                component={RouterLink}
                to="/dashboard"
            >
                <img src={ADILogo} alt={'analog devices'} height={40} width={100}/>
                <Box
                    sx={{
                        backgroundColor: 'rgba(120, 120, 120, 1)',
                        borderRadius: 0.7,
                        width: 2,
                        height: '100%',
                        ml: 1
                    }}
                />
                <Typography
                    fontWeight={'bold'}
                    sx={{
                        fontSize: '20px',
                        textDecoration: 'none',
                        color: "#000",
                        textTransform: 'none',
                    }}

                >
                    Algorithms Evaluation Platform
                </Typography>
            </Stack>
        </Paper>
    )
}