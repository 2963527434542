import {Line} from "react-chartjs-2";
import React, {useEffect} from 'react';
import {LayoutPosition} from "chart.js";
import LoadingButton from "../../LoadingButton";
import {spo2AlgoActions} from "../../../store/SpO2Algorithm/slice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {startSpO2SyncEvaluation} from "../../../store/SpO2Algorithm/api";
import {Box, Paper, Slider, Stack, TextField, Typography} from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function SyncLineChart() {
    const syncLabel = useAppSelector((state: any) => state.spo2Algorithm.syncLabel);
    const referenceSpO2Data = useAppSelector((state: any) => state.spo2Algorithm.referenceSpO2Data);
    const algoSpO2Data = useAppSelector((state: any) => state.spo2Algorithm.algoSpO2Data);
    const [modifiedSpO2Data, setModifiedSpO2Data] = React.useState<any>();
    const timeDiffInSec = useAppSelector((state: any) => state.spo2Algorithm.timeDiffInSec);
    const RMSE = useAppSelector((state: any) => state.spo2Algorithm.RMSE);
    const MAE = useAppSelector((state: any) => state.spo2Algorithm.MAE);
    const startEvaluationSyncAPIStatus = useAppSelector((state: any) => state.spo2Algorithm.startEvaluationSyncAPIStatus);
    const referenceFiles = useAppSelector((state: any) => state.commonAlgorithm.referenceFiles);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const updatedTimeDiffInSec = timeDiffInSec * 25;
        if (updatedTimeDiffInSec > 0) {
            setModifiedSpO2Data(algoSpO2Data.slice(updatedTimeDiffInSec))
        } else if (updatedTimeDiffInSec === 0) {
            setModifiedSpO2Data(algoSpO2Data)
        } else {
            const tempDiff = updatedTimeDiffInSec * -1
            const zeros = Array(tempDiff).fill(null);
            const updatedArr = [...zeros, ...algoSpO2Data.slice(0, updatedTimeDiffInSec)];
            setModifiedSpO2Data(updatedArr)
        }
    }, [algoSpO2Data, timeDiffInSec])

    function handleTimeDiffChange(e: any) {
        const step = e.target.value;
        dispatch(spo2AlgoActions.setTimeDiffInSec(step))
    }

    function handleSync() {
        dispatch(startSpO2SyncEvaluation())
    }

    function handleSliderChange(_: any, newValue: any) {
        dispatch(spo2AlgoActions.setTimeDiffInSec(newValue as number))
    }

    const chartOptions = {
        responsive: true,
        spanGaps: true,
        elements: {
            line: {
                tension: 0
            },
            point: {
                radius: 1
            }
        },
        scale: {
            y: {
                min: 60,
                max: 120,
            },
        },
        scales: {
            x: {
                ticks: {
                    callback: (value: any, index: number, values: any) => {
                        if (index % 25 === 0) {
                            return value / 25
                        }
                    },
                },
            },
        },
        maintainAspectRatio: true,
        animation: false as any,
        hover: false as any,
        responsiveAnimationDuration: 0,
        plugins: {
            legend: {
                position: "top" as LayoutPosition,
            },
            title: {
                display: true,
                text: 'Synchronize with reference data',
            },
        }
    };

    const graphData = {
        labels: syncLabel,
        datasets: [
            {
                label: 'SpO2 data',
                data: modifiedSpO2Data,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                showLine: false
            },
            {
                label: 'Ref SpO2 data',
                data: referenceSpO2Data,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                showLine: false
            },
        ],
    }

    return (
        <Stack
            direction='column'
            sx={{
                backgroundColor: '#F1F3FA',
                borderRadius: 1,
                m: 2,
                px: 2,
                height: '88%',
            }}
        >
            <Paper
                variant={"outlined"}
                sx={{width: '150px', p: 1, px: 2, mb: 5, mt: 1}}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Box>
                        <Typography fontSize={18}>{RMSE}</Typography>
                        <Typography fontSize={12}>RMSE</Typography>
                    </Box>
                    <Box>
                        <Typography fontSize={18}>{MAE}</Typography>
                        <Typography fontSize={12}>MAE</Typography>
                    </Box>
                </Stack>
            </Paper>


            <Line
                options={chartOptions}
                data={graphData}
            />

            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                sx={{width: '100%', mt: 2}}
            >
                <Typography
                    fontSize={12}
                    sx={{mr: 2}}
                >
                    Move the slider to sync:
                </Typography>
                <Slider
                    track={false}
                    value={timeDiffInSec}
                    step={1}
                    min={-2000}
                    max={2000}
                    sx={{width: 150, mr: 2}}
                    valueLabelDisplay="auto"
                    onChangeCommitted={handleSliderChange}
                    disabled={referenceFiles.length > 0 && referenceFiles[0].state !== "Uploaded"}
                />
                <Typography
                    fontSize={12}
                    sx={{mr: 2}}
                >
                    or manually enter a value to sync:
                </Typography>
                <TextField
                    value={timeDiffInSec}
                    size={"small"}
                    type="number"
                    sx={{width: 100}}
                    onChange={handleTimeDiffChange}
                    disabled={referenceFiles.length > 0 && referenceFiles[0].state !== "Uploaded"}
                />
                <Box flex={1}></Box>
                <LoadingButton
                    sx={{px: 3, ml: 3, textTransform: 'none', fontSize: '14px', width: 125}}
                    size={"small"}
                    variant={'contained'}
                    onClick={handleSync}
                    status={startEvaluationSyncAPIStatus}
                >
                    {(referenceFiles.length > 0 && referenceFiles[0].state !== "Uploaded") ? "Generate" : "Sync"}
                </LoadingButton>
            </Stack>
            <Typography sx={{mt: 2}} fontStyle={'italic'} fontSize={14} color={'rgba(117, 122, 139, 1)'}>
                **This performance is applicable only for an algorithm running
                on the cloud and it is not necessary what you would get from a specific platform.
            </Typography>
        </Stack>
    )
}

export default SyncLineChart;