import React from 'react';
import App from './App';
import store from "./store";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Provider} from "react-redux";
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {SettingsProvider} from "./context/SettingsContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <BrowserRouter>
                    <SettingsProvider>
                        <App/>
                    </SettingsProvider>
                </BrowserRouter>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);
