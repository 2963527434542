import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import validator from "validator";
import toBoolean = validator.toBoolean;
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {authActions} from "../../store/Auth/slice";
import {Link as RouterLink} from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert";
import LoadingButton from "../../components/LoadingButton";
import SimpleTextField from "../../components/SimpleTextField";
import {useAppSelector, useAppDispatch} from '../../store/hooks'
import {loginRequest, userDetailRequest} from "../../store/Auth/api";
import {
    Divider,
    FormControl,
    Box,
    Link,
    Typography,
    Container,
    Stack,
    Paper
} from '@mui/material';
import PasswordTextField from "../../components/PasswordTextField";
import dashboard_illustration_top from "../../svgs/dashboard-illustration-top.png";
import dashboard_illustration_bottom from "../../svgs/dashboard-illustration-bottom.png";

function Login() {
    const email = useAppSelector((state: any) => state.auth.email);
    const password = useAppSelector((state: any) => state.auth.password);
    const authStatus = useAppSelector((state: any) => state.auth.status);
    const response = useAppSelector((state: any) => state.auth.response);
    const dispatch = useAppDispatch();
    const footerRef = useRef<HTMLDivElement>(null);
    const [bottom, setBottom] = useState(0)

    useEffect(() => {
        if (authStatus === "succeeded") {
            dispatch(userDetailRequest())
        }
    }, [dispatch, authStatus])


    useEffect(() => {
        if (footerRef.current) {
            const data = footerRef.current.getBoundingClientRect();
            setBottom(parseInt(data.top + ''))
        }
    }, [footerRef]);

    useEffect(() => {
        document.title = 'Login';
    }, []);

    function login() {
        dispatch(authActions.validateEmail())
        dispatch(authActions.validatePassword())
        if (email.isValid && password.isValid) {
            dispatch(loginRequest())
        }
    }

    function clearEmail() {
        dispatch(authActions.clearEmail())
    }

    // function clearPassword() {
    //     dispatch(authActions.clearPassword())
    // }

    function handleEmailChange(event: any) {
        dispatch(authActions.setEmail(event.target.value))
        dispatch(authActions.validateEmail())
    }

    function handlePasswordChange(event: any) {
        dispatch(authActions.setPassword(event.target.value))
        dispatch(authActions.validatePassword())
    }

    function resetStates() {
        dispatch(authActions.resetStates())
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#0067B9'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_top})`,
                    width: '185px',
                    height: '274px',
                    top: 80,
                    right: 0,
                    zIndex: 10,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_bottom})`,
                    backgroundRepeat: "no-repeat",
                    width: '279px',
                    height: '414px',
                    left: 0,
                    top: `${bottom - 340}px`,
                    zIndex: 10,
                }}
            />
            <Header/>
            <Container
                component="main"
                sx={{
                    width: 570,
                    marginTop: 8,
                    py: 2,
                    px: 2,
                    pb: 3,
                }}
            >
                <Paper sx={{py: 2, px: 2, pb: 3}}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{mt: 4, mb: 1, pl: 5}}
                    >
                        Log In
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {response.errorMessage &&
                            <ErrorAlert>
                                {response.errorMessage}
                            </ErrorAlert>
                        }
                        <br/>
                        <FormControl variant="standard">
                            Email Address:
                            <SimpleTextField
                                sx={{mb: 2, width: 400}}
                                error={toBoolean(email.errorMessage)}
                                helperText={email.errorMessage}
                                autoComplete="Email"
                                onChange={handleEmailChange}
                                onClear={clearEmail}
                                status={authStatus}
                                value={email.data}
                            />
                        </FormControl>
                        <FormControl>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{width: 400}}
                                alignItems="center"
                            >
                                Password:
                                <Link
                                    component={RouterLink}
                                    to="/forgot_password"
                                    onClick={resetStates}
                                    sx={{textDecoration: 'none', fontSize: '14px'}}
                                    fontWeight={"bold"}
                                >
                                    Forgot Password?
                                </Link>
                            </Stack>
                            <PasswordTextField
                                inputProps={{'data-testid': 'password-input'}}
                                sx={{mb: 3, width: 400}}
                                error={toBoolean(password.errorMessage)}
                                helperText={password.errorMessage}
                                autoComplete="current-password"
                                onChange={handlePasswordChange}
                                value={password.data}
                            />
                        </FormControl>
                        <LoadingButton
                            onClick={login}
                            fullWidth
                            status={authStatus}
                            variant="contained"
                            sx={{width: 400, height: 50}}
                        >
                            Log In
                        </LoadingButton>
                        <Divider sx={{width: '90%', mt: 3, mb: 2}}/>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            sx={{width: 400, mb: 2}}
                            alignItems="center"
                        >
                            <Link
                                component={RouterLink}
                                to="/request_account"
                                onClick={resetStates}
                                sx={{textDecoration: 'none', fontSize: '18px'}}
                                fontWeight={"bold"}
                            >
                                Request an Account
                            </Link>
                        </Stack>
                    </Box>
                </Paper>
            </Container>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                }}
                ref={footerRef}
            >
                <Footer sx={{mb: 1}}/>
            </Box>
        </Box>
    );
}

export default React.memo(Login);