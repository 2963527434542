import React from "react";
import {Stack, Typography} from "@mui/material";

export function TwoDataRow(props: any) {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={props.sx}
        >
            <Stack direction='column' sx={{width: '50%'}}>
                <Typography color='grey' variant='body1'>{props.leftChild.key}</Typography>
                <Typography color='black' variant='body1'>{props.leftChild.value}</Typography>
            </Stack>
            <Stack direction='column' sx={{width: '50%'}}>
                <Typography color='grey' variant='body1'>{props.rightChild.key}</Typography>
                <Typography color='black' variant='body1'>{props.rightChild.value}</Typography>
            </Stack>
        </Stack>
    )
}

export function OneDataRow(props: any) {
    return (
        <Stack direction='column' sx={props.sx}>
            <Typography color='grey' variant='body1'>{props.child.key}</Typography>
            <Typography color='black' variant='body1'>{props.child.value}</Typography>
        </Stack>
    )
}