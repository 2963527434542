import {RootState} from "../index";
import {authAxios, publicAxios} from '../axiosAPI';
import {createAsyncThunk} from '@reduxjs/toolkit'

export const loginRequest = createAsyncThunk(
    'users/login',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const auth = state.auth;
        const data = {email: auth.email.data, password: auth.password.data}
        const response = await publicAxios.post('/user/token/', data)
        return response.data
    }
)

export const userDetailRequest = createAsyncThunk(
    'users/me',
    async (_, thunkAPI) => {
        const response = await authAxios.get('/user/me/')
        return response.data
    }
)
