import React from 'react';
import {Close} from "@mui/icons-material";
import excelSample from "../../../assets/Images/ExcelSampleSpO2.png";
import referenceSample from "../../../assets/Images/spo2-reference-sample.png";
import {ReactComponent as file_template_icon} from "../../../svgs/file-template-icon.svg";
import {Button, Dialog, DialogContent, Divider, IconButton, Link, Stack, SvgIcon, Typography} from "@mui/material";

export default function TemplateDialog(props: any) {
    const fileTemplateDialog = props.fileTemplateDialog;
    const closeFileTemplateDialog = props.closeFileTemplateDialog;

    return (
        <Dialog
            open={fileTemplateDialog}
            onClose={closeFileTemplateDialog}
            fullWidth
            componentsProps={{backdrop: {style: {backgroundColor: "#003965", opacity: 0.6499999761581421}}}}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{py: 2, pl: 2}}
            >
                <SvgIcon component={file_template_icon} viewBox={""} sx={{height: '48px', width: '48px'}}/>
                <Typography sx={{ml: 10}} variant={"h5"}>
                    SpO2 File Template
                </Typography>
            </Stack>
            <IconButton
                aria-label="close"
                onClick={closeFileTemplateDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close/>
            </IconButton>
            <Divider/>
            <DialogContent>
                <Typography fontSize={14}>
                    In order to help you to have successful evaluation of your input for the algorithm, we
                    encourage
                    you to follow the template from below:
                </Typography>
                <Typography fontWeight={"bold"} sx={{mt: 1}}>Input file:</Typography>
                <img src={excelSample} alt={'Excel file Sample'} style={{margin: 8, width: 400}}/>
                <Typography fontSize={11}>
                    <b>Column A:</b> IR PPG (19-bit PPG in 32 uA dynamic range, gathered from IR LED ). Value Range
                    [0-524287]<br/>
                    <b>Column B:</b> RED PPG (19-bit PPG in 32 uA dynamic range, gathered from RED LED). Value Range
                    [0-524287]<br/>
                    <b>Column C:</b> Calibrated X axis acceleration in +/- 8G range. [milli-G]<br/>
                    <b>Column D:</b> Calibrated Y axis acceleration in +/- 8G range. [milli-G]<br/>
                    <b>Column E:</b> Calibrated Z axis acceleration in +/- 8G range. [milli-G]<br/>
                    <br/>
                    <b>Notes:</b> <br/>
                    <ul>
                        <li>Accelerometer and PPG signal sampling rate should be 25Hz, with sampling interval between
                            38ms and 43ms.
                        </li>
                        <li>Accel to PPG synchronization should be within 25ms, with reference to the sample
                            timestamps.
                        </li>
                        <li>All accel axis should be in sync.</li>
                        <li>There should be accelerometer and PPG data point drops for no more than one sample per
                            minute.
                        </li>
                    </ul>
                    <br/>
                </Typography>
                <Typography fontWeight={"bold"}>Reference File:</Typography>
                <img src={referenceSample} alt={'Excel file Sample'} style={{margin: 8, width: 110}}/>
                <Typography fontSize={11}>
                    <b>Column A:</b> Reference SpO2 values in 1-HZ collected from clinical pulse oximeter device such as
                    Masimo, Nellcor, etc.
                    <br/>
                    <br/>
                </Typography>
                <Typography fontSize={13}>
                    If you want to make sure all the data is right handled from your side and our system is
                    flawless
                    analysing, you can <Link target={"_blank"}
                                             href={"https://aep-public-data.s3.amazonaws.com/spo2/aep-template.zip"}
                                             style={{cursor: 'pointer'}}>download the .csv template</Link> and
                    start
                    adding there your data.
                </Typography>
            </DialogContent>
            <Divider/>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={4}
                sx={{pr: 3}}
            >
                {/*<Button*/}
                {/*    variant={'text'}*/}
                {/*    sx={{my: 2}}*/}
                {/*    onClick={closeFileTemplateDialog}*/}
                {/*>*/}
                {/*    Cancel*/}
                {/*</Button>*/}
                <Button
                    variant={'contained'}
                    sx={{my: 2, py: 1, px: 2}}
                    onClick={closeFileTemplateDialog}
                >
                    OK
                </Button>
            </Stack>
        </Dialog>
    )
}