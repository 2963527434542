import React from 'react';
import {
    Link,
    Stack,
    Typography,
} from "@mui/material";


export default function Footer2() {
    return (
        <React.Fragment>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{float: 'left'}}
            >
                <Typography
                    variant="caption"
                    color="rgba(57, 61, 79, 1)"
                    sx={{mx: 3}}
                >
                    @1995-2023 Analog Devices, Inc.
                </Typography>
                <Link
                    color="primary.dark"
                    href="https://www.analog.com/en/index.html"
                    sx={{textDecoration: 'none', p: 1.5, fontSize: '12px'}}
                >
                    Privacy & Security
                </Link>
                <Link
                    color="primary.dark"
                    href="https://www.analog.com/en/index.html"
                    sx={{textDecoration: 'none', p: 1.5, fontSize: '12px'}}
                >
                    Privacy Settings
                </Link>
                <Link
                    color="primary.dark"
                    href="https://www.analog.com/en/index.html"
                    sx={{textDecoration: 'none', p: 1.5, fontSize: '12px'}}
                >
                    Terms of Use
                </Link>
            </Stack>
            <Link
                color="primary.dark"
                href="https://www.analog.com/en/index.html"
                sx={{textDecoration: 'none', p: 1.5, mt: 1, float: 'right', mr: 2, fontSize: '12px'}}
            >
                Contact Us
            </Link>
        </React.Fragment>
    )
}