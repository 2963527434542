import {RootState} from "../index";
import {publicAxios} from '../axiosAPI';
import {createAsyncThunk} from '@reduxjs/toolkit'

export const forgotPasswordRequest = createAsyncThunk(
    'users/forgot password',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const forgotPassword = state.forgotPassword;
        const data = {email: forgotPassword.email.data}
        const response = await publicAxios.post('/user/forgot_password/', data)
        return response.data
    }
)
