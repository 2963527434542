import React from "react";
import {Stack, Typography} from "@mui/material";
import no_evaluation from "../../../svgs/no-evaluation.svg";

export default function InitialEvaluationPage() {
    return <Stack
        direction='column'
        alignItems='center'
        justifyContent="center"
        sx={{
            backgroundColor: '#F1F3FA',
            borderRadius: 1,
            m: 2,
            height: '88%',
        }}
    >
        <img src={no_evaluation} style={{marginBottom: "16px"}} alt={"NO Evaluation"}/>
        <Typography
            fontWeight={"bold"}
            align={"center"}
            sx={{mt: 1, fontSize: '14px'}}
        >
            No Evaluation Yet
        </Typography>
        <Typography align={"center"} fontSize={14}>
            Add the input files on the Data section, so that we can generate the results here.
        </Typography>
    </Stack>
}