import React, {useEffect} from 'react';
import useTitle from "../../hooks/useTitle";
import {useNavigate} from "react-router-dom";
import {authActions} from "../../store/Auth/slice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";


export default function Logout() {
    useTitle("Logout")
    const isLoggedIn = useAppSelector((state: any) => state.auth.isLoggedIn);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Logout';
    }, []);

    useEffect(() => {
        dispatch(authActions.logout())
        if (isLoggedIn === false)
            navigate('/login', {replace: true});
    }, [dispatch, isLoggedIn, navigate])

    return (<React.Fragment></React.Fragment>)
}
