import React from "react";
import {Close} from "@mui/icons-material";
import LoadingButton from "../LoadingButton";
import {adminActions} from "../../store/Admin/slice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {requestAccountByAdmin} from "../../store/RequestAccount/api";
import {requestAccountActions} from "../../store/RequestAccount/slice";
import RequestAccountFragment from "../Fragments/RequestAccountFragment";
import {
    Autocomplete, Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";

export default function CreateAccountDialog() {
    const [helperTextAlgo, setHelperTextAlgo] = React.useState({
        text: 'Please select atleast one algorithm',
        initialized: false
    });
    const algorithms = useAppSelector((state) => state.algorithm.algorithms);
    const createAccountDialog = useAppSelector((state) => state.admin.createAccountDialog);
    const companyName = useAppSelector((state: any) => state.requestAccount.companyName);
    const firstName = useAppSelector((state: any) => state.requestAccount.firstName);
    const lastName = useAppSelector((state: any) => state.requestAccount.lastName);
    const emailAddress = useAppSelector((state: any) => state.requestAccount.emailAddress);
    const algorithmRequest = useAppSelector((state: any) => state.requestAccount.algorithm);
    const phoneNumber = useAppSelector((state: any) => state.requestAccount.phoneNumber);
    const purpose = useAppSelector((state: any) => state.requestAccount.purpose);
    const requestUsers = useAppSelector((state: any) => state.requestAccount.requestUsers);
    const status = useAppSelector((state: any) => state.requestAccount.status);
    const dispatch = useAppDispatch();

    function handleRequestAccount() {
        if (!helperTextAlgo.initialized || helperTextAlgo.text !== '') {
            setHelperTextAlgo({...helperTextAlgo, initialized: true});
        }
        dispatch(requestAccountActions.validateCompanyName());
        dispatch(requestAccountActions.validateEmailAddress());
        dispatch(requestAccountActions.validateFirstName());
        dispatch(requestAccountActions.validateLastName());
        dispatch(requestAccountActions.validatePhoneNumber());
        dispatch(requestAccountActions.validatePurpose());
        dispatch(requestAccountActions.validateAlgorithm());
        requestUsers.map((user: any) => dispatch(requestAccountActions.validateUserEmail(user.id)));
        requestUsers.map((user: any) => dispatch(requestAccountActions.validateUserFirstName(user.id)));
        requestUsers.map((user: any) => dispatch(requestAccountActions.validateUserLastName(user.id)));
        const allUsersValid = requestUsers.every((user: any) => {
            return user.firstName.isValid && user.lastName.isValid && user.email.isValid
        });
        if (
            companyName.isValid &&
            emailAddress.isValid &&
            firstName.isValid &&
            lastName.isValid &&
            purpose.isValid &&
            algorithmRequest.isValid &&
            phoneNumber.isValid &&
            allUsersValid
        ) {
            dispatch(requestAccountByAdmin());
        }
    }

    function handleClose(_: any, reason: any = null) {
        if (reason && reason === "backdropClick")
            return;
        dispatch(adminActions.closeCreateAccountDialog());
        dispatch(requestAccountActions.resetPage());
        setHelperTextAlgo({...helperTextAlgo, text: ''});
    }

    function handleAlgorithmChange(value: any) {
        // console.log(value)
        setHelperTextAlgo({text: '', initialized: true});
        if (value.length === 0) {
            setHelperTextAlgo({...helperTextAlgo, text: 'Please select atleast one algorithm'});
        }
        dispatch(requestAccountActions.setAlgorithm(value));
        dispatch(requestAccountActions.validateAlgorithm());
    }

    const getFormattedHelperText = (text: any) => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
                component="span"
                color="#c70f49"
                sx={{ml: -1.5}}
            >
                <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
                {text}
            </Stack>
        )
    }

    return (
        <Dialog
            open={createAccountDialog}
            onClose={handleClose}
            fullWidth
            componentsProps={{backdrop: {style: {backgroundColor: "#003965", opacity: 0.6499999761581421}}}}
        >
            <DialogTitle variant={"h5"}>
                Request Account
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <RequestAccountFragment/>
                <Typography
                    sx={{pl: 1, pr: 1, my: 1}}
                    fontWeight={"bold"}
                >
                    Setup Algorithms for Request
                </Typography>
                <Typography
                    color="grey"
                    sx={{pl: 3, pr: 3, my: 1}}
                >
                    Algorithms
                </Typography>
                <Autocomplete
                    multiple
                    options={algorithms}
                    sx={{pl: 3, pr: 3, mt: 1, mb: 3}}
                    onChange={(_, value) => {
                        handleAlgorithmChange(value)
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.name}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox sx={{mr: 1}} checked={selected}/>
                            {option.name}
                        </li>
                    )}
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Choose Algorithms"
                            error={helperTextAlgo.initialized && helperTextAlgo.text.length > 0}
                            helperText={helperTextAlgo.initialized && helperTextAlgo.text.length > 0 && getFormattedHelperText(helperTextAlgo.text)}
                        />
                    )}
                />
                <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    sx={{mb: 2}}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{width: "200px"}}
                        onClick={handleRequestAccount}
                        status={status}
                    >
                        Create Account
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
