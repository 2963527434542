import * as _ from 'lodash'
import {createSlice} from "@reduxjs/toolkit";
import {
    approveRequestAccess,
    archiveAccountList, archiveAccountRequest,
    extendDateRequest, nextArchiveAccountList,
    nextRequestAccountListRequest,
    nextUserListRequest, previousArchiveAccountList,
    previousRequestAccountListRequest,
    previousUserListRequest, rejectRequestAccess,
    requestAccountListRequest,
    userListRequest
} from "./api";
import {
    clearInput,
    InputInterface,
    setInput,
    validateInput
} from "../validation";
import {requestAccountByAdmin} from "../RequestAccount/api";

const adminSlice = createSlice({
    name: "adminSlice",
    initialState: {
        requestAccessAlgorithms: [],
        extendedExpiryDate: {data: '', errorMessage: '', isValid: false} as InputInterface,
        createAccountDialog: false,
        accountPermissionDialog: false,
        extendAccountDialog: false,
        currentSelectedAccountRequest: null as any,
        currentSelectedUser: null as any,
        notifications: [
            {
                id: 123,
                title: 'Account ID #4',
                reason: 'Not Failure',
                description: 'The system is not failing soon',
                createdOn: "01/04/2023 02:56:32",
                category: '3',
                read: false
            },
            {
                id: 122,
                title: 'Account ID #3',
                reason: 'Failure',
                description: 'The system is failing soon',
                createdOn: "04/03/2023 02:56:32",
                category: '3',
                read: false
            },
            {
                id: 121,
                title: 'Account ID #2',
                reason: 'Not Expire',
                description: 'The account is not expiring soon',
                createdOn: "02/03/2023 02:56:32",
                category: '2',
                read: false
            },
            {
                id: 120,
                title: 'Account ID #1',
                reason: 'Expire',
                description: 'The account is expiring soon',
                createdOn: "01/04/2023 23:41:20" as string,
                category: '2',
                read: false
            },
        ],
        accountRequests: [] as any,
        user: [] as any,
        archived: [] as any,
        currentTab: "1" as "1" | "2" | "3",
        filterName: "" as any,
        accountRequestsListSize: 5 as number,
        totalAccountRequests: 0 as any,
        accountRequestPageNo: 1 as number,
        accountRequestsLeftArrowLink: null as any,
        accountRequestsRightArrowLink: null as any,
        userListSize: 5 as number,
        totalUsers: 0 as any,
        userPageNo: 1 as number,
        userLeftArrowLink: null as any,
        userRightArrowLink: null as any,
        archivedListSize: 5 as number,
        totalArchived: 0 as any,
        archivePageNo: 1 as number,
        archiveLeftArrowLink: null as any,
        archiveRightArrowLink: null as any,
        status: "idle" as "idle" | "pending" | "succeeded" | "failed",
        approveAccountStatus: "idle" as "idle" | "pending" | "succeeded" | "failed",
        rejectAccountStatus: "idle" as "idle" | "pending" | "succeeded" | "failed",
        extendAccountStatus: "idle" as "idle" | "pending" | "succeeded" | "failed",
        refreshData: false as boolean,
    },
    reducers: {
        setRefreshData(state, action) {
            state.refreshData = action.payload
        },
        setFilterName(state, action) {
            state.filterName = action.payload
            state.userPageNo = 1
            state.archivePageNo = 1
            state.accountRequestPageNo = 1
        },
        setTab(state, action) {
            state.currentTab = action.payload;
            state.filterName = ""
        },
        openCreateAccountDialog(state) {
            state.createAccountDialog = true;
        },
        openAccountPermissionDialog(state, action) {
            state.accountPermissionDialog = true;
            state.currentSelectedAccountRequest = action.payload;
            state.requestAccessAlgorithms = []
        },
        openExtendAccountDialog(state, action) {
            state.extendAccountDialog = true;
            state.currentSelectedUser = action.payload;
            clearInput(state, "extendedExpiryDate")
        },
        closeCreateAccountDialog(state) {
            state.createAccountDialog = false;
        },
        closeAccountPermissionDialog(state) {
            state.accountPermissionDialog = false;
        },
        closeExtendAccountDialog(state) {
            state.extendAccountDialog = false;
        },
        setExtendedExpiryDate(state, action) {
            setInput(state, "extendedExpiryDate", action.payload)
        },
        validateExtendedExpiryDate(state) {
            validateInput(state, "extendedExpiryDate", "Please enter expiry date.")
        },
        setReason(state, action) {
            state.currentSelectedAccountRequest.reason = action.payload;
        },
        setRequestAccessAlgorithms(state, action) {
            state.requestAccessAlgorithms = action.payload;
        },
        setApprovalStatus(state, action) {
            _.set(_.find(
                state.currentSelectedAccountRequest.request_access_set,
                {id: action.payload.requestAccess.id}
            ), "status", action.payload.status)
        },
        setAccountRequestsListSize(state, action) {
            state.accountRequestsListSize = action.payload;
            state.refreshData = true
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        },
        setUserListSize(state, action) {
            state.userListSize = action.payload;
            state.refreshData = true
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        },
        setArchivedListSize(state, action) {
            state.archivedListSize = action.payload;
            state.refreshData = true
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userListRequest.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(userListRequest.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.user = action.payload.results
            state.totalUsers = action.payload.count
            state.userLeftArrowLink = action.payload.previous
            state.userRightArrowLink = action.payload.next
        })
        builder.addCase(userListRequest.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(previousUserListRequest.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(previousUserListRequest.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.user = action.payload.results
            state.totalUsers = action.payload.count
            state.userLeftArrowLink = action.payload.previous
            state.userRightArrowLink = action.payload.next
            state.userPageNo -= 1
        })
        builder.addCase(previousUserListRequest.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(nextUserListRequest.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(nextUserListRequest.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.user = action.payload.results
            state.totalUsers = action.payload.count
            state.userLeftArrowLink = action.payload.previous
            state.userRightArrowLink = action.payload.next
            state.userPageNo += 1
        })
        builder.addCase(nextUserListRequest.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(requestAccountListRequest.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(requestAccountListRequest.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.accountRequests = action.payload.results
            state.totalAccountRequests = action.payload.count
            state.accountRequestsLeftArrowLink = action.payload.previous
            state.accountRequestsRightArrowLink = action.payload.next
        })
        builder.addCase(requestAccountListRequest.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(previousRequestAccountListRequest.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(previousRequestAccountListRequest.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.accountRequests = action.payload.results
            state.totalAccountRequests = action.payload.count
            state.accountRequestsLeftArrowLink = action.payload.previous
            state.accountRequestsRightArrowLink = action.payload.next
            state.accountRequestPageNo -= 1
        })
        builder.addCase(previousRequestAccountListRequest.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(nextRequestAccountListRequest.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(nextRequestAccountListRequest.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.accountRequests = action.payload.results
            state.totalAccountRequests = action.payload.count
            state.accountRequestsLeftArrowLink = action.payload.previous
            state.accountRequestsRightArrowLink = action.payload.next
            state.accountRequestPageNo += 1
        })
        builder.addCase(nextRequestAccountListRequest.rejected, (state) => {
            state.status = "failed"
        })
        builder.addCase(archiveAccountList.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(archiveAccountList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.archived = action.payload.results
            state.totalArchived = action.payload.count
            state.archiveLeftArrowLink = action.payload.previous
            state.archiveRightArrowLink = action.payload.next
        })
        builder.addCase(archiveAccountList.rejected, (state) => {
            state.status = 'failed'
        })
        builder.addCase(previousArchiveAccountList.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(previousArchiveAccountList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.archived = action.payload.results
            state.totalArchived = action.payload.count
            state.archiveLeftArrowLink = action.payload.previous
            state.archiveRightArrowLink = action.payload.next
            state.archivePageNo -= 1
        })
        builder.addCase(previousArchiveAccountList.rejected, (state) => {
            state.status = 'failed'
        })
        builder.addCase(nextArchiveAccountList.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(nextArchiveAccountList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.archived = action.payload.results
            state.totalArchived = action.payload.count
            state.archiveLeftArrowLink = action.payload.previous
            state.archiveRightArrowLink = action.payload.next
            state.archivePageNo += 1
        })
        builder.addCase(nextArchiveAccountList.rejected, (state) => {
            state.status = 'failed'
        })

        builder.addCase(extendDateRequest.pending, (state) => {
            state.status = 'pending'
            state.extendAccountStatus = "pending"
        });
        builder.addCase(extendDateRequest.fulfilled, (state) => {
            state.status = 'succeeded'
            state.extendAccountDialog = false;
            state.extendAccountStatus = "succeeded"
            state.refreshData = true;
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        });
        builder.addCase(extendDateRequest.rejected, (state) => {
            state.status = 'failed'
            state.extendAccountStatus = "failed"
            state.extendedExpiryDate['isValid'] = false
            state.extendedExpiryDate['errorMessage'] = "Please select a valid date."
        });

        builder.addCase(requestAccountByAdmin.pending, (state) => {
            state.status = 'pending'
            state.createAccountDialog = false;
        })
        builder.addCase(requestAccountByAdmin.fulfilled, (state) => {
            state.status = 'succeeded'
            state.createAccountDialog = false;
            state.refreshData = true;
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        })
        builder.addCase(requestAccountByAdmin.rejected, (state) => {
            state.status = 'failed'
            state.createAccountDialog = false;
        })

        builder.addCase(archiveAccountRequest.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(archiveAccountRequest.fulfilled, (state) => {
            state.status = 'succeeded'
            state.refreshData = true;
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        })
        builder.addCase(archiveAccountRequest.rejected, (state) => {
            state.status = 'failed'
        })

        builder.addCase(approveRequestAccess.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(approveRequestAccess.fulfilled, (state) => {
            state.status = 'succeeded'
            state.refreshData = true;
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        })
        builder.addCase(approveRequestAccess.rejected, (state) => {
            state.status = 'failed'
        })


        builder.addCase(rejectRequestAccess.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(rejectRequestAccess.fulfilled, (state) => {
            state.status = 'succeeded'
            state.refreshData = true;
            state.accountRequestPageNo = 1
            state.userPageNo = 1
            state.archivePageNo = 1
        })
        builder.addCase(rejectRequestAccess.rejected, (state) => {
            state.status = 'failed'
        })


    },
});

export const adminActions = adminSlice.actions;
export default adminSlice;
