import React from "react";
import {Button, Stack, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import EvaluationEvent from "../../store/CommonAlgorithm/evaluationEvent";
import {AccessTime, Cancel, CheckCircle, Loop} from "@mui/icons-material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {commonAlgoActions} from "../../store/CommonAlgorithm/slice";


function EvaluationTimeline() {
    const evaluationTimelineData = useAppSelector((state: any) => state.commonAlgorithm.evaluationTimelineData);
    const showEvaluationResult = useAppSelector((state: any) => state.commonAlgorithm.enableShowEvaluationResultButton);
    const [evaluating, setEvaluating] = React.useState(true);
    const dispatch = useAppDispatch();

    function timelineDot(status: string) {
        if (status === EvaluationEvent.IN_PROGRESS) {
            return (
                <TimelineDot color="primary">
                    <Loop
                        sx={{
                            animation: "spin 2s linear infinite",
                            "@keyframes spin": {
                                "0%": {
                                    transform: "rotate(360deg)",
                                },
                                "100%": {
                                    transform: "rotate(0deg)",
                                },
                            },
                        }}
                    />
                </TimelineDot>
            )
        } else if (status === EvaluationEvent.COMPLETED) {
            return (
                <TimelineDot color="success">
                    <CheckCircle/>
                </TimelineDot>
            )
        } else if (status === EvaluationEvent.FAILED) {
            return (
                <TimelineDot color="error">
                    <Cancel/>
                </TimelineDot>
            )
        } else {
            return (
                <TimelineDot>
                    <AccessTime/>
                </TimelineDot>
            )
        }
    }

    function setCompletedStateHandle() {
        dispatch(commonAlgoActions.setEvaluationCompletedState())
    }

    React.useEffect(() => {
        if (showEvaluationResult) setEvaluating(false);
    }, [showEvaluationResult])

    return (
        <Stack
            direction='column'
            alignItems='center'
            justifyContent="center"
            sx={{
                backgroundColor: '#F1F3FA',
                borderRadius: 1,
                mt: 2,
                px: 5,
                height: '90%',
                mr: 1
            }}
        >
            <Typography
                fontWeight={"bold"}
                align={"center"}
                sx={{mt: 5, mb: 2}}
            >
                {evaluating ? 'Evaluating...' : 'Evaluated'}
            </Typography>

            <Timeline sx={{width: '100%'}}>
                {evaluationTimelineData.map((item: any, index: number) => {
                    return (
                        <TimelineItem key={index} data-testid="timeline-item">
                            <TimelineOppositeContent
                                sx={{pt: 3, px: 2,}}
                            >
                                {item.name}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector/>
                                {timelineDot(item.status)}
                                <TimelineConnector/>
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{m: 'auto 0'}}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                <Typography>
                                    {item.time}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{mt: 5}}
                >
                    <Button
                        variant={"outlined"}
                        sx={{textTransform: 'none'}}
                        disabled={!showEvaluationResult}
                        onClick={setCompletedStateHandle}
                    >
                        Show Result
                    </Button>
                </Stack>
            </Timeline>
        </Stack>
    )
}

export default React.memo(EvaluationTimeline);
