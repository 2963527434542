import React, {useEffect} from "react";
import {Close} from "@mui/icons-material";
import {TwoDataRow, OneDataRow} from "../DataRow";
import {adminActions} from "../../store/Admin/slice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    approveRequestAccess,
    rejectRequestAccess,
} from "../../store/Admin/api";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";


export default function AccountPermissionDialog() {
    const [helperTextReasonBox, setHelperTextReasonBox] = React.useState('');
    const [helperTextAlgo, setHelperTextAlgo] = React.useState('');
    const [helperTextUser, setHelperTextUser] = React.useState('');
    const algorithms = useAppSelector((state: any) => state.algorithm.algorithms);
    const [requestAccessAlgorithms, setRequestAccessAlgorithms] = React.useState<any>([]);
    const currentSelectedAccountRequest = useAppSelector((state: any) => state.admin.currentSelectedAccountRequest);
    const [decision, setDecision] = React.useState("na");
    const accountPermissionDialog = useAppSelector((state: any) => state.admin.accountPermissionDialog);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (decision === "rejected") {
            if (currentSelectedAccountRequest.request_access_set.length !== 1) {
                for (let i = 0; i < currentSelectedAccountRequest.request_access_set.length; i++) {
                    dispatch(adminActions.setApprovalStatus({
                        status: true,
                        requestAccess: currentSelectedAccountRequest.request_access_set[i]
                    }));
                }
            }

        }
    }, [decision])

    function handleClose(_: any, reason: any = null) {
        if (reason && reason === "backdropClick") return;
        dispatch(adminActions.closeAccountPermissionDialog());
        setDecision("na");
        setHelperTextReasonBox('')
        setHelperTextAlgo('')
        setRequestAccessAlgorithms([])
        setHelperTextUser("")
    }

    function handleCancel() {
        setHelperTextReasonBox('');
        setDecision("na");
    }

    function handleAccountAccessCheckboxChange(e: any, row: any) {
        dispatch(
            adminActions.setApprovalStatus({
                status: e.target.checked,
                requestAccess: row,
            })
        );
    }

    function handleReasonChange(event: any) {
        dispatch(adminActions.setReason(event.target.value));
        if (decision === 'rejected' && event.target.value.length === 0) {
            setHelperTextReasonBox('Please enter reason of rejection');
        } else if (decision === 'rejected') {
            setHelperTextReasonBox('');
        } else {
            setHelperTextReasonBox('');
        }
    }

    function handleRequestAccessAction() {
        let flagCheck = true;
        if (currentSelectedAccountRequest.request_access_set.length !== 1) {
            flagCheck = false;
            for (let i = 0; i < currentSelectedAccountRequest.request_access_set.length; i++) {
                if (currentSelectedAccountRequest.request_access_set[i].status === true) {
                    flagCheck = true
                    break
                }
            }
        }
        if (!flagCheck) {
            setHelperTextUser('Please select at least one user.');
            return
        } else {
            setHelperTextUser('')
        }

        if (decision === "approved") {
            if (requestAccessAlgorithms.length === 0) {
                setHelperTextAlgo('Please assign at least one algorithm');
            } else {
                setHelperTextAlgo('')
                dispatch(approveRequestAccess());
                handleClose(null, null)
            }
        } else if (decision === "rejected") {
            if (currentSelectedAccountRequest.request_access_set.length !== 1) {
                for (let i = 0; i < currentSelectedAccountRequest.request_access_set.length; i++) {
                    dispatch(adminActions.setApprovalStatus({
                        status: true,
                        requestAccess: currentSelectedAccountRequest.request_access_set[i]
                    }));
                }
            }

            if (!currentSelectedAccountRequest.reason || currentSelectedAccountRequest.reason === "") {
                setHelperTextReasonBox('Please enter reason of rejection');
            } else {
                setHelperTextReasonBox('');
                dispatch(rejectRequestAccess());
                handleClose(null, null)
            }
        }
    }

    function handleAlgorithmChange(_: any, newValue: any) {
        dispatch(adminActions.setRequestAccessAlgorithms(newValue));
        setRequestAccessAlgorithms(newValue);
    }

    const getFormattedHelperText = (text: any) => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
                component="span"
                color="#c70f49"
                sx={{ml: -1.5}}
            >
                <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
                {text}
            </Stack>
        )
    }

    function renderRequestersDetails() {
        if (currentSelectedAccountRequest.request_access_set.length === 1) {
            return currentSelectedAccountRequest.request_access_set.map(
                (row: any, index: number) => (
                    <div key={index}>
                        <TwoDataRow
                            leftChild={{key: "First Name:", value: row.first_name}}
                            rightChild={{key: "Last Name:", value: row.last_name}}
                        />
                        <OneDataRow
                            child={{key: "Email Address:", value: row.email}}
                            sx={{mt: 2}}
                        />
                    </div>
                )
            );
        }
        return currentSelectedAccountRequest.request_access_set.map(
            (row: any, index: number) => {
                return (
                    <Paper sx={{width: "100%", my: 1, p: 2}} variant="outlined">
                        <Stack
                            direction="row"
                            key={index}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Checkbox
                                defaultChecked={false}
                                disableRipple={true}
                                onChange={(e) => handleAccountAccessCheckboxChange(e, row)}
                            />
                            <Box sx={{width: '100%', pl: 1}}>
                                <TwoDataRow
                                    leftChild={{key: "First Name:", value: row.first_name}}
                                    rightChild={{key: "Last Name:", value: row.last_name}}
                                    sx={{mt: 1}}
                                />
                                <Divider sx={{mt: 2, width: '90%'}}/>
                                <OneDataRow
                                    child={{key: "Email Address:", value: row.email}}
                                    sx={{mt: 2}}
                                />
                            </Box>
                        </Stack>
                    </Paper>
                );
            }
        );
    }

    return (
        <Dialog
            open={accountPermissionDialog}
            onClose={handleClose}
            fullWidth
            componentsProps={{backdrop: {style: {backgroundColor: "#003965", opacity: 0.6499999761581421}}}}

        >
            <DialogTitle variant={"h5"}>
                Account Request
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <OneDataRow
                    child={{
                        key: "Company Name:",
                        value: currentSelectedAccountRequest.company_name,
                    }}
                    sx={{mb: 2}}
                />
                <b>Requesters Details</b>
                <TwoDataRow
                    leftChild={{
                        key: "First Name:",
                        value: currentSelectedAccountRequest.first_name,
                    }}
                    rightChild={{
                        key: "Last Name:",
                        value: currentSelectedAccountRequest.last_name,
                    }}
                />
                <TwoDataRow
                    leftChild={{
                        key: "Phone Number:",
                        value: currentSelectedAccountRequest.phone,
                    }}
                    rightChild={{
                        key: "Email Address:",
                        value: currentSelectedAccountRequest.email,
                    }}
                    sx={{mt: 2}}
                />
                <OneDataRow
                    child={{
                        key: "Purpose:",
                        value: currentSelectedAccountRequest.purpose,
                    }}
                    sx={{mt: 2}}
                />
                <Divider sx={{width: "100%", my: 1.5}}/>
                <Typography fontWeight={"bold"}>Request Access for</Typography>
                {renderRequestersDetails()}
                <Typography color={'red'} fontSize={12}>{helperTextUser}</Typography>
                <Divider sx={{width: "100%", my: 1.5}}/>
                {decision === "rejected" && (
                    <React.Fragment>
                        <Typography fontWeight={"bold"}>Status</Typography>
                        <Typography sx={{mb: 1}}>Rejected</Typography>
                    </React.Fragment>
                )}
                {decision === "approved" && (
                    <React.Fragment>
                        <Typography fontWeight={"bold"}>
                            Reason for Approval (Optional)
                        </Typography>
                        <Typography>Reason:</Typography>
                    </React.Fragment>
                )}
                {decision === "rejected" && (
                    <React.Fragment>
                        <Typography fontWeight={"bold"}>Reason for Rejection</Typography>
                        <Typography>Reason:</Typography>
                    </React.Fragment>
                )}
                {decision !== "na" && (
                    <TextField
                        sx={{width: "100%", borderColor: "white", my: 2}}
                        multiline
                        rows={2}
                        onChange={handleReasonChange}
                        error={helperTextReasonBox.length > 0}
                        helperText={helperTextReasonBox.length > 0 && getFormattedHelperText(helperTextReasonBox)}
                        value={currentSelectedAccountRequest.reason || ""}
                    />
                )}
                {decision === "approved" && (
                    <React.Fragment>
                        <Typography sx={{mb: 1}} fontWeight={"bold"}>
                            Setup Algorithms for Request
                        </Typography>
                        <Typography color="grey">Algorithms</Typography>
                        <Autocomplete
                            multiple
                            options={algorithms}
                            sx={{mb: 2}}
                            onChange={handleAlgorithmChange}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox sx={{mr: 1}} checked={selected}/>
                                    {option.name}
                                </li>
                            )}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Choose Algorithms"
                                    error={requestAccessAlgorithms.length <= 0 && helperTextAlgo.length > 0}
                                    helperText={requestAccessAlgorithms.length <= 0 && helperTextAlgo.length > 0 && getFormattedHelperText(helperTextAlgo)}
                                />
                            )}/>
                    </React.Fragment>
                )}
                {decision !== "na" && (
                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{my: 2}}
                    >
                        <Button variant="text" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{width: "170px"}}
                            onClick={handleRequestAccessAction}
                        >
                            Complete
                        </Button>
                    </Stack>
                )}
                {decision === "na" && (
                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{my: 2}}
                    >
                        <Button variant="text" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{width: "170px"}}
                            color={"secondary"}
                            onClick={() => setDecision("rejected")}
                        >
                            Reject
                        </Button>
                        <Button
                            variant="contained"
                            sx={{width: "170px"}}
                            onClick={() => setDecision("approved")}
                        >
                            Approve
                        </Button>
                    </Stack>
                )}
            </DialogContent>
        </Dialog>
    );
}
