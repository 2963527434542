import React from "react";
import LoadingButton from "../../LoadingButton";
import {authAxios} from "../../../store/axiosAPI";
import {useAppSelector} from "../../../store/hooks";
import {Box, Divider, Link, Paper, Stack, SvgIcon, Typography} from "@mui/material";
import {ReactComponent as download_icon} from "../../../svgs/download-icon.svg";
import {Link as RouterLink} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function AlgorithmSubHeader(props: any) {
    const {algorithmTitle} = props
    const evaluationID = useAppSelector((state: any) => state.commonAlgorithm.evaluationID);
    const algorithmState = useAppSelector((state: any) => state.commonAlgorithm.algorithmState);
    const algorithmEvaluationState = useAppSelector((state: any) => state.commonAlgorithm.algorithmEvaluationState);

    async function handleDownloadReport() {
        const response = await authAxios(
            "/files/evaluation_files/" + evaluationID + "/OUTPUT/Data.zip",
            {responseType: 'arraybuffer'}
        )
        const blob = new Blob([response.data], {type: 'application/zip'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url as any;
        link.target = "_blank";
        link.setAttribute('download', 'Data.zip');
        link.click();
    }

    return (
        <Paper sx={{overflow: 'auto', p: 1, py: 2, borderRight: 0, borderLeft: 0}} variant={"outlined"} square>
            <Stack
                direction={"row"}
                sx={{float: 'left'}}
            >
                <Link
                    component={RouterLink}
                    to="/dashboard"
                    sx={{
                        pl: 2,
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                    }}
                >
                    <ArrowBackIcon color={"primary"} sx={{mr: 1}}/>
                    <b>Back</b>
                </Link>
                <Divider orientation="vertical" sx={{py: 2, pl: 2}}/>
                <Typography
                    sx={{ml: 2}}
                    fontSize={18}
                >
                    {algorithmTitle}
                </Typography>
            </Stack>
            <LoadingButton
                variant='contained'
                sx={{float: 'right', textTransform: 'none', px: 2, py: 1, fontSize: '14px', mr: 2}}
                size={"small"}
                disabled={algorithmEvaluationState !== "Completed" || algorithmState !== "PostProcessing"}
                onClick={handleDownloadReport}
            >
                <SvgIcon component={download_icon}/>
                Download Report
            </LoadingButton>
        </Paper>
    )
}