import React from "react";
import SyncLineChart from "./SyncLineChart";
import PerformanceCharts from "./PerformanceCharts";
import SpectrogramCharts from "./SpectrogramCharts";
import EvaluationTimeline from "../../EvaluationTimeline";
import InitialEvaluationPage from "../InitialEvaluationPage";
import AlgorithmProcessingPage3Tabs from "../AlgorithmProcessingPage3Tabs";

export default function ProcessingPage(props: any) {
    const {
        showInstruction,
        setShowInstructions
    } = props;

    return (
        <AlgorithmProcessingPage3Tabs
            InitialEvaluationPage={InitialEvaluationPage}
            EvaluationTimeline={EvaluationTimeline}
            SyncLineChart={SyncLineChart}
            PerformanceCharts={PerformanceCharts}
            SpectrogramCharts={SpectrogramCharts}
            showInstruction={showInstruction}
            setShowInstructions={setShowInstructions}
        />
    );
}