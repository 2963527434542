import * as React from "react";
import {Stack, TextField} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";

export default function SimpleTextField(props: any) {
    const {onClear, status, helperText, ...rest} = props

    function errorMessage(helperText: any) {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                component="span"
                spacing={4}
                color="#c70f49"
                sx={{ml: -1.5}}
            >
                <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
                {helperText}
            </Stack>
        )
    }

    return (
        <TextField
            disabled={status === 'pending'}
            fullWidth
            helperText={helperText && helperText.length !== 0 ? errorMessage(helperText) : ""}
            InputProps={{
                endAdornment: (
                    <ClearIcon
                        onClick={props.onClear}
                        sx={{
                            visibility: props.value.length > 0 ? 'visible' : 'hidden',
                            color: '#757A8B',
                            cursor: 'pointer'
                        }}
                    >
                    </ClearIcon>
                )
            }}
            {...rest}
        />
    );
}