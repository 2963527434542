import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
// import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
// import SmsFailedRoundedIcon from '@mui/icons-material/SmsFailedRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
// import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import {
    Avatar,
    Box,
    Grid,
    Link,
    Paper,
    Popover,
    Stack,
    Tab,
    Typography
} from "@mui/material";
import {
    TabContext,
    TabList
} from "@mui/lab";
import {ReactComponent as NotificationsDefault} from '../../svgs/Notifications default.svg'
// import {ReactComponent as NotificationsWithAlerts} from '../../svgs/Notifications with alerts.svg'
// import {ReactComponent as NotificationsWithHoverAndAlerts} from '../../svgs/Notifications with alerts-hover state.svg'
import {ReactComponent as HeadphonesDefault} from '../../svgs/Headphones-default.svg'
import {ReactComponent as Logout} from '../../svgs/sign out-1.svg'
import {useAppSelector} from "../../store/hooks";
import {deepPurple} from "@mui/material/colors";
import ADILogo from "../../assets/Images/Analog_Devices_Logo.svg";

export default function Header2() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    // const [hoverBell, setHoverBell] = React.useState(false);
    // const [hoverHelp, setHoverHelp] = React.useState(false);
    // const [hoverProfile, setHoverProfile] = React.useState(false);
    const notifications = useAppSelector((state: any) => state.admin.notifications)
    const user = useAppSelector((state: any) => state.auth.user)
    const [value, setValue] = React.useState('1');

    // function getNotification(ms: number) {
    //     let seconds = ms / 1000;
    //     let minutes = seconds / 60;
    //     let hours = minutes / 60;
    //     let days = hours / 24;
    //     let months = days / 30;
    //     let years = months / 12;
    //     let onlySeconds = seconds % 60;
    //     let onlyMinutes = minutes % 60;
    //     let onlyHours = hours % 24;
    //     let onlyDays = days % 7;
    //     let onlyWeeks = (days % 30) / 7;
    //     let onlyMonths = months % 12;
    //     let onlyYears = years % 10;
    //     if (onlyYears >= 1) return (Number(onlyYears).toFixed(0) + ' years ago')
    //     if (onlyMonths >= 1) return (Number(onlyMonths).toFixed(0) + ' months ago')
    //     if (onlyWeeks >= 1) return (Number(onlyWeeks).toFixed(0) + ' week ago')
    //     if (onlyDays >= 1) return (Number(onlyDays).toFixed(0) + ' day ago')
    //     if (onlyHours >= 1) return (Number(onlyHours).toFixed(0) + ' hour ago')
    //     if (onlyMinutes >= 1) return (Number(onlyMinutes).toFixed(0) + ' min ago')
    //     if (onlySeconds >= 1) return (Number(onlySeconds).toFixed(0) + ' sec ago')
    // }

    // function handleNotificationClick(event: any) {
    //     if (anchorEl === null) setAnchorEl(event.target)
    //     else setAnchorEl(null)
    // }

    // function handleNotificationClose(event: any) {
    //     setAnchorEl(null)
    // }

    // function handleTabChange(event: any, newValue: string) {
    //     setValue(newValue);
    // }

    function onHoverBell() {
        // setHoverBell(true)
    }

    function offHoverBell() {
        // setHoverBell(false);
    }

    // function onHoverHelp() {
    //     // setHoverHelp(true);
    // }
    //
    // function offHoverHelp() {
    //     // setHoverHelp(false);
    // }

    function onHoverProfile() {
        // setHoverProfile(true);
    }

    function offHoverProfile() {
        // setHoverProfile(false);
    }

    return (
        <Paper
            square
            variant={"outlined"}
            sx={{
                borderWidth: '0px 0px 1px 0px',
                display: 'flex',
                py: 1,
                zIndex: 11,
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{flexGrow: 1, pl: 3, py: 1, textDecoration: 'none',}}
                component={RouterLink}
                to="/dashboard"
            >
                <img src={ADILogo} alt={'analog devices'} height={40} width={100}/>
                <Box
                    sx={{
                        backgroundColor: 'rgba(120, 120, 120, 1)',
                        borderRadius: 0.7,
                        width: 2,
                        height: '100%',
                        ml: 1
                    }}
                />
                <Typography
                    fontWeight={'bold'}
                    sx={{
                        fontSize: '20px',
                        textDecoration: 'none',
                        color: "#000",
                        textTransform: 'none',
                    }}

                >
                    Algorithms Evaluation Platform
                </Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <div
                    // onClick={handleNotificationClick}
                    onMouseEnter={onHoverBell}
                    onMouseLeave={offHoverBell}
                    // style={{cursor: 'pointer'}}
                >
                    <NotificationsDefault/>
                    {/*{!hoverBell && notifications[0].read && <NotificationsDefault/>}*/}
                    {/*{!hoverBell && !notifications[0].read && <NotificationsWithAlerts/>}*/}
                    {/*{hoverBell && notifications[0].read && <NotificationsDefault/>}*/}
                    {/*{hoverBell && !notifications[0].read && <NotificationsWithHoverAndAlerts/>}*/}
                </div>
                <Popover
                    id={'1'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    // onClose={handleNotificationClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{mt: 2}}
                >
                    <Paper
                        sx={{p: 2, width: 600}}
                    >
                        <Typography
                            fontWeight={"bold"}
                            fontSize={16}
                        >
                            Notifications
                        </Typography>
                        <Box sx={{width: '100%', typography: 'body1'}}>
                            <TabContext value={value}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList
                                        // onChange={handleTabChange}
                                    >
                                        <Tab sx={{textTransform: 'none'}} label="All notifications" value="1"/>
                                        <Tab sx={{textTransform: 'none'}} label="Accounts" value="2"/>
                                        <Tab sx={{textTransform: 'none'}} label="System" value="3"/>
                                    </TabList>
                                </Box>
                                <div style={{maxHeight: '30vh', overflow: 'auto'}}>
                                    {notifications.filter((notification: any) => value === '1' || notification.category === value).map((notification: any, index: number) => (
                                        <Grid
                                            container
                                            sx={{
                                                background: 'rgba(235, 235, 235, 1)',
                                                width: '100%',
                                                my: 1,
                                                p: 1
                                            }}
                                            key={index}
                                        >
                                            <Grid item xs={1.2}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'rgba(165, 165, 165, 1)',
                                                        borderRadius: 20,
                                                        width: 36,
                                                        height: 36,
                                                        ml: 1
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        fontSize={15}
                                                        sx={{mb: 1}}
                                                    >
                                                        {notification.title + ' > ' + notification.reason}
                                                    </Typography>
                                                    <Typography
                                                        fontSize={14}
                                                        color={'grey'}
                                                        sx={{mb: 1.7}}
                                                    >
                                                        {notification.description}
                                                    </Typography>
                                                    <Link sx={{
                                                        textDecoration: 'none',
                                                        cursor: 'pointer',
                                                        fontSize: 13
                                                    }}>{"View More >"}</Link>
                                                </div>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={1.8}>
                                                <div>
                                                    <Box
                                                        sx={{
                                                            backgroundColor: 'rgba(204, 71, 52, 1)',
                                                            borderRadius: 20,
                                                            width: 12,
                                                            height: 12,
                                                            mt: 1,
                                                            ml: 1
                                                        }}
                                                    />
                                                    <br/>
                                                    <br/>
                                                    <Typography
                                                        color={'rgba(204, 71, 52, 1)'}
                                                        fontSize={11}
                                                    >
                                                        {/*{getNotification((new Date()).getTime() - new Date(notification.createdOn).getTime())}*/}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </div>
                            </TabContext>
                        </Box>
                    </Paper>
                    {/*<Typography sx={{ p: 2 }}>The content of the Popover.</Typography>*/}
                </Popover>
                <div
                    // onClick={handleNotificationClick}
                    onMouseEnter={onHoverProfile}
                    onMouseLeave={offHoverProfile}
                    // style={{cursor: 'pointer'}}
                >
                    <HeadphonesDefault/>
                </div>
                <Box sx={{width: '1px', height: "100%", backgroundColor: 'rgba(217, 217, 217, 1)'}}/>
                <div
                    // onClick={handleNotificationClick}
                    onMouseEnter={onHoverProfile}
                    onMouseLeave={offHoverProfile}
                    // style={{cursor: 'pointer'}}
                >
                    <Avatar
                        sx={{
                            width: '30px',
                            height: '30px',
                            bgcolor: deepPurple[500]
                        }}
                    >
                        {user.first_name && user.first_name[0].toUpperCase()}
                    </Avatar>
                </div>
                <ArrowDropDownRoundedIcon style={{marginLeft: '1px'}}/>
                <Box
                    style={{marginLeft: '8px'}}
                    sx={{width: '1px', height: "100%", backgroundColor: 'rgba(217, 217, 217, 1)'}}
                />
                <Link
                    component={RouterLink}
                    to="/logout"
                    sx={{textDecoration: 'none', pr: 4}}
                    fontWeight={"bold"}
                >
                    <Logout/>
                </Link>
            </Stack>
        </Paper>
    )
}