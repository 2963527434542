import * as React from "react";
import {
    Stack,
    Typography
} from "@mui/material";
import {useEffect} from "react";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";

export default function PasswordStatus(props: any) {

    const [statusColor, setStatusColor] = React.useState('#757A8B');

    useEffect(() => {
        if (props.status === false)
            setStatusColor("#c70f49")
        else if (props.status === true)
            setStatusColor('#009d80')
    }, [props.status])

    return <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{color: statusColor}}
    >
        {props.status === null && <HmcIcon name="circle-outline-xs" style={{marginRight: '8px'}}/>}
        {props.status === true && <HmcIcon name="check-circle-s" style={{marginRight: '8px'}}/>}
        {props.status === false && <HmcIcon name="close-circle-s" style={{marginRight: '8px'}}/>}

        <Typography variant={"body2"} sx={{color: statusColor}}>
            {props.children}
        </Typography>
    </Stack>;
}