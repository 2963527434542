import React from 'react';
import {Stack} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {useAppSelector} from "../../../store/hooks";

export default function PerformanceCharts() {
    const lineGraph = useAppSelector((state: any) => state.spo2Algorithm.lineGraph);
    const referenceFiles = useAppSelector((state: any) => state.commonAlgorithm.referenceFiles);

    return (
        <>
            {
                lineGraph[4] === null &&
                <Skeleton
                    variant="rectangular"
                    sx={{width: '97%', height: '335px', ml: 2, my: 2, backgroundColor: 'rgb(241, 243, 250)'}}
                />
            }
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{mr: 1, mt: 2}}
            >
                {referenceFiles[0].state === "Uploaded" && lineGraph[0] && <img
                    src={`data:image/png;base64, ${lineGraph[0]}`}
                    alt={"SpO2 Output vs Reference"}
                    style={{width: '98%'}}
                />}
                {referenceFiles[0].state === "Initial" && lineGraph[1] && <img
                    src={`data:image/png;base64, ${lineGraph[1]}`}
                    alt={"SpO2 Output"}
                    style={{width: '98%'}}
                />}
                {lineGraph[2] && <img
                    src={`data:image/png;base64, ${lineGraph[2]}`}
                    alt={"Input IR PPG"}
                    style={{width: '98%'}}
                />}
                {lineGraph[3] && <img
                    src={`data:image/png;base64, ${lineGraph[3]}`}
                    alt={"Input Red PPG"}
                    style={{width: '98%'}}
                />}
                {lineGraph[4] && <img
                    src={`data:image/png;base64, ${lineGraph[4]}`}
                    alt={"Input Accelerometer Magnitude"}
                    style={{width: '98%'}}
                />}
                {lineGraph[5] && <img
                    src={`data:image/png;base64, ${lineGraph[5]}`}
                    alt={"SpO2 Confidence"}
                    style={{width: '98%'}}
                />}
                {lineGraph[6] && <img
                    src={`data:image/png;base64, ${lineGraph[6]}`}
                    alt={"IR PI"}
                    style={{width: '98%'}}
                />}
                {lineGraph[7] && <img
                    src={`data:image/png;base64, ${lineGraph[7]}`}
                    alt={"Red PI"}
                    style={{width: '98%'}}
                />}
                {lineGraph[8] && <img
                    src={`data:image/png;base64, ${lineGraph[8]}`}
                    alt={"Low PI FLag"}
                    style={{width: '98%'}}
                />}
                {lineGraph[9] && <img
                    src={`data:image/png;base64, ${lineGraph[9]}`}
                    alt={"Low Signal Quality Flag"}
                    style={{width: '98%'}}
                />}
                {lineGraph[10] && <img
                    src={`data:image/png;base64, ${lineGraph[10]}`}
                    alt={"Unreliable R Flag"}
                    style={{width: '98%'}}
                />}
                {lineGraph[11] && <img
                    src={`data:image/png;base64, ${lineGraph[11]}`}
                    alt={"Motion Flag"}
                    style={{width: '98%'}}
                />}
            </Stack>
        </>
    );
}