import React, {useEffect, useRef, useState} from 'react';
import Footer2 from "../../components/Footer2";
import Header2 from "../../components/Header2";
import {Link as RouterLink} from "react-router-dom";
import {algorithmRequest} from "../../store/Algorithm/api";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import ChangePasswordDialog from "../../components/ChangePasswordDialog";
import {userDetailRequest} from "../../store/Auth/api";
import {
    Box,
    Divider,
    Grid,
    Link,
    Paper,
    Typography,
    Stack, SvgIcon
} from '@mui/material';
import dashboard_illustration_bottom from "../../svgs/dashboard-illustration-bottom.png"
import dashboard_illustration_top from "../../svgs/dashboard-illustration-top.png"
import {ReactComponent as hr_icon} from "../../svgs/HR.svg";


export default function Dashboard() {
    const algorithms = useAppSelector((state: any) => state.algorithm.algorithms);
    const dispatch = useAppDispatch();
    const footerRef = useRef<HTMLDivElement>(null);
    const [bottom, setBottom] = useState(0)

    useEffect(() => {
        dispatch(userDetailRequest())
        dispatch(algorithmRequest())
    }, [dispatch])

    useEffect(() => {
        if (footerRef.current) {
            const data = footerRef.current.getBoundingClientRect();
            setBottom(parseInt(data.top + ''))
        }
    }, [footerRef, algorithms]);

    useEffect(() => {
        document.title = 'Dashboard';
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#0067B9'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_top})`,
                    width: '185px',
                    height: '274px',
                    top: 5,
                    right: 0,
                    zIndex: 10,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_bottom})`,
                    backgroundRepeat: "no-repeat",
                    width: '279px',
                    height: '414px',
                    left: 0,
                    top: `${bottom - 400}px`,
                    zIndex: 10,
                }}
            />
            <Header2/>
            <Box
                sx={{
                    mb: 2,
                    mx: "auto",
                    my: 5,
                    width: '750px'
                }}
            >
                <Typography
                    variant="body1"
                    align={"center"}
                    sx={{mt: 2, fontSize: '21px'}}
                    color={"#fff"}
                >
                    ADI Monitor - Algorithms Evaluation Platform
                </Typography>
                <Typography
                    variant="h5"
                    align={"center"}
                    sx={{fontSize: '30px'}}
                    color={"#fff"}
                >
                    Evaluate the algorithms you have access to by adding your configuration and file.
                </Typography>
                <Typography
                    variant="body1"
                    align={"center"}
                    sx={{my: 2, fontSize: '21px'}}
                    color={"#fff"}
                >
                    Evaluate one algorithm at a time:
                </Typography>
            </Box>
            <Grid
                container
                sx={{
                    mx: "auto",
                    background: 'rgba(241, 243, 250, 1)',
                    p: 6,
                    mb: 10,
                    borderRadius: '16px',
                    width: '80%'
                }}
                data-testid="algorithms_list_container"
            >
                {algorithms.map((cardInfo: any, index: number) => (
                    <Grid item xs={6} key={index}>
                        <Paper
                            sx={{
                                p: 2,
                                m: 2.5,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}
                            data-testid="algorithm_widget"
                        >
                            <Stack
                                direction='row'
                                justifyContent='flex-start'
                                alignItems='center'
                                sx={{mb: 2}}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#0067B9',
                                        p: 1,
                                        py: 0,
                                        mr: 1,
                                        borderRadius: 100
                                    }}
                                >
                                    <SvgIcon component={hr_icon} sx={{mt: 1.4}}/>
                                </Box>
                                <Typography
                                    variant='h6'
                                    fontWeight={"bold"}
                                >
                                    {cardInfo.display_name}
                                </Typography>
                            </Stack>
                            <Typography
                                variant='body1'
                                sx={{mb: 2}}
                            >
                                {cardInfo.description}
                            </Typography>
                            <Divider sx={{mb: 2}}/>
                            <Link
                                target="_blank"
                                component={RouterLink}
                                to={cardInfo.name}
                                sx={{
                                    mt: 'auto',
                                    alignSelf: 'flex-start',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                    cursor: 'pointer'
                                }}>
                                <Typography
                                    color='primary'
                                    sx={{mr: 1, fontSize: '14px'}}
                                >
                                    Evaluate Algorithm
                                </Typography>
                                <ArrowForwardIcon color='primary'/>
                            </Link>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: '#fff'
                }}
                ref={footerRef}
            >
                <Footer2/>
            </Box>
            <ChangePasswordDialog/>
        </Box>
    )
}