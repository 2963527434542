import {createSlice} from "@reduxjs/toolkit";
import {
    setState,
    setInput,
    clearInput,
    validateInput,
    validateEmail,
    InputInterface,
} from '../validation'
import {loginRequest, userDetailRequest} from "./api";

interface authState {
    email: InputInterface;
    password: InputInterface;
    response: InputInterface;
    isLoggedIn: boolean;
    user: any;
    status: "idle" | "pending" | "succeeded" | "failed";
}

let defaultState = {
    email: {data: '', errorMessage: '', isValid: false},
    password: {data: '', errorMessage: '', isValid: false},
    response: {data: '', errorMessage: '', isValid: false},
    isLoggedIn: window.localStorage.getItem('access') !== null,
    user: JSON.parse(window.localStorage.getItem('user') || "{}") as any,
    status: 'idle' as 'idle' | 'pending' | 'succeeded' | 'failed'
}

const authSlice = createSlice({
    name: "authSlice",
    initialState: defaultState as authState,
    reducers: {
        resetStates() {
            return defaultState;
        },
        // clearPassword(state) {
        //     clearInput(state, "password")
        // },
        clearEmail(state) {
            clearInput(state, "email")
        },
        // clearResponse(state) {
        //     clearInput(state, "response")
        // },
        validateEmail(state) {
            validateEmail(state, "email")
        },
        validatePassword(state) {
            validateInput(state, "password", "Please enter your password.")
        },
        setEmail(state, action) {
            const email = action.payload.toString().toLowerCase()
            setInput(state, "email", email)
        },
        setPassword(state, action) {
            setInput(state, "password", action.payload)
        },
        // setResponse(state, action) {
        //     setState(state, "response", action.payload)
        // },
        logout(state) {
            window.localStorage.removeItem('user');
            window.localStorage.removeItem('access');
            window.localStorage.removeItem('refresh');
            state.isLoggedIn = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginRequest.pending, (state, _) => {
            window.localStorage.removeItem('user');
            state.status = 'pending'
            clearInput(state, "response")
        })
        builder.addCase(loginRequest.fulfilled, (state, action) => {
            state.status = 'succeeded'
            window.localStorage.setItem('access', action.payload.access);
            window.localStorage.setItem('refresh', action.payload.refresh);
        })
        builder.addCase(userDetailRequest.fulfilled, (state, action) => {
            state.isLoggedIn = true
            state.user = action.payload
            window.localStorage.setItem('user', JSON.stringify(action.payload));
        })
        builder.addCase(loginRequest.rejected, (state, _) => {
            state.status = 'failed'
            setState(state, "response", {
                data: '',
                errorMessage: 'Incorrect email address or password. Please try again.',
                isValid: false
            })
            clearInput(state, "email")
            clearInput(state, "password")

        })
    },
});

export const authActions = authSlice.actions;
export default authSlice;