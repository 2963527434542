import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormattedHelperText from "../../FormattedHelperText";
import {whrmAlgoActions} from "../../../store/WHRMAlgorithm/slice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {FormControlLabel, InputAdornment, Paper, Radio, Stack, TextField, Typography} from "@mui/material";

export default function Inputs() {
    const age = useAppSelector((state: any) => state.whrmAlgorithm.age);
    const height = useAppSelector((state: any) => state.whrmAlgorithm.height);
    const weight = useAppSelector((state: any) => state.whrmAlgorithm.weight);
    const gender = useAppSelector((state: any) => state.whrmAlgorithm.gender);
    const dispatch = useAppDispatch();

    function ageChangeHandler(e: any) {
        dispatch(whrmAlgoActions.setAge(e.target.value));
        dispatch(whrmAlgoActions.validateAge());
    }

    function heightChangeHandler(e: any) {
        dispatch(whrmAlgoActions.setHeight(e.target.value));
        dispatch(whrmAlgoActions.validateHeight());
    }

    function weightChangeHandler(e: any) {
        dispatch(whrmAlgoActions.setWeight(e.target.value))
        dispatch(whrmAlgoActions.validateWeight());
    }

    function setGenderFemale() {
        dispatch(whrmAlgoActions.setGender(1));
        dispatch(whrmAlgoActions.validateGender());
    }

    function setGenderMale() {
        dispatch(whrmAlgoActions.setGender(0));
        dispatch(whrmAlgoActions.validateGender());
    }

    return (
        <Paper
            variant={'outlined'}
            sx={{
                p: 1,
                m: 1.5,
                mb: 8,
                mx: 3
            }}>
            <Typography sx={{m: 1, fontSize: '14px'}}>Age:</Typography>
            <TextField
                inputProps={{'data-testid': 'age-field'}}
                type="number"
                value={age.data}
                onChange={ageChangeHandler}
                error={age.errorMessage.length > 0}
                helperText={<FormattedHelperText text={age.errorMessage}/>}
                sx={{width: '95%', ml: 1}}
            />
            <Typography sx={{m: 1, fontSize: '14px'}}>Height:</Typography>
            <OutlinedInput
                inputProps={{'data-testid': 'height-field'}}
                endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                sx={{width: '95%', ml: 1}}
                type={'number'}
                value={height.data}
                error={height.errorMessage.length > 0}
                onChange={heightChangeHandler}
            />
            <FormattedHelperText text={height.errorMessage} sx={{fontSize: '12px', ml: 1, pt: 0.5}}/>
            <Typography sx={{m: 1, fontSize: '14px'}}>Weight:</Typography>
            <OutlinedInput
                inputProps={{'data-testid': 'weight-field'}}
                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                sx={{width: '95%', ml: 1}}
                type={'number'}
                value={weight.data}
                error={weight.errorMessage.length > 0}
                onChange={weightChangeHandler}
            />
            <FormattedHelperText text={weight.errorMessage} sx={{fontSize: '12px', ml: 1, pt: 0.5}}/>
            <Typography sx={{m: 1, mb: 0, fontSize: '14px'}}>Gender: </Typography>
            <Stack
                direction={'row'}
                sx={{justifyContent: 'space-around'}}
            >
                <FormControlLabel
                    onClick={setGenderFemale}
                    checked={gender.data === 1}
                    control={<Radio/>}
                    label="Female"
                />
                <FormControlLabel
                    onClick={setGenderMale}
                    checked={gender.data === 0}
                    control={<Radio/>}
                    label="Male"
                />
            </Stack>
        </Paper>
    )
}
