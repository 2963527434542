import React, {useEffect} from "react";
import validator from "validator";
import toBoolean = validator.toBoolean;
import {Close} from "@mui/icons-material";
import LoadingButton from "../LoadingButton";
import PasswordTextField from "../PasswordTextField";
import {adminActions} from "../../store/Admin/slice";
import {userDetailRequest} from "../../store/Auth/api";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {changePasswordRequest} from "../../store/ResetPassword/api";
import {resetPasswordActions} from "../../store/ResetPassword/slice";
import ResetPasswordFragment from "../Fragments/ResetPasswordFragment";
import {
    Box,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    IconButton,
    Stack, SvgIcon,
    Typography
} from "@mui/material";
import {ReactComponent as lock_icon} from "../../svgs/lock-icon.svg"

export default function ChangePasswordDialog() {
    const user = useAppSelector((state: any) => state.auth.user);
    const resetPassword = useAppSelector((state: any) => state.resetPassword);
    const successStatus = useAppSelector((state: any) => state.resetPassword.status);
    const currentPassword = useAppSelector((state: any) => state.resetPassword.currentPassword);
    const dispatch = useAppDispatch();

    function handleChangePassword() {
        dispatch(resetPasswordActions.validatePassword())
        dispatch(resetPasswordActions.validateCurrentPassword())
        dispatch(resetPasswordActions.validateConfirmPassword())
        if (
            resetPassword.currentPassword.isValid &&
            resetPassword.confirmPassword.isValid &&
            resetPassword.passwordStatus10Characters &&
            resetPassword.passwordStatus1Number &&
            resetPassword.passwordStatus1Capital &&
            resetPassword.passwordStatus1Lower &&
            resetPassword.passwordStatus1Special
        ) {
            dispatch(changePasswordRequest())
        }
    }

    function handleCurrentPasswordChange(event: any) {
        dispatch(resetPasswordActions.setCurrentPassword(event.target.value))
        dispatch(resetPasswordActions.validateCurrentPassword())
    }

    function handleClose(_: any, reason: any = null) {
        if (reason && reason === "backdropClick")
            return;
        dispatch(adminActions.closeAccountPermissionDialog());
    }

    useEffect(() => {
        if (successStatus === "succeeded") {
            dispatch(userDetailRequest())
        }
    }, [dispatch, successStatus])

    return (
        <Dialog
            open={user.status === "ACCOUNT_INITIATED"}
            onClose={handleClose}
            fullWidth
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{py: 2, pl: 2}}
            >
                <SvgIcon component={lock_icon} viewBox={""} sx={{height: '48px', width: '48px'}}/>
                <Typography sx={{ml: 10}} variant={"h5"}>
                    Change Password
                </Typography>
            </Stack>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close/>
            </IconButton>
            <Divider/>
            <DialogContent>
                <Typography
                    color='grey'
                    variant='body2'
                    sx={{mx: 9, mb: 2}}
                >
                    Please change your password now as you will not be able
                    to use this temporary password a second time.
                </Typography>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <FormControl>
                        Current Password:
                        <PasswordTextField
                            inputProps={{'data-testid': 'reset-password-input-current'}}
                            sx={{mb: 3, width: 400}}
                            error={toBoolean(currentPassword.errorMessage)}
                            helperText={currentPassword.errorMessage}
                            autoComplete="current-password"
                            status={successStatus}
                            onChange={handleCurrentPasswordChange}
                            value={currentPassword.data}
                        />
                    </FormControl>
                    <ResetPasswordFragment changePasswordDialog/>
                    <LoadingButton
                        onClick={handleChangePassword}
                        fullWidth
                        variant="contained"
                        status={successStatus}
                        sx={{width: 400, height: 50, textTransform: 'None'}}
                    >
                        Confirm Changing
                    </LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
