import validator from 'validator';
import React, {useEffect, useRef, useState} from 'react';
import toBoolean = validator.toBoolean;
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Link as RouterLink} from "react-router-dom";
import CachedIcon from '@mui/icons-material/Cached';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '../../components/LoadingButton';
import SimpleTextField from "../../components/SimpleTextField";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {requestAccountActions} from "../../store/RequestAccount/slice";
import {requestAccount, requestCaptcha} from "../../store/RequestAccount/api";
import RequestAccountFragment from "../../components/Fragments/RequestAccountFragment";
import {
    Box,
    Button,
    Container,
    Divider, FormControl,
    FormHelperText,
    IconButton,
    Link,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";
import dashboard_illustration_top from "../../svgs/dashboard-illustration-top.png";
import dashboard_illustration_bottom from "../../svgs/dashboard-illustration-bottom.png";

export default function RequestAccount() {
    const companyName = useAppSelector((state: any) => state.requestAccount.companyName);
    const firstName = useAppSelector((state: any) => state.requestAccount.firstName);
    const lastName = useAppSelector((state: any) => state.requestAccount.lastName);
    const emailAddress = useAppSelector((state: any) => state.requestAccount.emailAddress);
    const phoneNumber = useAppSelector((state: any) => state.requestAccount.phoneNumber);
    const purpose = useAppSelector((state: any) => state.requestAccount.purpose);
    const captcha = useAppSelector((state: any) => state.requestAccount.captcha);
    const requestUsers = useAppSelector((state: any) => state.requestAccount.requestUsers);
    const captchaImage = useAppSelector((state: any) => state.requestAccount.captchaImage);
    const requestStatus = useAppSelector((state: any) => state.requestAccount.status);
    const dispatch = useAppDispatch();
    const footerRef = useRef<HTMLDivElement>(null);
    const [bottom, setBottom] = useState(0)

    useEffect(() => {
        dispatch(requestCaptcha())
    }, [dispatch])

    useEffect(() => {
        if (footerRef.current) {
            const data = footerRef.current.getBoundingClientRect();
            setBottom(parseInt(data.top + ''))
        }
    }, [footerRef]);

    useEffect(() => {
        document.title = 'Request Account';
    }, []);

    useEffect(() => {
        if (requestStatus === "failed")
            dispatch(requestCaptcha())
    }, [dispatch, requestStatus])


    function handleRequestAccount() {
        dispatch(requestAccountActions.validateCompanyName())
        dispatch(requestAccountActions.validateEmailAddress())
        dispatch(requestAccountActions.validateFirstName())
        dispatch(requestAccountActions.validateLastName())
        dispatch(requestAccountActions.validatePhoneNumber())
        dispatch(requestAccountActions.validatePurpose())
        dispatch(requestAccountActions.validateCaptcha())

        requestUsers.map((user: any) => dispatch(requestAccountActions.validateUserFirstName(user.id)))
        requestUsers.map((user: any) => dispatch(requestAccountActions.validateUserEmail(user.id)))
        requestUsers.map((user: any) => dispatch(requestAccountActions.validateUserLastName(user.id)))
        const allUsersValid = requestUsers.every((user: any) => {
            return user.firstName.isValid && user.lastName.isValid && user.email.isValid;
        });

        if (
            companyName.isValid &&
            emailAddress.isValid &&
            firstName.isValid &&
            lastName.isValid &&
            purpose.isValid &&
            captcha.isValid &&
            phoneNumber.isValid &&
            allUsersValid
        ) {
            dispatch(requestAccount())
        }
    }

    function clearCaptcha() {
        dispatch(requestAccountActions.clearCaptcha())
    }

    function handleCaptchaChange(event: any) {
        dispatch(requestAccountActions.setCaptcha(event.target.value))
        dispatch(requestAccountActions.validateCaptcha())
    }

    function refreshCaptcha() {
        dispatch(requestCaptcha())
    }

    function handlePageReset() {
        dispatch(requestCaptcha())
        dispatch(requestAccountActions.resetPage())
    }

    const getFormattedHelperText = (text: any) => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
                component="span"
                color="#c70f49"
                sx={{ml: -1.5}}
            >
                <HmcIcon name="error-s" style={{marginRight: '8px'}}/>
                {text}
            </Stack>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#0067B9'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_top})`,
                    width: '185px',
                    height: '274px',
                    top: 80,
                    right: 0,
                    zIndex: 10,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_bottom})`,
                    backgroundRepeat: "no-repeat",
                    width: '279px',
                    height: '414px',
                    left: 0,
                    top: `${bottom - 340}px`,
                    zIndex: 10,
                }}
            />
            <Header/>
            <Container
                component="main"
                sx={{
                    width: 570,
                    marginTop: 8,
                    py: 2,
                    px: 2,
                    pb: 3,
                }}
            >
                <Paper sx={{py: 2, px: 2, pb: 3}}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{mt: 4, mb: 1, pl: 3}}
                    >
                        Request Account
                    </Typography>
                    {requestStatus !== 'succeeded' && <RequestAccountFragment/>}
                    {requestStatus !== 'succeeded' &&
                        <React.Fragment>
                            <FormControl
                                variant="standard"
                                sx={{pl: 3, pr: 3, my: 1}}
                                fullWidth
                            >
                                Prove that you are human:
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        width: 440,
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <img
                                            src={captchaImage}
                                            style={{width: '300px', height: '90px'}}
                                            alt={"Captcha"}
                                        />
                                        <IconButton onClick={refreshCaptcha}>
                                            <CachedIcon color='primary' fontSize='large'/>
                                        </IconButton>
                                    </Stack>
                                    <SimpleTextField
                                        sx={{
                                            width: 440
                                        }}
                                        error={toBoolean(captcha.errorMessage)}
                                        onClear={clearCaptcha}
                                        onChange={handleCaptchaChange}
                                        value={captcha.data}
                                        status={requestStatus}
                                    />
                                </Paper>
                                {captcha.errorMessage.length > 0 && <FormHelperText sx={{color: 'red', ml: 1.5}}>
                                    {getFormattedHelperText(captcha.errorMessage)}
                                </FormHelperText>}
                            </FormControl>
                            <br/>
                            <LoadingButton
                                onClick={handleRequestAccount}
                                fullWidth
                                variant="contained"
                                status={requestStatus}
                                sx={{
                                    ml: 3,
                                    mr: 3,
                                    width: 440,
                                    height: 50,
                                    textTransform: 'None'
                                }}
                            >
                                Request Account
                            </LoadingButton>
                        </React.Fragment>
                    }
                    {requestStatus === 'succeeded' &&
                        <>
                            <Typography
                                variant={"body1"}
                                sx={{mx: 3, mt: 3}}
                                fontSize={12}
                            >
                                Your request has been sent to admin successfully. Requested accounts will get access
                                post
                                approval.
                            </Typography>
                            <Button
                                variant='contained'
                                sx={{m: 1, mx: 3}}
                                onClick={handlePageReset}
                            >
                                Create new Request
                            </Button>
                        </>}
                    <Divider sx={{width: '90%', mt: 3, mb: 2, ml: 3, mr: 3}}/>
                    <Stack sx={{pl: 3, pr: 3, mb: 2}}>
                        <Link
                            component={RouterLink}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                            }}
                            to='/login'
                            onClick={handlePageReset}
                        >
                            <ArrowBackIcon color={"primary"} sx={{mr: 1}}/>
                            <b color='primary'>Back to Login Page</b>
                        </Link>
                    </Stack>
                </Paper>
            </Container>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                }}
                ref={footerRef}
            >
                <Footer sx={{mb: 1}}/>
            </Box>
        </Box>
    )
}