import {Line} from "react-chartjs-2";
import React, {useEffect} from 'react';
import {LayoutPosition} from "chart.js";
import LoadingButton from "../../LoadingButton";
import {whrmAlgoActions} from "../../../store/WHRMAlgorithm/slice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {startWHRMSyncEvaluation} from "../../../store/WHRMAlgorithm/api";
import {Box, Paper, Slider, Stack, TextField, Typography} from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function SyncLineChart() {
    const syncLabel = useAppSelector((state: any) => state.whrmAlgorithm.syncLabel);
    const referenceHRData = useAppSelector((state: any) => state.whrmAlgorithm.referenceHRData);
    const algoHRData = useAppSelector((state: any) => state.whrmAlgorithm.algoHRData);
    const [modifiedHRData, setModifiedHRData] = React.useState<any>();
    const timeDiffInSec = useAppSelector((state: any) => state.whrmAlgorithm.timeDiffInSec);
    const bpm5Acc = useAppSelector((state: any) => state.whrmAlgorithm.bpm5Acc);
    const bpm10Acc = useAppSelector((state: any) => state.whrmAlgorithm.bpm10Acc);
    const MAE = useAppSelector((state: any) => state.whrmAlgorithm.MAE);
    const startEvaluationSyncAPIStatus = useAppSelector((state: any) => state.commonAlgorithm.startEvaluationSyncAPIStatus);
    const referenceFiles = useAppSelector((state: any) => state.commonAlgorithm.referenceFiles);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (timeDiffInSec > 0) {
            setModifiedHRData(algoHRData.slice(timeDiffInSec))
        } else if (timeDiffInSec === 0) {
            setModifiedHRData(algoHRData)
        } else {
            const tempDiff = timeDiffInSec * -1
            const zeros = Array(tempDiff).fill(null);
            const updatedArr = [...zeros, ...algoHRData.slice(0, timeDiffInSec)];
            setModifiedHRData(updatedArr)
        }
    }, [algoHRData, timeDiffInSec])

    function handleTimeDiffChange(e: any) {
        const step = e.target.value;
        dispatch(whrmAlgoActions.setTimeDiffInSec(step))
    }

    function handleSync() {
        dispatch(startWHRMSyncEvaluation())
    }

    function handleSliderChange(_: any, newValue: any) {
        dispatch(whrmAlgoActions.setTimeDiffInSec(newValue as number))
    }

    const chartOptions = {
        responsive: true,
        spanGaps: true,
        elements: {
            line: {
                tension: 0
            },
            point: {
                radius: 0
            }
        },
        maintainAspectRatio: true,
        animation: false as any,
        hover: false as any,
        responsiveAnimationDuration: 0,
        plugins: {
            legend: {
                position: "top" as LayoutPosition,
            },
            title: {
                display: true,
                text: 'Synchronize with reference data',
            },
        }
    };

    const graphData = {
        labels: syncLabel,
        datasets: [
            {
                label: 'HR data',
                data: modifiedHRData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Ref HR data',
                data: referenceHRData,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    }

    return (
        <Stack
            direction='column'
            sx={{
                backgroundColor: '#F1F3FA',
                borderRadius: 1,
                m: 2,
                px: 2,
                height: '88%',
            }}
        >
            <Paper
                variant={"outlined"}
                sx={{width: '300px', p: 1, px: 2, mb: 5, mt: 1}}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Box>
                        <Typography fontSize={18}>{bpm5Acc}</Typography>
                        <Typography fontSize={12}>+/-5 bpm Acc</Typography>
                    </Box>
                    <Box>
                        <Typography fontSize={18}>{bpm10Acc}</Typography>
                        <Typography fontSize={12}>+/-10 bpm Acc</Typography>
                    </Box>
                    <Box>
                        <Typography fontSize={18}>{MAE}</Typography>
                        <Typography fontSize={12}>MAE</Typography>
                    </Box>
                </Stack>
            </Paper>

            <Line
                options={chartOptions}
                data={graphData}
            />

            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                sx={{width: '100%', mt: 2}}
            >
                <Typography
                    fontSize={12}
                    sx={{mr: 2}}
                >
                    Move the slider to sync:
                </Typography>
                <Slider
                    track={false}
                    value={timeDiffInSec === '' ? 0 : timeDiffInSec}
                    step={1}
                    min={-300}
                    max={300}
                    sx={{width: 150, mr: 2}}
                    valueLabelDisplay="auto"
                    onChangeCommitted={handleSliderChange}
                    disabled={referenceFiles.length > 0 && referenceFiles[0].state !== "Uploaded"}
                />
                <Typography
                    fontSize={12}
                    sx={{mr: 2}}
                >
                    or manually enter a value to sync:
                </Typography>
                <TextField
                    value={timeDiffInSec}
                    size={"small"}
                    type="number"
                    sx={{width: 100}}
                    onChange={handleTimeDiffChange}
                    disabled={referenceFiles.length > 0 && referenceFiles[0].state !== "Uploaded"}
                />
                <Box flex={1}></Box>
                <LoadingButton
                    sx={{px: 3, ml: 3, textTransform: 'none', fontSize: '14px', width: 125, height: '35px'}}
                    size={"small"}
                    variant={'contained'}
                    onClick={handleSync}
                    status={startEvaluationSyncAPIStatus}
                >
                    {(referenceFiles.length > 0 && referenceFiles[0].state !== "Uploaded") ? "Generate" : "Sync"}
                </LoadingButton>
            </Stack>
            <Typography sx={{mt: 2}} fontStyle={'italic'} fontSize={14} color={'rgba(117, 122, 139, 1)'}>
                **This performance is applicable only for an algorithm running
                on the cloud and it is not necessary what you would get from a specific platform.
            </Typography>
        </Stack>
    )
}

export default SyncLineChart;