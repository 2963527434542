import {Stack, Typography} from "@mui/material";
import {HmcIcon} from "@adi-ctx/harmonic-core-components-react";
import * as React from "react";

export default function ErrorAlert(props: any) {

    return (
        <Stack
            direction={'row'}
            alignContent={'center'}
            justifyContent={'center'}
            style={{
                marginTop: '16px',
                marginRight: '5px',
                backgroundColor: '#FDF2F2',
                border: '1px solid #D83A22',
                padding: '12px',
                borderRadius: 5
            }}
        >
            <HmcIcon name="warning-m" color="#c70f49"/>
            <Typography fontSize={14} sx={{ml: 1, color: '#D83A22'}} >{props.children}</Typography>
        </Stack>
    );
}