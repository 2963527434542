import validator from "validator";

export interface InputInterface {
    data: string;
    errorMessage: string;
    isValid: boolean
}

export interface AlgorithmInputInterface {
    data: number;
    errorMessage: string;
    isValid: boolean
}

export function CSVToArrParser(data: string, col: number, skip: number = 0) {
    let rows = data.split('\n')
    let resArr = []
    for (let i = 1; i < rows.length; ++i) {
        if (i < skip) continue
        resArr.push(parseInt(rows[i].split(",")[col]))
    }
    return resArr
}

export function validateSpO2Coefficient(state: any, stateName: string) {
    const data = state[stateName].data;
    const numberString = data.toString();
    if (!Number.isInteger(parseInt(numberString))) {
        state[stateName] = {
            data: data,
            errorMessage: 'Please enter decimal values',
            isValid: false
        }
    } else if (data < -1000 || data > 1000) {
        state[stateName] = {
            data: data,
            errorMessage: 'coefficient has to be between -1000.0 and 1000.0',
            isValid: false
        }
    } else if (!/^[+-]?(\d*[.])?\d+$/.test(numberString)) {
        state[stateName] = {
            data: data,
            errorMessage: 'Please enter decimal values',
            isValid: false
        }
    } else {
        state[stateName] = {
            data: data,
            errorMessage: '',
            isValid: true
        }
    }
}

export function validateInput(state: any, stateName: string, errorMessage: string) {
    const data = state[stateName].data;
    if (data.length === 0)
        state[stateName] = {
            data: data,
            errorMessage: errorMessage,
            isValid: false
        }
    else
        state[stateName] = {
            data: data,
            errorMessage: '',
            isValid: true
        }
}

export function validateEmail(
    state: any,
    stateName: string,
    errorMessage: string = 'Please enter your email address.'
) {
    const email = state[stateName].data;
    if (email.length === 0)
        state[stateName] = {
            data: email,
            errorMessage: errorMessage,
            isValid: false
        }
    else if (!validator.isEmail(email))
        state[stateName] = {
            data: email,
            errorMessage: 'The email doesn\'t have the correct format: yourname@example.com',
            isValid: false
        }
    else
        state[stateName] = {
            data: email,
            errorMessage: '',
            isValid: true
        }
}

export function setInput(state: any, stateName: string, payload: any) {
    state[stateName] = {
        data: payload,
        errorMessage: state[stateName].errorMessage,
        isValid: state[stateName].isValid
    }
}

export function setArrayInput(state: any, stateName: string, payload: any) {
    state[stateName] = {
        data: payload,
        errorMessage: state[stateName].errorMessage,
        isValid: state[stateName].isValid
    }
}


export function setState(state: any, stateName: string, payload: any) {
    state[stateName] = {
        data: payload.data,
        errorMessage: payload.errorMessage,
        isValid: payload.isValid
    }
}

export function clearInput(state: any, stateName: string) {
    state[stateName] = {
        data: '',
        errorMessage: '',
        isValid: false
    }
}