import React from 'react';
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import {ButtonProps} from "@mui/material/Button/Button";

interface LoadingButtonProps extends ButtonProps {
    status?: any
}

export default function LoadingButton(props: LoadingButtonProps) {
    const {status, sx, ...rest} = props

    return (
        <Button
            sx={{borderRadius: '4px', fontSize: '18px', textTransform: 'none', ...sx}}
            startIcon={status === 'pending' ? <CircularProgress color="inherit" size={20}/> : props.startIcon}
            disabled={status === 'pending'}
            {...rest}
        >
            {status === 'pending' ? null : props.children}
        </Button>
    );
}
