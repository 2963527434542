import {useAppSelector} from "../store/hooks";
import {Navigate, useLocation} from "react-router-dom";

export default function AlreadyLoginCheck({children}: { children: JSX.Element }) {
    const isLoggedIn = useAppSelector((state: any) => state.auth.isLoggedIn);
    const user = useAppSelector((state: any) => state.auth.user);
    let location = useLocation();

    if (isLoggedIn && user.groups[0].name === "user") {
        return <Navigate to="/dashboard" state={{from: location}} replace/>;
    }
    if (isLoggedIn && user.groups[0].name === "admin") {
        return <Navigate to="/admin" state={{from: location}} replace/>;
    }
    return children;
}