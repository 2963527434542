import React, {useContext} from "react"
import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import SettingsContext from "../context/SettingsContext";

interface Props {
    children: any
}

export default function ThemeConfig(props: Props) {
    const {children} = props;
    const {settings} = useContext(SettingsContext);
    return (
        <ThemeProvider theme={settings.theme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    );
}
