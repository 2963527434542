import React, {useEffect, useRef, useState} from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {Link as RouterLink} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {forgotPasswordActions} from "../../store/ForgotPassword/slice";
import ForgotPasswordFragment from "../../components/Fragments/ForgotPasswordFragment";
import {
    Container,
    Typography,
    Stack,
    Link, Box, Paper, Divider
} from '@mui/material';
import dashboard_illustration_top from "../../svgs/dashboard-illustration-top.png";
import dashboard_illustration_bottom from "../../svgs/dashboard-illustration-bottom.png";

function ForgotPasswordStatus() {
    return (
        <>
            <Typography
                variant="body2"
                color="grey"
                align="left"
                lineHeight={1.6}
                sx={{pl: 6, pr: 3, mb: 4}}
            >
                You will get an email with instructions on resetting your password.
                <br/>
                If it doesn't arrive, be sure to check your spam folder.
            </Typography>
            <Divider sx={{width: '90%', mt: 1, mb: 2, ml: 3, mr: 3}}/>
        </>
    )
}

export default function ForgotPassword() {
    const successStatus = useAppSelector((state: any) => state.forgotPassword.status);
    const dispatch = useAppDispatch();
    const footerRef = useRef<HTMLDivElement>(null);
    const [bottom, setBottom] = useState(0)

    function resetStates() {
        dispatch(forgotPasswordActions.resetStates());
    }

    useEffect(() => {
        if (footerRef.current) {
            const data = footerRef.current.getBoundingClientRect();
            setBottom(parseInt(data.top + ''))
        }
    }, [footerRef]);

    useEffect(() => {
        document.title = 'Forgot Password';
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#0067B9'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_top})`,
                    width: '185px',
                    height: '274px',
                    top: 80,
                    right: 0,
                    zIndex: 10,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_bottom})`,
                    backgroundRepeat: "no-repeat",
                    width: '279px',
                    height: '414px',
                    left: 0,
                    top: `${bottom - 340}px`,
                    zIndex: 10,
                }}
            />
            <Header/>
            <Container
                component="main"
                sx={{
                    width: 570,
                    marginTop: 8,
                    py: 2,
                    px: 2,
                    pb: 3,
                }}
            >
                <Paper sx={{py: 2, px: 2, pb: 3}}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{mt: 4, mb: 1, pl: 6}}
                    >
                        Forgot Password?
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {successStatus !== "succeeded" && <ForgotPasswordFragment/>}
                        {successStatus === "succeeded" && <ForgotPasswordStatus/>}
                    </Box>
                    <Stack sx={{pl: 3, pr: 3, mb: 2}}>
                        <Link
                            component={RouterLink}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                            }}
                            onClick={resetStates}
                            to='/login'
                        >
                            <ArrowBackIcon color={"primary"} sx={{mr: 1}}/>
                            <b style={{fontSize: '16px'}}>Back to Login Page</b>
                        </Link>
                    </Stack>
                </Paper>
            </Container>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                }}
                ref={footerRef}
            >
                <Footer sx={{mb: 1}}/>
            </Box>
        </Box>
    )
}