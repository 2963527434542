import React, {
    createContext,
    useState
} from 'react';
import _ from 'lodash';
import {createTheme} from "@mui/material/styles";
import {lightTheme} from "../theme/lightTheme";
import {darkTheme} from "../theme/darkTheme";

type DefaultSetting = {
    theme: any,
}

const defaultSettings: DefaultSetting = {
    theme: {
        lightTheme
    },
}

export const restoreSettings = () => {
    let settings = null;
    let selectedTheme = null
    try {
        const storedData = window.localStorage.getItem('settings');
        if (storedData) {
            settings = JSON.parse(storedData);
            selectedTheme = settings.theme.palette.mode === 'dark' ? darkTheme : lightTheme
        } else {
            settings = defaultSettings
            selectedTheme = lightTheme
        }
        settings.theme = createTheme(selectedTheme);
    } catch (err) {
        console.error(err);
    }

    return settings;
};

export const storeSettings = (settings: any) => {
    window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext({
    settings: defaultSettings,
    saveSettings: (update = {}) => {
    }
});

interface Props {
    children: any
}

export const SettingsProvider = (props: Props) => {
    const {children} = props;
    const [currentSettings, setCurrentSettings] = useState(restoreSettings());

    const handleSaveSettings = (update = {}) => {
        const mergedSettings = _.merge({}, currentSettings, update);
        setCurrentSettings(mergedSettings);
        storeSettings(mergedSettings);
    };
    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                saveSettings: handleSaveSettings
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export const SettingsConsumer = SettingsContext.Consumer;
export default SettingsContext;
