import React from "react";
import FormattedHelperText from "../../FormattedHelperText";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {spo2AlgoActions} from "../../../store/SpO2Algorithm/slice";
import {FormControlLabel, Paper, Radio, Stack, TextField, Typography} from "@mui/material";

export default function Inputs() {
    const coefficientA = useAppSelector((state: any) => state.spo2Algorithm.coefficientA);
    const coefficientB = useAppSelector((state: any) => state.spo2Algorithm.coefficientB);
    const coefficientC = useAppSelector((state: any) => state.spo2Algorithm.coefficientC);
    const enableCoefficient = useAppSelector((state: any) => state.spo2Algorithm.enableCoefficient);
    const dispatch = useAppDispatch();

    function coefficientAChangeHandler(e: any) {
        dispatch(spo2AlgoActions.setCoefficientA(e.target.value));
        dispatch(spo2AlgoActions.validateCoefficientA());
    }

    function coefficientBChangeHandler(e: any) {
        dispatch(spo2AlgoActions.setCoefficientB(e.target.value));
        dispatch(spo2AlgoActions.validateCoefficientB());
    }

    function coefficientCChangeHandler(e: any) {
        dispatch(spo2AlgoActions.setCoefficientC(e.target.value));
        dispatch(spo2AlgoActions.validateCoefficientC());
    }

    function disableCoefficientHandler() {
        dispatch(spo2AlgoActions.setEnableCoefficient(0));
    }

    function enableCoefficientHandler() {
        dispatch(spo2AlgoActions.setEnableCoefficient(1));
    }

    return (
        <Paper
            variant={'outlined'}
            sx={{
                p: 1,
                m: 1.5,
                mb: 8,
                mx: 3
            }}>
            <Typography sx={{m: 1, fontSize: '14px'}}>Enable Coefficient: </Typography>
            <Stack
                direction={'row'}
                sx={{justifyContent: 'space-around'}}
            >
                <FormControlLabel
                    onClick={enableCoefficientHandler}
                    checked={enableCoefficient.data === 1}
                    control={<Radio/>}
                    label="Yes"
                />
                <FormControlLabel
                    onClick={disableCoefficientHandler}
                    checked={enableCoefficient.data === 0}
                    control={<Radio/>}
                    label="No"
                />
            </Stack>
            <Typography sx={{m: 1, fontSize: '14px'}}>Coefficient A:</Typography>
            <TextField
                inputProps={{'data-testid': 'coefficient-a-field'}}
                type="number"
                value={coefficientA.data}
                onChange={coefficientAChangeHandler}
                disabled={enableCoefficient.data === 0}
                error={coefficientA.errorMessage.length > 0}
                helperText={<FormattedHelperText text={coefficientA.errorMessage}/>}
                sx={{width: '95%', ml: 1}}
            />
            <Typography sx={{m: 1, fontSize: '14px'}}>Coefficient B:</Typography>
            <TextField
                inputProps={{'data-testid': 'coefficient-b-field'}}
                type="number"
                value={coefficientB.data}
                onChange={coefficientBChangeHandler}
                disabled={enableCoefficient.data === 0}
                error={coefficientB.errorMessage.length > 0}
                helperText={<FormattedHelperText text={coefficientB.errorMessage}/>}
                sx={{width: '95%', ml: 1}}
            />
            <Typography sx={{m: 1, fontSize: '14px'}}>Coefficient C:</Typography>
            <TextField
                inputProps={{'data-testid': 'coefficient-c-field'}}
                type="number"
                value={coefficientC.data}
                onChange={coefficientCChangeHandler}
                disabled={enableCoefficient.data === 0}
                error={coefficientC.errorMessage.length > 0}
                helperText={<FormattedHelperText text={coefficientC.errorMessage}/>}
                sx={{width: '95%', ml: 1}}
            />
        </Paper>
    )
}
