import React from "react";
import {FormatIndentDecrease} from "@mui/icons-material";
import {Box, Button, ButtonGroup, Divider, Paper} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {commonAlgoActions} from "../../../store/CommonAlgorithm/slice";

export default function AlgorithmProcessingPage3Tabs(props: any) {
    const {
        InitialEvaluationPage,
        EvaluationTimeline,
        SyncLineChart,
        PerformanceCharts,
        SpectrogramCharts,
        showInstruction,
        setShowInstructions
    } = props;
    const tab = useAppSelector((state: any) => state.commonAlgorithm.tab);
    const algorithmState = useAppSelector((state: any) => state.commonAlgorithm.algorithmState);
    const algorithmEvaluationState = useAppSelector((state: any) => state.commonAlgorithm.algorithmEvaluationState);
    const dispatch = useAppDispatch();

    function tabChangeHandler(tab: number) {
        dispatch(commonAlgoActions.setTab(tab))
    }

    return (
        <Paper
            sx={{
                height: '100%',
                position: 'relative',
            }}
        >
            <Box sx={{overflow: "auto", pt: 2, ml: 2}}>
                <ButtonGroup
                    disabled={algorithmEvaluationState !== "Completed" || algorithmState !== "PostProcessing"}
                    disableElevation
                    sx={{float: 'left'}}
                >
                    <Button
                        variant={tab === 0 ? "contained" : "outlined"}
                        sx={{borderRadius: 0, textTransform: 'none'}}
                        onClick={() => tabChangeHandler(0)}
                    >
                        Synchronize
                    </Button>
                    <Button
                        variant={tab === 1 ? "contained" : "outlined"}
                        sx={{borderRadius: 0, textTransform: 'none'}}
                        onClick={() => tabChangeHandler(1)}
                    >
                        Performance
                    </Button>
                    <Button
                        variant={tab === 2 ? "contained" : "outlined"}
                        sx={{borderRadius: 0, textTransform: 'none'}}
                        onClick={() => tabChangeHandler(2)}
                    >
                        Spectrogram
                    </Button>
                </ButtonGroup>
                {!showInstruction &&
                    <Box
                        sx={{
                            float: 'right',
                            alignContent: 'center',
                            border: 1,
                            pt: 1,
                            px: 1,
                            borderRight: 0,
                            borderRadius: 1,
                            borderTopRightRadius: 1,
                            borderBottomRightRadius: 1,
                            cursor: 'pointer'
                        }}
                        onClick={() => setShowInstructions(!showInstruction)}
                    >
                        <FormatIndentDecrease/>
                    </Box>
                }
            </Box>
            <Divider sx={{width: '100%', mt: 2}}/>
            {tab === 0 && algorithmEvaluationState === 'Initial' && <InitialEvaluationPage/>}
            {tab === 0 && algorithmEvaluationState === 'Running' && <EvaluationTimeline/>}
            {tab === 0 && algorithmEvaluationState === 'Completed' && <SyncLineChart/>}
            {tab === 1 && algorithmEvaluationState === 'Completed' && <PerformanceCharts/>}
            {tab === 2 && algorithmEvaluationState === 'Completed' && <SpectrogramCharts/>}
        </Paper>
    );
}