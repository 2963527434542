import {createSlice} from "@reduxjs/toolkit";
import {remove, find, uniqueId, filter, isEqual} from 'lodash'
import {requestAccount, requestAccountByAdmin, requestCaptcha} from "./api";
import {
    InputInterface,
    clearInput,
    setInput,
    validateInput,
    validateEmail,
    setState,
    setArrayInput
} from '../validation'

let defaultState = {
    companyName: {data: '', errorMessage: '', isValid: false} as InputInterface,
    firstName: {data: '', errorMessage: '', isValid: false} as InputInterface,
    lastName: {data: '', errorMessage: '', isValid: false} as InputInterface,
    emailAddress: {data: '', errorMessage: '', isValid: false} as InputInterface,
    phoneNumber: {data: '', errorMessage: '', isValid: false} as InputInterface,
    purpose: {data: '', errorMessage: '', isValid: false} as InputInterface,
    captcha: {data: '', errorMessage: '', isValid: false} as InputInterface,
    algorithm: {data: [], errorMessage: '', isValid: false},
    countryCode: {data: 'US=+1', errorMessage: '', isValid: true} as InputInterface,
    requestUsers: [
        {
            id: uniqueId(),
            firstName: {data: '', errorMessage: '', isValid: false},
            lastName: {data: '', errorMessage: '', isValid: false},
            email: {data: '', errorMessage: '', isValid: false},
        }
    ],
    status: "idle" as "idle" | "pending" | "succeeded" | "failed",
    captchaKey: "",
    captchaImage: "",
}

const requestAccountSlice = createSlice({
    name: "requestAccountSlice",
    initialState: defaultState,
    reducers: {
        resetPage() {
            return defaultState;
        },
        clearCompanyName(state) {
            clearInput(state, "companyName")
        },
        clearFirstName(state) {
            clearInput(state, "firstName")
        },
        clearLastName(state) {
            clearInput(state, "lastName")
        },
        clearEmailAddress(state) {
            clearInput(state, "emailAddress")
        },
        clearPhoneNumber(state) {
            clearInput(state, "phoneNumber")
        },
        clearCaptcha(state) {
            clearInput(state, "captcha")
        },
        // clearAlgorithm(state) {
        //     clearInput(state, "algorithm")
        // },
        clearUserFirstName(state, action) {
            const id = action.payload
            const userData = find(state.requestUsers as any, {id: id});
            clearInput(userData, "firstName")
        },
        clearUserLastName(state, action) {
            const id = action.payload
            const userData = find(state.requestUsers as any, {id: id});
            clearInput(userData, "lastName")
        },
        clearUserEmail(state, action) {
            const id = action.payload
            const userData = find(state.requestUsers as any, {id: id});
            clearInput(userData, "email")
        },
        setUserFirstName(state, action) {
            const id = action.payload.id
            const data = action.payload.data
            const userData = find(state.requestUsers as any, {id: id});
            setInput(userData, "firstName", data)
        },
        setUserLastName(state, action) {
            const id = action.payload.id
            const data = action.payload.data
            const userData = find(state.requestUsers as any, {id: id});
            setInput(userData, "lastName", data)
        },
        setUserEmail(state, action) {
            const id = action.payload.id
            const data = action.payload.data.toString().toLowerCase()
            const userData = find(state.requestUsers as any, {id: id});
            setInput(userData, "email", data)
        },
        setCompanyName(state, action) {
            setInput(state, "companyName", action.payload)
        },
        setFirstName(state, action) {
            setInput(state, "firstName", action.payload)
        },
        setAlgorithm(state, action) {
            setArrayInput(state, "algorithm", action.payload)
        },
        setCountryCode(state, action) {
            setInput(state, "countryCode", action.payload)
        },
        setLastName(state, action) {
            setInput(state, "lastName", action.payload)
        },
        setEmailAddress(state, action) {
            const email = action.payload.toString().toLowerCase()
            setInput(state, "emailAddress", email)
        },
        setPhoneNumber(state, action) {
            setInput(state, "phoneNumber", action.payload)
        },
        setPurpose(state, action) {
            setInput(state, "purpose", action.payload)
        },
        setCaptcha(state, action) {
            setInput(state, "captcha", action.payload)
        },
        validatePurpose(state) {
            validateInput(state, "purpose", "Please select a purpose for requesting account.")
        },
        validateAlgorithm(state) {
            validateInput(state, "algorithm", "Please select a purpose for requesting account.")
        },
        validateCompanyName(state) {
            validateInput(state, "companyName", "Please enter the company name.")
        },
        validateFirstName(state) {
            validateInput(state, "firstName", "Please enter requester's first name.")
        },
        validateLastName(state) {
            validateInput(state, "lastName", "Please enter requester's last name.")
        },
        validateCaptcha(state) {
            validateInput(state, "captcha", "Please enter the captcha.")
        },
        validateEmailAddress(state) {
            validateEmail(state, "emailAddress")
        },
        validateUserFirstName(state, action) {
            const id = action.payload
            const userData = find(state.requestUsers as any, {id: id});
            validateInput(userData, "firstName", "Please enter user first name.")
        },
        validateUserLastName(state, action) {
            const id = action.payload
            const userData = find(state.requestUsers as any, {id: id});
            validateInput(userData, "lastName", "Please enter user last name.")
        },
        validateUserEmail(state, action) {
            const id = action.payload
            const userData = find(state.requestUsers as any, {id: id});
            const count = filter(state.requestUsers, (user) =>
                isEqual(user.email.data, userData['email'].data)
            ).length;
            if (count > 1 && userData['email'].data.length > 0) {
                userData['email'] = {
                    data: userData['email'].data,
                    errorMessage: 'This email request already exists.',
                    isValid: false
                }
            } else {
                validateEmail(userData, "email", "Please enter user email address.")
            }
        },
        addUser(state) {
            if (state.requestUsers.length < 5)
                state.requestUsers.push({
                    id: uniqueId(),
                    firstName: {data: '', errorMessage: '', isValid: false},
                    lastName: {data: '', errorMessage: '', isValid: false},
                    email: {data: '', errorMessage: '', isValid: false},
                })
        },
        removeUser(state, action) {
            const id = action.payload;
            if (state.requestUsers.length > 1)
                remove(state.requestUsers, {id: id})
        },
        validatePhoneNumber(state) {
            const phoneNum = state.phoneNumber.data;
            if (phoneNum.length === 0) {
                state.phoneNumber = {
                    data: phoneNum,
                    errorMessage: 'Please enter requester\'s phone number.',
                    isValid: false
                }
            } else if (!/^\d+$/.test(phoneNum)) {
                state.phoneNumber = {
                    data: phoneNum,
                    errorMessage: 'Phone number should contain only numbers.',
                    isValid: false
                }
            } else if (phoneNum.length !== 10) {
                state.phoneNumber = {
                    data: phoneNum,
                    errorMessage: 'Please enter 10 digit requester\'s phone number.',
                    isValid: false
                }
            } else {
                state.phoneNumber = {
                    data: phoneNum,
                    errorMessage: '',
                    isValid: true
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestAccount.pending, (state) => {
            state.status = 'pending'
            clearInput(state, "response")
        })
        builder.addCase(requestAccount.fulfilled, (state) => {
            state.status = 'succeeded'
        })
        builder.addCase(requestAccount.rejected, (state, action) => {
            state.status = 'failed'
            const error = action.payload as any
            if (error.hasOwnProperty("non_field_errors")) {
                setState(state, "captcha", {
                    data: '',
                    errorMessage: error["non_field_errors"][0],
                    isValid: false
                })
            }

            if (error.hasOwnProperty("request_access_users")) {
                for (let i = 0; i < error["request_access_users"].length; i++) {
                    if (error["request_access_users"][i].hasOwnProperty("email")) {
                        state.requestUsers[i]["email"].isValid = false
                        state.requestUsers[i]["email"].errorMessage = "An existing user with same email address was found"
                    }
                }
            }
        })
        builder.addCase(requestCaptcha.fulfilled, (state, action) => {
            state.captchaKey = action.payload.captcha_key
            state.captchaImage = "data:image/png;base64," + action.payload.captcha_image
        })
        builder.addCase(requestAccountByAdmin.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(requestAccountByAdmin.fulfilled, (state) => {
            state.status = 'succeeded'
        })
        builder.addCase(requestAccountByAdmin.rejected, (state) => {
            state.status = 'failed'
        })
    },
});

export const requestAccountActions = requestAccountSlice.actions;
export default requestAccountSlice;