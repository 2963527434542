import {RootState} from "../index";
import {createAsyncThunk} from '@reduxjs/toolkit'
import {publicAxios, authAxios} from '../axiosAPI';

export const resetPasswordRequest = createAsyncThunk(
    'users/reset_password',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const resetPassword = state.resetPassword;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const data = {token: urlParams.get('token'), password: resetPassword.password.data}
        const response = await publicAxios.post('/user/reset_password/', data)
        return response.data
    }
)

export const changePasswordRequest = createAsyncThunk(
    'users/change_password',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const resetPassword = state.resetPassword;
        const data = {
                current_password: resetPassword.currentPassword.data,
                new_password: resetPassword.password.data
        }
        const response = await authAxios.post('/user/change_password/', data)
        return response.data
    }
)
