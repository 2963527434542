import {createSlice} from "@reduxjs/toolkit";
import {AlgorithmInputInterface} from "../validation";
import {
    getHROutput,
    getLineGraphImages,
    getReferenceHROutput,
    getSpectrogramGraphImages,
    startEvaluation,
    startWHRMSyncEvaluation
} from "./api";


const initialState = {
    age: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,
    height: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,
    weight: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,
    gender: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,

    syncLabel: [] as any,
    algoHRData: [] as any,
    referenceHRData: [] as any,
    timeDiffInSec: 0,

    lineGraph: [null, null, null, null, null] as any,
    spectrogramGraph: [null, null, null, null] as any,

    bpm5Acc: "-" as any,
    bpm10Acc: "-" as any,
    MAE: "-" as any,

}
const whrmAlgoSlice = createSlice({
    name: "whrmAlgoSlice",
    initialState: initialState,
    reducers: {
        resetState() {
            return initialState
        },
        setTimeDiffInSec(state, action) {
            state.timeDiffInSec = action.payload
        },
        setAge(state, action) {
            state.age.data = action.payload;
        },
        validateAge(state) {
            const numberString = state.age.data.toString();
            if (!Number.isInteger(parseInt(numberString))) {
                state.age.isValid = false;
                state.age.errorMessage = 'Please enter age in integer values';
            } else if (state.age.data < 0 || state.age.data > 150) {
                state.age.isValid = false;
                state.age.errorMessage = 'Age has to be between 0 and 150';
            } else if (!/^\d+$/.test(numberString)) {
                state.age.isValid = false;
                state.age.errorMessage = 'Please enter age in integer values';
            } else {
                state.age.isValid = true;
                state.age.errorMessage = '';
            }
        },
        setHeight(state, action) {
            state.height.data = action.payload;
        },
        validateHeight(state) {
            const numberString = state.height.data.toString();
            if (!Number.isInteger(parseInt(numberString))) {
                state.height.isValid = false;
                state.height.errorMessage = 'Please enter height in integer values';
            } else if (state.height.data < 0 || state.height.data > 300) {
                state.height.isValid = false;
                state.height.errorMessage = 'Height has to be between 0 and 300';
            } else if (!/^\d+$/.test(numberString)) {
                state.height.isValid = false;
                state.height.errorMessage = 'Please enter height in integer values';
            } else {
                state.height.isValid = true;
                state.height.errorMessage = '';
            }
        },
        setWeight(state, action) {
            state.weight.data = action.payload;
        },
        validateWeight(state) {
            const numberString = state.weight.data.toString();
            if (!Number.isInteger(parseInt(numberString))) {
                state.weight.isValid = false;
                state.weight.errorMessage = 'Please enter weight in integer values';
            } else if (state.weight.data < 0 || state.weight.data > 1000) {
                state.weight.isValid = false;
                state.weight.errorMessage = 'Weight has to be between 0 and 1000';
            } else if (!/^\d+$/.test(numberString)) {
                state.weight.isValid = false;
                state.weight.errorMessage = 'Please enter weight in integer values';
            } else {
                state.weight.isValid = true;
                state.weight.errorMessage = '';
            }
        },
        setGender(state, action) {
            state.gender.data = action.payload;
        },
        validateGender(state) {
            state.gender.isValid = true;
            state.gender.errorMessage = '';
        },
        setEvaluationResult(state, action) {
            state.bpm5Acc = action.payload.bpm5Acc
            state.bpm10Acc = action.payload.bpm10Acc
            state.MAE = action.payload.MAE
        },
        setLineGraphImages(state, action) {
            state.lineGraph = action.payload
        },
        setSpectrogramGraphImages(state, action) {
            state.spectrogramGraph = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(startEvaluation.fulfilled, (state) => {
            state.syncLabel = []
            state.algoHRData = []
            state.referenceHRData = []
            state.timeDiffInSec = 0
            state.lineGraph = [null, null, null, null, null]
            state.spectrogramGraph = [null, null, null, null]
            state.bpm5Acc = "-"
            state.bpm10Acc = "-"
            state.MAE = "-"
        })
        builder.addCase(startWHRMSyncEvaluation.pending, (state) => {
            state.lineGraph = [null, null, null, null, null]
            state.spectrogramGraph = [null, null, null, null]
        })
        builder.addCase(startWHRMSyncEvaluation.fulfilled, (state) => {
            state.bpm5Acc = "-"
            state.bpm10Acc = "-"
            state.MAE = "-"
        })
        builder.addCase(getLineGraphImages.fulfilled, (state, action) => {
            state.lineGraph = action.payload
        })
        builder.addCase(getSpectrogramGraphImages.fulfilled, (state, action) => {
            state.spectrogramGraph = action.payload
        })
        builder.addCase(getHROutput.fulfilled, (state, action) => {
            let labels = [];
            for (let i = 0; i < action.payload.length; ++i)
                labels.push(i + 1);
            state.syncLabel = labels
            state.algoHRData = action.payload
        })
        builder.addCase(getReferenceHROutput.fulfilled, (state, action) => {
            state.referenceHRData = action.payload
        })
    }
});

export const whrmAlgoActions = whrmAlgoSlice.actions;
export default whrmAlgoSlice;
