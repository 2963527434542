import React from "react";
import {styled} from '@mui/material/styles';
import {adminActions} from "../../store/Admin/slice";
import {tableCellClasses} from '@mui/material/TableCell';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    Archive,
    ArrowLeft,
    ArrowRight,
    CheckCircle,
    Lock,
    MoreTime,
    Pending,
    Person,
    Visibility
} from "@mui/icons-material";
import {
    Button,
    ButtonGroup,
    Chip,
    FormControl,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    archiveAccountRequest,
    nextArchiveAccountList,
    nextRequestAccountListRequest,
    nextUserListRequest,
    previousArchiveAccountList,
    previousRequestAccountListRequest,
    previousUserListRequest
} from "../../store/Admin/api";


const StyledTableCellDisabled = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgba(252, 252, 252, 1)',
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#AAADB6',
    },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgba(252, 252, 252, 1)',
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const RequestAccountStyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(217, 220, 221, 0.15)',
        border: '1px solid #D9DCDD',
        color: '#AAADB6',
        borderLeft: 0,
        borderRight: 0,
    },
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(217, 220, 221, 0.15)',
        border: '1px solid #D9DCDD',
        borderLeft: 0,
        color: '#AAADB6',
        borderRight: 0,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
        color: '#AAADB6',
    },
}));

const UserStyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
        // border: 0,
    },
}));

export default function AdminTable() {
    const user = useAppSelector((state: any) => state.admin.user);
    const userPageNo = useAppSelector((state: any) => state.admin.userPageNo);
    const totalUsers = useAppSelector((state: any) => state.admin.totalUsers);
    const userListSize = useAppSelector((state: any) => state.admin.userListSize);
    const userLeftArrowLink = useAppSelector((state: any) => state.admin.userLeftArrowLink);
    const userRightArrowLink = useAppSelector((state: any) => state.admin.userRightArrowLink);
    const currentTab = useAppSelector((state: any) => state.admin.currentTab);
    const accountRequests = useAppSelector((state: any) => state.admin.accountRequests);
    const accountRequestPageNo = useAppSelector((state: any) => state.admin.accountRequestPageNo);
    const totalAccountRequests = useAppSelector((state: any) => state.admin.totalAccountRequests);
    const accountRequestsListSize = useAppSelector((state: any) => state.admin.accountRequestsListSize);
    const accountRequestsLeftLink = useAppSelector((state: any) => state.admin.accountRequestsLeftArrowLink);
    const accountRequestsRightLink = useAppSelector((state: any) => state.admin.accountRequestsRightArrowLink);
    const archived = useAppSelector((state: any) => state.admin.archived);
    const archivePageNo = useAppSelector((state: any) => state.admin.archivePageNo);
    const totalArchived = useAppSelector((state: any) => state.admin.totalArchived);
    const archiveListSize = useAppSelector((state: any) => state.admin.archivedListSize);
    const archivedLeftArrowLink = useAppSelector((state: any) => state.admin.archiveLeftArrowLink);
    const archivedRightArrowLink = useAppSelector((state: any) => state.admin.archiveRightArrowLink);
    const status = useAppSelector((state: any) => state.admin.status);
    const dispatch = useAppDispatch();

    function parseDate(originalDate: string) {
        const dateObj = new Date(originalDate);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`
    }

    function dayRemaining(originalDate: string) {
        const targetDate = new Date(originalDate);
        const currentDate = new Date();
        const timeDiff = targetDate.getTime() - currentDate.getTime();
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
    }

    function handleViewRequest(request: any) {
        dispatch(adminActions.openAccountPermissionDialog(request))
    }

    function handleExtendAccountRequest(user: any) {
        dispatch(adminActions.openExtendAccountDialog(user))
    }

    function handleArchiveAccount(user: any) {
        dispatch(archiveAccountRequest({user}))
    }

    function setListSize(listSize: number) {
        let functionToSetIn = currentTab === '1' ?
            adminActions.setAccountRequestsListSize
            : currentTab === '2' ?
                adminActions.setUserListSize
                : adminActions.setArchivedListSize
        dispatch(functionToSetIn(listSize));

    }

    function leftArrowClickHandler() {
        const functionToCall = currentTab === '1' ?
            previousRequestAccountListRequest
            : currentTab === '2' ?
                previousUserListRequest
                : previousArchiveAccountList
        dispatch(functionToCall());
    }

    function rightArrowClickHandler() {
        const functionToCall = currentTab === '1' ?
            nextRequestAccountListRequest
            : currentTab === '2' ?
                nextUserListRequest
                : nextArchiveAccountList
        dispatch(functionToCall());
    }

    return (
        <TableContainer component={Paper} sx={{mt: 3}}>
            {status === "pending" && <LinearProgress sx={{width: '100%'}}/>}
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Company Name</StyledTableCell>
                        <StyledTableCell>First N. Last N.</StyledTableCell>
                        <StyledTableCell>Email Address</StyledTableCell>
                        <StyledTableCell>Algorithms</StyledTableCell>
                        <StyledTableCell>Date Requested</StyledTableCell>
                        <StyledTableCell>Remaining Account Time</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentTab === '1' && accountRequests.map((row: any, index: number) => (
                        <RequestAccountStyledTableRow key={index}>
                            <StyledTableCellDisabled>
                                {row.id}
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                {row.company_name}
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                {`${row.first_name} ${row.last_name}`}
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                {row.email}
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                -
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                {parseDate(row.created_on)}
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                -
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                <Chip
                                    icon={
                                        row.status === "COMPLETED" ?
                                            <CheckCircle sx={{fontSize: '14px'}}/>
                                            :
                                            <Pending sx={{fontSize: '14px'}}/>
                                    }
                                    sx={{
                                        fontSize: '10px',
                                        color: '#AAADB6',
                                        backgroundColor: '#FFF',
                                        fontWeight: 'bold',

                                    }}
                                    label={row.status}
                                />
                            </StyledTableCellDisabled>
                            <StyledTableCellDisabled>
                                <Button
                                    variant="text"
                                    startIcon={<Visibility/>}
                                    sx={{
                                        textTransform: 'none',
                                        textDecoration: 'underline',
                                        fontSize: 14,
                                    }}
                                    size="small"
                                    onClick={() => handleViewRequest(row)}
                                    disabled={row.status === "COMPLETED"}
                                >
                                    View Request
                                </Button>
                            </StyledTableCellDisabled>
                        </RequestAccountStyledTableRow>
                    ))}
                    {currentTab === '2' && user.map((row: any, index: number) => (
                        <UserStyledTableRow key={index}>
                            <StyledTableCell>-</StyledTableCell>
                            <StyledTableCell>-</StyledTableCell>
                            <StyledTableCell>{`${row.first_name} ${row.last_name}`}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>
                                {
                                    row.algorithm_access_set.map((algo: any, index: number) => {
                                        return (index ? ', ' : '') + algo.algorithm.name
                                    })
                                }
                            </StyledTableCell>
                            <StyledTableCell>-</StyledTableCell>
                            <StyledTableCell>{dayRemaining(row.account_expires_on)} Days</StyledTableCell>
                            <StyledTableCell>
                                {row.status === "ACCOUNT_INITIATED" ?
                                    <Chip
                                        icon={<Person sx={{fontSize: '14px'}}/>}
                                        sx={{
                                            fontSize: '10px',
                                            color: '#937101',
                                            backgroundColor: '#FEF7DC',
                                            fontWeight: 'bold',
                                        }}
                                        label={row.status}
                                    /> :
                                    <Chip
                                        icon={<Lock sx={{fontSize: '14px'}}/>}
                                        sx={{
                                            fontSize: '10px',
                                            color: '#3A8362',
                                            backgroundColor: '#EDF5F1',
                                            fontWeight: 'bold',
                                        }}
                                        label={row.status}
                                    />
                                }
                            </StyledTableCell>
                            <StyledTableCell>
                                <Button
                                    startIcon={<MoreTime/>}
                                    sx={{
                                        textTransform: 'none',
                                        textDecoration: 'underline',
                                        fontSize: 14,
                                    }}
                                    onClick={() => handleExtendAccountRequest(row)}
                                    variant={"text"}
                                >
                                    Extend
                                </Button>
                                <Button
                                    startIcon={<Archive/>}
                                    sx={{
                                        textTransform: 'none',
                                        textDecoration: 'underline',
                                        fontSize: 14,
                                    }}
                                    onClick={() => handleArchiveAccount(row)}
                                    variant={"text"}
                                >
                                    Archive
                                </Button>
                            </StyledTableCell>
                        </UserStyledTableRow>
                    ))}
                    {currentTab === '3' && archived.map((row: any, index: number) => (
                        <UserStyledTableRow key={index}>
                            <StyledTableCell>-</StyledTableCell>
                            <StyledTableCell>-</StyledTableCell>
                            <StyledTableCell>{`${row.first_name} ${row.last_name}`}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>{row.algorithms}</StyledTableCell>
                            <StyledTableCell>-</StyledTableCell>
                            <StyledTableCell>{parseDate(row.account_expires_on)}</StyledTableCell>
                            <StyledTableCell>{row.status}</StyledTableCell>
                            <StyledTableCell> - </StyledTableCell>
                        </UserStyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{float: 'left', my: 2}}
            >
                <Typography
                    variant="body2"
                    color="grey"
                    sx={{ml: 4}}
                >
                    Results per Page
                </Typography>
                <FormControl
                    sx={{m: 1, minWidth: 80}}
                    size="small"
                >
                    <Select
                        value={
                            currentTab === '1' ?
                                accountRequestsListSize
                                : currentTab === '2' ?
                                    userListSize
                                    : archiveListSize
                        }
                    >
                        <MenuItem
                            value={5}
                            onClick={() => setListSize(5)}
                        >
                            5
                        </MenuItem>
                        <MenuItem
                            value={10}
                            onClick={() => setListSize(10)}
                        >
                            10
                        </MenuItem>
                        <MenuItem
                            value={15}
                            onClick={() => setListSize(15)}
                        >
                            15
                        </MenuItem>
                        <MenuItem
                            value={20}
                            onClick={() => setListSize(20)}
                        >
                            20
                        </MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{float: 'right', mr: 2, my: 2}}
            >
                <ButtonGroup
                    color='inherit'
                    variant="outlined"
                    aria-label="outlined button group"
                    sx={{height: 40, color: 'grey', borderRadius: 0.5}}
                >
                    <Button sx={{color: 'grey'}}>
                        {
                            currentTab === '1' ?
                                accountRequestPageNo
                                : currentTab === '2' ?
                                    userPageNo
                                    : archivePageNo
                        }
                    </Button>
                    <Typography
                        variant="body2"
                        color="grey"
                        sx={{mx: 2, py: 1}}
                    >
                        of {
                        currentTab === '1' ?
                            Math.ceil(totalAccountRequests / accountRequestsListSize)
                            : currentTab === '2' ?
                                Math.ceil(totalUsers / userListSize)
                                : Math.ceil(totalArchived / archiveListSize)
                    }
                    </Typography>
                </ButtonGroup>
                <ButtonGroup
                    color='inherit'
                    variant="outlined"
                    size={"small"}
                    sx={{height: 40, color: 'grey', borderRadius: 0.5}}
                >
                    <Button
                        sx={{color: 'grey'}}
                        disabled={
                            // tabPointers[currentTab][0] == null
                            currentTab === '1' ?
                                accountRequestsLeftLink == null
                                : currentTab === '2' ?
                                    userLeftArrowLink == null
                                    : archivedLeftArrowLink == null
                        }
                        onClick={leftArrowClickHandler}
                    >
                        <ArrowLeft/>
                    </Button>
                    <Button
                        sx={{color: 'grey'}}
                        disabled={
                            currentTab === '1' ?
                                accountRequestsRightLink == null
                                : currentTab === '2' ?
                                    userRightArrowLink == null
                                    : archivedRightArrowLink == null
                        }
                        onClick={rightArrowClickHandler}
                    >
                        <ArrowRight/>
                    </Button>
                </ButtonGroup>
            </Stack>
        </TableContainer>
    )
}