import React from 'react';
import Router from "./routes/routes";
import ScrollToTop from "./components/ScrollToTop";
import ThemeConfig from "./theme";

function App() {
    return (
        <ThemeConfig>
            <ScrollToTop/>
            <Router/>
        </ThemeConfig>
    );
}

export default App;
