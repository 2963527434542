enum EvaluationEvent {
    STEP_LIST = 'JobStatus.STEP_LIST',
    INIT = 'JobStatus.INIT',
    IN_PROGRESS = 'JobStatus.IN_PROGRESS',
    COMPLETED = 'JobStatus.COMPLETED',
    TIME_INFO = 'JobStatus.TIME_INFO',
    TIME_END = 'JobStatus.TIME_END',
    FAILED = 'JobStatus.FAILED',
    RESULT = 'JobStatus.RESULT',
}

export default EvaluationEvent;