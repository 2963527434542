import React from 'react';
import validator from 'validator';
import toBoolean = validator.toBoolean;
import PasswordStatus from "../PasswordStatus";
import PasswordTextField from "../PasswordTextField";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {resetPasswordActions} from "../../store/ResetPassword/slice";
import {
    FormControl,
    Stack
} from "@mui/material";

export default function ResetPasswordFragment({changePasswordDialog = false}) {
    const password = useAppSelector((state: any) => state.resetPassword.password);
    const successStatus = useAppSelector((state: any) => state.resetPassword.status);
    const confirmPassword = useAppSelector((state: any) => state.resetPassword.confirmPassword);
    const passwordStatus1Lower = useAppSelector((state: any) => state.resetPassword.passwordStatus1Lower);
    const passwordStatus1Number = useAppSelector((state: any) => state.resetPassword.passwordStatus1Number);
    const passwordStatus1Capital = useAppSelector((state: any) => state.resetPassword.passwordStatus1Capital);
    const passwordStatus1Special = useAppSelector((state: any) => state.resetPassword.passwordStatus1Special);
    const passwordStatus10Characters = useAppSelector((state: any) => state.resetPassword.passwordStatus10Characters);
    const dispatch = useAppDispatch();

    function handlePasswordChange(event: any) {
        dispatch(resetPasswordActions.setPassword(event.target.value))
        dispatch(resetPasswordActions.validatePassword())
        dispatch(resetPasswordActions.validateConfirmPassword())
    }

    function handleConfirmPasswordChange(event: any) {
        dispatch(resetPasswordActions.setConfirmPassword(event.target.value))
        dispatch(resetPasswordActions.validatePassword())
        dispatch(resetPasswordActions.validateConfirmPassword())
    }

    return (
        <React.Fragment>
            <FormControl>
                {changePasswordDialog ? 'New Password:' : 'Password:'}
                <PasswordTextField
                    inputProps={{'data-testid': 'reset-password-input-first'}}
                    sx={{mb: 2, width: 400}}
                    error={
                        passwordStatus10Characters === false ||
                        passwordStatus1Number === false ||
                        passwordStatus1Capital === false ||
                        passwordStatus1Lower === false ||
                        passwordStatus1Special === false
                    }
                    status={successStatus}
                    autoComplete="current-password"
                    onChange={handlePasswordChange}
                    value={password.data}
                />
                <Stack sx={{mb: 1}}>
                    <PasswordStatus status={passwordStatus10Characters}>
                        Must be at least 10 characters
                    </PasswordStatus>
                    <PasswordStatus status={passwordStatus1Number}>
                        Must contain at least 1 number
                    </PasswordStatus>
                    <PasswordStatus status={passwordStatus1Capital}>
                        Must contain at least 1 letter in capital case
                    </PasswordStatus>
                    <PasswordStatus status={passwordStatus1Lower}>
                        Must contain at least 1 letter in lower case
                    </PasswordStatus>
                    <PasswordStatus status={passwordStatus1Special}>
                        Must contain at least 1 special character
                    </PasswordStatus>
                </Stack>
            </FormControl>
            <FormControl>
                {changePasswordDialog ? 'Confirm New Password:' : 'Confirm Password:'}
                <PasswordTextField
                    inputProps={{'data-testid': 'reset-password-input-confirm'}}
                    sx={{mb: 3, width: 400}}
                    error={toBoolean(confirmPassword.errorMessage)}
                    helperText={confirmPassword.errorMessage}
                    autoComplete="current-password"
                    status={successStatus}
                    onChange={handleConfirmPasswordChange}
                    value={confirmPassword.data}
                />
            </FormControl>
        </React.Fragment>
    )
}