import React from "react";
import {Box, Typography} from "@mui/material";

export default function Instructions() {
    return (
        <React.Fragment>
            <Typography fontSize={14} sx={{p: 2}}>
                Oxygen saturation is the fraction of oxygen-saturated hemoglobin relative to total
                hemoglobin (unsaturated + saturated) in the blood. The human body requires and regulates
                a very precise and specific balance of oxygen in the blood. Normal arterial blood
                oxygen saturation levels in humans are 97–100 percent.
                If the level is below 90 percent, it is considered low and called hypoxemia.
                Arterial blood oxygen levels below 80 percent may compromise organ function, such as the brain
                and heart, and should be promptly addressed.
                Continued low oxygen levels may lead to respiratory or cardiac arrest. Oxygen therapy may be
                used to assist in raising blood oxygen levels.
            </Typography>
            <Box sx={{px: 2}}>
                <img
                    src='https://upload.wikimedia.org/wikipedia/commons/8/8a/Oxyhaemoglobin_dissociation_curve.png'
                    style={{width: '90%'}}
                    alt={"SpO2"}
                />
            </Box>
        </React.Fragment>
    )
}