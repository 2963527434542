import {Middleware} from 'redux'
import {commonAlgoActions} from './slice';
import EvaluationEvent from "./evaluationEvent";

const evaluationMiddleware: Middleware = store => {
    let ws: WebSocket;
    const token = window.localStorage.getItem('access')

    return next => action => {
        if (commonAlgoActions.startWebsocketConnection.match(action)) {
            const evaluationID = store.getState().commonAlgorithm.evaluationID
            const isWebsocketConnected = store.getState().commonAlgorithm.isWebsocketConnected
            if (!isWebsocketConnected) {
                // ws = new WebSocket('ws://localhost:8000/whrm/' + evaluationID + '/live?auth-token=' + token);
                ws = new WebSocket('wss://aep.analog-dhc.com:8000/whrm/' + evaluationID + '/live?auth-token=' + token);

                ws.addEventListener("open", (_: any) => {
                    store.dispatch(commonAlgoActions.setWebsocketConnected(true))
                });

                ws.addEventListener("message", (event: any) => {
                    const jsonData = JSON.parse(event.data);
                    if (jsonData.status === EvaluationEvent.STEP_LIST) {
                        store.dispatch(commonAlgoActions.setEvaluationTimelineStates(jsonData.data))
                    } else {
                        store.dispatch(commonAlgoActions.updateEvaluationTimelineStates(jsonData))
                    }
                });
            }
        }
        next(action);
    }
}

export default evaluationMiddleware;