import {createSlice} from "@reduxjs/toolkit";
import {AlgorithmInputInterface, setInput, validateSpO2Coefficient} from "../validation";
import {
    getLineGraphImages,
    getSpectrogramGraphImages,
    getReferenceSpO2Output,
    getSpO2Output,
    startSpO2Evaluation,
    startSpO2SyncEvaluation
} from "./api";

const initialState = {
    enableCoefficient: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,
    coefficientA: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,
    coefficientB: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,
    coefficientC: {data: 0, errorMessage: '', isValid: true} as AlgorithmInputInterface,
    timeDiffInSec: 0,

    syncLabel: [] as any,
    algoSpO2Data: [] as any,
    referenceSpO2Data: [] as any,

    lineGraph: [null, null, null, null, null, null, null, null, null, null, null, null] as any,
    spectrogramGraph: [null, null, null] as any,

    RMSE: "-" as any,
    MAE: "-" as any,
}

const spo2AlgoSlice = createSlice({
    name: "spo2AlgoSlice",
    initialState: initialState,
    reducers: {
        resetState() {
            return initialState
        },
        setTimeDiffInSec(state, action) {
            state.timeDiffInSec = action.payload
        },
        setEnableCoefficient(state, action) {
            setInput(state, "enableCoefficient", action.payload)
        },
        setCoefficientA(state, action) {
            setInput(state, "coefficientA", action.payload)
        },
        validateCoefficientA(state) {
            validateSpO2Coefficient(state, "coefficientA")
        },
        setCoefficientB(state, action) {
            setInput(state, "coefficientB", action.payload)
        },
        validateCoefficientB(state) {
            validateSpO2Coefficient(state, "coefficientB")
        },
        setCoefficientC(state, action) {
            setInput(state, "coefficientC", action.payload)
        },
        validateCoefficientC(state) {
            validateSpO2Coefficient(state, "coefficientC")
        },
        setEvaluationResult(state, action) {
            state.RMSE = action.payload.RMSE
            state.MAE = action.payload.MAE
        },
        setLineGraphImages(state, action) {
            state.lineGraph = action.payload
        },
        setSpectrogramGraphImages(state, action) {
            state.spectrogramGraph = action.payload
        }
    },
    extraReducers: (builder) => {

        builder.addCase(startSpO2Evaluation.fulfilled, (state) => {
            state.syncLabel = []
            state.algoSpO2Data = []
            state.referenceSpO2Data = []
            state.timeDiffInSec = 0
            state.lineGraph = [null, null, null, null, null, null, null, null, null, null, null, null]
            state.spectrogramGraph = [null, null, null]
            state.RMSE = "-"
            state.MAE = "-"
        })
        builder.addCase(startSpO2SyncEvaluation.pending, (state) => {
            state.lineGraph = [null, null, null, null, null, null, null, null, null, null, null, null]
            state.spectrogramGraph = [null, null, null, null]
        })
        builder.addCase(startSpO2SyncEvaluation.fulfilled, (state) => {
            state.RMSE = "-"
            state.MAE = "-"
        })
        builder.addCase(getLineGraphImages.fulfilled, (state, action) => {
            state.lineGraph = action.payload
        })
        builder.addCase(getSpectrogramGraphImages.fulfilled, (state, action) => {
            state.spectrogramGraph = action.payload
        })
        builder.addCase(getSpO2Output.fulfilled, (state, action) => {
            let labels = [];
            for (let i = 0; i < action.payload.length; ++i)
                labels.push(i + 1);
            state.syncLabel = labels
            state.algoSpO2Data = action.payload
        })
        builder.addCase(getReferenceSpO2Output.fulfilled, (state, action) => {
            state.referenceSpO2Data = action.payload
        })
    }
});

export const spo2AlgoActions = spo2AlgoSlice.actions;
export default spo2AlgoSlice;

