import React, {useEffect, useRef, useState} from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {Link as RouterLink} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "../../components/LoadingButton";
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {resetPasswordRequest} from "../../store/ResetPassword/api";
import {resetPasswordActions} from "../../store/ResetPassword/slice";
import ResetPasswordFragment from "../../components/Fragments/ResetPasswordFragment";
import {
    Box,
    Container,
    Divider,
    Link, Paper,
    Stack,
    Typography
} from "@mui/material";
import dashboard_illustration_top from "../../svgs/dashboard-illustration-top.png";
import dashboard_illustration_bottom from "../../svgs/dashboard-illustration-bottom.png";

function ResetPasswordStatus() {
    return (
        <Typography
            variant="body2"
            color="grey"
            align="left"
            lineHeight={1.6}
            sx={{pl: 5, pr: 3, mb: 4}}
        >
            Your password was successfully changed.
        </Typography>
    )
}

function ResetPasswordFailedStatus() {
    return (
        <Typography
            variant="body2"
            color="grey"
            align="left"
            lineHeight={1.6}
            fontWeight={"bolder"}
            sx={{pl: 5, pr: 3, mb: 4, color: '#E04E39'}}
        >
            Invalid reset password token.
        </Typography>
    )
}

export default function ResetPassword() {
    const successStatus = useAppSelector((state: any) => state.resetPassword.status);
    const resetPassword = useAppSelector((state: any) => state.resetPassword);
    const dispatch = useAppDispatch();
    const footerRef = useRef<HTMLDivElement>(null);
    const [bottom, setBottom] = useState(0)

    useEffect(() => {
        if (footerRef.current) {
            const data = footerRef.current.getBoundingClientRect();
            setBottom(parseInt(data.top + ''))
        }
    }, [footerRef]);

    function handleResetPassword() {
        dispatch(resetPasswordActions.validatePassword())
        dispatch(resetPasswordActions.validateConfirmPassword())
        if (
            resetPassword.confirmPassword.isValid &&
            resetPassword.passwordStatus10Characters &&
            resetPassword.passwordStatus1Number &&
            resetPassword.passwordStatus1Capital &&
            resetPassword.passwordStatus1Lower &&
            resetPassword.passwordStatus1Special
        ) {
            dispatch(resetPasswordRequest())
        }
    }

    useEffect(() => {
        document.title = 'Reset Password';
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#0067B9'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_top})`,
                    width: '185px',
                    height: '274px',
                    top: 80,
                    right: 0,
                    zIndex: 10,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    backgroundImage: `url(${dashboard_illustration_bottom})`,
                    backgroundRepeat: "no-repeat",
                    width: '279px',
                    height: '414px',
                    left: 0,
                    top: `${bottom - 340}px`,
                    zIndex: 10,
                }}
            />
            <Header/>
            <Container
                component="main"
                sx={{
                    width: 570,
                    marginTop: 8,
                    py: 2,
                    px: 2,
                    pb: 3,
                }}
            >
                <Paper sx={{py: 2, px: 2, pb: 3}}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{mt: 4, mb: 2, pl: 5}}
                    >
                        Reset Password
                    </Typography>
                    {successStatus === "succeeded" && <ResetPasswordStatus/>}
                    {successStatus === "failed" && <ResetPasswordFailedStatus/>}
                    {(successStatus === "idle" || successStatus === "pending") &&
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <ResetPasswordFragment/>
                            <LoadingButton
                                onClick={handleResetPassword}
                                fullWidth
                                variant="contained"
                                status={successStatus}
                                sx={{width: 400, height: 50, textTransform: 'None'}}
                            >
                                Reset
                            </LoadingButton>
                            <Divider sx={{width: '90%', mt: 3, mb: 2}}/>
                        </Box>
                    }
                    <Stack sx={{pl: 3, pr: 3, mb: 2}}>
                        <Link
                            component={RouterLink}
                            to="/login"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                            }}
                        >
                            <ArrowBackIcon color={"primary"} sx={{mr: 1}}/>
                            <b>Back to Login Page</b>
                        </Link>
                    </Stack>
                </Paper>
            </Container>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                }}
                ref={footerRef}
            >
                <Footer sx={{mb: 1}}/>
            </Box>
        </Box>
    );
}
