import {createSlice} from "@reduxjs/toolkit";
import {algorithmRequest} from "./api";

interface authState {
    algorithms: [];
    status: "idle" | "pending" | "succeeded" | "failed";
}

const algorithmSlice = createSlice({
    name: "authSlice",
    initialState: {
        algorithms: [],
        status: 'idle'
    } as authState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(algorithmRequest.pending, (state, _) => {
            state.status = 'pending'
        })
        builder.addCase(algorithmRequest.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.algorithms = action.payload.results
        })
        builder.addCase(algorithmRequest.rejected, (state, _) => {
            state.status = 'failed'
        })
    },
});

export const algorithmActions = algorithmSlice.actions;
export default algorithmSlice;